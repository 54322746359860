import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Welcome from "./components/Welcome";
import Login from "./Login/Login";
import Signup from "./Login/Signup";
import Exercises from "./Exercises/Exercises";
import ReactLessons from "./Exercises/ReactLessons";
import LiveChat from "./Chat/LiveChat";
import ResetPassword from "./Login/ResetPassword";
import ResetPasswordConfirm from "./Login/ResetPasswordConfirm";
import AuthActionHandler from "./Login/AuthActionHandler";
import VerifyEmail from "./Login/VerifyEmail";
import AuthorList from "./Authors/AuthorList";
import Faq from "./components/Faq";
import Pricing from "./components/Pricing";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import Success from "./Stripe/Success";
import Cancel from "./Stripe/Cancel";
import Articles from "./Blog/Articles";
import MobileMessage from "./Exercises/MobileMessage";
import LiveLessons from "./Exercises/LiveLessons";
import useAuth from "./Login/useAuth";
import BlogCardList from "./Blog/BlogCardList";
import ReactExplained from "./Blog/ReactExplained";
import DevEssentials from "./Blog/DevEssentials";
import Interview from "./Interview/Interview";
import InterviewQuestions from "./Interview/InterviewQuestions";
import InterviewQuestionQuiz from "./Interview/InterviewQuestionQuiz";
import InterviewTasks from "./Interview/InterviewTasks";
import ProtectedRoute from "./ProtectedRoute";
import InterviewCodeQuiz from "./Interview/InterviewCodeQuiz";
import InterviewCorrectCode from "./Interview/InterviewCorrectCode";
import WorkshopList from "./Exercises/WorkshopList";
import MusicAcademy from "./Exercises/MusicAcademy";
import LiveEditor from "./Exercises/LiveEditor";
import Reference from "./Reference/Reference";
import ScrollToTopOnNavigation from "./components/ScrollToTopOnNavigation";
import WrongRoute from "./components/WrongRoute";
import NotFound from "./components/NotFound";

// Public preview components for crawlers
const ExercisesPreview = () => (
  <div>
    <h1>React Exercises</h1>
    <p>Practice React with 100+ interactive exercises. Log in to access the full content.</p>
  </div>
);

const InterviewPreview = () => (
  <div>
    <h1>React Interview Prep</h1>
    <p>Prepare for React interviews with quizzes and tasks. Log in to access the full content.</p>
  </div>
);

const ProtectedRoutePreview = ({ title, description }) => (
  <div>
    <h1>{title}</h1>
    <p>{description} Log in to access the full content.</p>
  </div>
);

// Higher-order component for protected routes
const withProtectedRoute = (Component) => ({ isLoggedIn, isAdmin, ...props }) => (
  <ProtectedRoute isLoggedIn={isLoggedIn || isAdmin}>
    <Component {...props} />
  </ProtectedRoute>
);

// Dynamic metadata for SEO with canonical URLs
const getMetaData = (path) => {
  const baseUrl = "https://www.projectschool.dev";
  switch (path) {
    case "/exercises":
      return {
        title: "React Exercises - ProjectSchool",
        description: "Practice React with 100+ interactive exercises.",
        canonical: `${baseUrl}/exercises`,
      };
    case "/interview":
      return {
        title: "React Interview Prep - ProjectSchool",
        description: "Prepare for React interviews with quizzes and tasks.",
        canonical: `${baseUrl}/interview`,
      };
    case "/blogs":
      return {
        title: "React Blog - ProjectSchool",
        description: "Explore React tutorials, tips, and best practices.",
        canonical: `${baseUrl}/blogs`,
      };
    case "/login":
      return {
        title: "Login - ProjectSchool",
        description: "Log in to access exclusive React content.",
        canonical: `${baseUrl}/login`,
      };
    case "/signup":
      return {
        title: "Sign Up - ProjectSchool",
        description: "Join ProjectSchool to master React with interactive exercises.",
        canonical: `${baseUrl}/signup`,
      };
    default:
      return {
        title: "Master React: 100+ Projects & Quizzes - ProjectSchool",
        description: "Exclusive React projects, quizzes, and live challenges to master UI development.",
        canonical: `${baseUrl}/`,
      };
  }
};

// Improved crawler detection
const isCrawler = () =>
  /googlebot|bingbot|yahoo|duckduckbot|baiduspider|twitterbot|facebookexternalhit|linkedinbot/.test(
    navigator.userAgent.toLowerCase()
  );

const App = () => {
  const { isLoggedIn, isAdmin } = useAuth();
  const { title, description, canonical } = getMetaData(window.location.pathname);

  return (
    <HelmetProvider>
      <div>
        <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
        <ScrollToTopOnNavigation />
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <link rel="canonical" href={canonical} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta
            property="og:image"
            content="https://projectschool.dev/static/media/navbarlogo.a5e20d48192811086a79.webp"
          />
          <meta property="og:url" content={canonical} />
          <meta property="og:type" content="website" />
        </Helmet>

        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Welcome />} />
            <Route
              path="/login"
              element={
                isCrawler() ? (
                  <ProtectedRoutePreview
                    title="Login - ProjectSchool"
                    description="Please log in to access exclusive React content."
                  />
                ) : isLoggedIn ? (
                  <Navigate
                    to={
                      new URLSearchParams(window.location.search).get("redirect") || "/"
                    }
                  />
                ) : (
                  <Login />
                )
              }
            />
            <Route
              path="/signup"
              element={
                isCrawler() ? (
                  <ProtectedRoutePreview
                    title="Sign Up - ProjectSchool"
                    description="Join ProjectSchool to master React with interactive exercises."
                  />
                ) : isLoggedIn ? (
                  <Navigate to="/" />
                ) : (
                  <Signup />
                )
              }
            />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/faq" element={<Faq />} />
            <Route
              path="/interview"
              element={
                isCrawler() ? (
                  <InterviewPreview />
                ) : (
                  withProtectedRoute(Interview)({ isLoggedIn, isAdmin })
                )
              }
            />
            <Route
              path="/interview/interview-questions"
              element={
                isCrawler() ? (
                  <ProtectedRoutePreview
                    title="React Interview Questions - ProjectSchool"
                    description="Practice common React interview questions."
                  />
                ) : (
                  withProtectedRoute(InterviewQuestions)({ isLoggedIn, isAdmin })
                )
              }
            />
            <Route
              path="/interview/interview-correct-code/:slug"
              element={
                isCrawler() ? (
                  <ProtectedRoutePreview
                    title="React Interview Code Solutions - ProjectSchool"
                    description="Review correct code solutions for React interview tasks."
                  />
                ) : (
                  withProtectedRoute(InterviewCorrectCode)({ isLoggedIn, isAdmin })
                )
              }
            />
            <Route
              path="/interview/interview-quiz/:slug"
              element={
                isCrawler() ? (
                  <ProtectedRoutePreview
                    title="React Interview Quiz - ProjectSchool"
                    description="Test your React knowledge with interview quizzes."
                  />
                ) : (
                  withProtectedRoute(InterviewQuestionQuiz)({ isLoggedIn, isAdmin })
                )
              }
            />
            <Route
              path="/interview/interview-tasks"
              element={
                isCrawler() ? (
                  <ProtectedRoutePreview
                    title="React Interview Tasks - ProjectSchool"
                    description="Practice React interview tasks and challenges."
                  />
                ) : (
                  withProtectedRoute(InterviewTasks)({ isLoggedIn, isAdmin })
                )
              }
            />
            <Route
              path="/interview/interview-code-quiz"
              element={
                isCrawler() ? (
                  <ProtectedRoutePreview
                    title="React Code Quiz - ProjectSchool"
                    description="Test your coding skills with React quizzes."
                  />
                ) : (
                  withProtectedRoute(InterviewCodeQuiz)({ isLoggedIn, isAdmin })
                )
              }
            />
            <Route path="/notavailable" element={<NotFound />} />
            <Route path="/blogs/devessentials" element={<DevEssentials />} />
            <Route path="/blogs/reactexplained" element={<ReactExplained />} />
            <Route path="/blogs" element={<BlogCardList />} />
            <Route path="/blogs/devessentials/:id" element={<Articles />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/resetPassword" element={<ResetPassword />} />
            <Route path="/reset-password-confirm" element={<ResetPasswordConfirm />} />
            <Route path="/auth-action-handler" element={<AuthActionHandler />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route
              path="/exercises"
              element={
                isCrawler() ? (
                  <ExercisesPreview />
                ) : (
                  withProtectedRoute(Exercises)({ isLoggedIn, isAdmin })
                )
              }
            />
            <Route
              path="/exercises/reactlessons"
              element={
                isCrawler() ? (
                  <ProtectedRoutePreview
                    title="React Lessons - ProjectSchool"
                    description="Interactive React lessons to boost your skills."
                  />
                ) : (
                  withProtectedRoute(ReactLessons)({ isLoggedIn, isAdmin })
                )
              }
            />
            <Route
              path="/exercises/livelessons"
              element={
                isCrawler() ? (
                  <ProtectedRoutePreview
                    title="Live Lessons - ProjectSchool"
                    description="Join live React lessons with expert instructors."
                  />
                ) : (
                  withProtectedRoute(LiveLessons)({ isLoggedIn, isAdmin })
                )
              }
            />
            <Route
              path="/exercises/musicacademy"
              element={
                isCrawler() ? (
                  <ProtectedRoutePreview
                    title="Music Academy - ProjectSchool"
                    description="Learn React through music-themed projects."
                  />
                ) : (
                  withProtectedRoute(MusicAcademy)({ isLoggedIn, isAdmin })
                )
              }
            />
            <Route
              path="/exercises/workshoplist"
              element={
                isCrawler() ? (
                  <ProtectedRoutePreview
                    title="Workshops - ProjectSchool"
                    description="Explore React workshops and hands-on sessions."
                  />
                ) : (
                  withProtectedRoute(WorkshopList)({ isLoggedIn, isAdmin })
                )
              }
            />
            <Route
              path="/reference"
              element={
                isCrawler() ? (
                  <ProtectedRoutePreview
                    title="React Reference - ProjectSchool"
                    description="Quick reference guide for React concepts."
                  />
                ) : (
                  withProtectedRoute(Reference)({ isLoggedIn, isAdmin })
                )
              }
            />
            <Route
              path="/livechat"
              element={
                isCrawler() ? (
                  <ProtectedRoutePreview
                    title="Live Chat - ProjectSchool"
                    description="Chat live with React experts and peers."
                  />
                ) : (
                  withProtectedRoute(LiveChat)({ isLoggedIn, isAdmin })
                )
              }
            />
            <Route
              path="/exercises/:lessonType/:taskId"
              element={
                isCrawler() ? (
                  <ProtectedRoutePreview
                    title="Live Editor - ProjectSchool"
                    description="Practice React with an interactive live editor."
                  />
                ) : (
                  withProtectedRoute(LiveEditor)({ isLoggedIn, isAdmin })
                )
              }
            />
            <Route
              path="/authors"
              element={
                isCrawler() ? (
                  <ProtectedRoutePreview
                    title="Authors - ProjectSchool"
                    description="Meet the experts behind ProjectSchool’s content."
                  />
                ) : (
                  withProtectedRoute(AuthorList)({ isLoggedIn, isAdmin })
                )
              }
            />
            <Route
              path="/success"
              element={
                isCrawler() ? (
                  <ProtectedRoutePreview
                    title="Payment Success - ProjectSchool"
                    description="Thank you for your purchase!"
                  />
                ) : (
                  withProtectedRoute(Success)({ isLoggedIn, isAdmin })
                )
              }
            />
            <Route
              path="/cancel"
              element={
                isCrawler() ? (
                  <ProtectedRoutePreview
                    title="Payment Cancelled - ProjectSchool"
                    description="Payment was cancelled. Contact us for assistance."
                  />
                ) : (
                  withProtectedRoute(Cancel)({ isLoggedIn, isAdmin })
                )
              }
            />
            <Route
              path="/mobile-message"
              element={
                isCrawler() ? (
                  <ProtectedRoutePreview
                    title="Mobile Message - ProjectSchool"
                    description="Optimized React learning for mobile users."
                  />
                ) : (
                  withProtectedRoute(MobileMessage)({ isLoggedIn, isAdmin })
                )
              }
            />
            <Route path="*" element={<WrongRoute />} /> {/* Catch-all route */}
          </Routes>
        </Suspense>
      </div>
    </HelmetProvider>
  );
};

// Error Boundary Component
class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) return <h1>Something went wrong.</h1>;
    return this.props.children;
  }
}

const AppWithErrorBoundary = () => (
  <ErrorBoundary>
    <App />
  </ErrorBoundary>
);

export default AppWithErrorBoundary;
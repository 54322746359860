import React from "react";
import styles from "./BlogCard.module.css";
import BlogCard from "./BlogCard";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import BlogTitle from "./BlogTitle";
import ScrollToTopOnNavigation from "../components/ScrollToTopOnNavigation";

const BlogCardList = () => {
 
  const posts = [
    {
      heading: "Dev Essentials: Core Guides & Tools",
      description:
        "Your ultimate resource for comprehensive guides on vital development tools and techniques. These in-depth resources provide clear, step-by-step insights to enhance your projects and sharpen your skills. Perfect for developers of all levels seeking to boost productivity and elevate the quality of their work.",
      link: "/blogs/devessentials",
    },
    {
      heading: "React FAQs: Essential Questions Answered",
      description:
        "Get clear, concise answers to some of the most frequently asked questions about React. Explore topics like the differences between React and React Native, the async nature of hooks, and the current demand for React developers. This collection provides quick insights for developers of all experience levels, helping you deepen your understanding and make informed decisions in your React journey.",
      link: "/blogs/reactexplained",
    },
  ];

  return (
    <div>
      <ScrollToTopOnNavigation />

      <Navbar />
      <BlogTitle />
      <div className={styles.cardMasonry}>
        {posts.map((post, index) => (
          <BlogCard key={index} {...post} />
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default BlogCardList;

import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { gsap } from "gsap";
import { Link } from "react-router-dom";
import "../styles/section1.css";

// Import images (assuming these are still relevant)
import image1 from "../images/pslogo.webp"; // big logo
import sectionImg3 from "../images/sectionImg3.webp"; // board
import pslogosmall from "../images/pslogosmall.webp"; // small logo
import image3 from "../images/reactLogo.webp"; // React intro
import image4 from "../images/live-trans.webp"; // Live projects
import image6 from "../images/buildWitME.webp"; // Build with Me
import image7 from "../images/webDevInterview.webp"; // Interview
import image5 from "../images/sectionPicture2.webp"; // Think differently
import image2 from "../images/devEssFAQ.webp"; // Think differently
import CrazyScrollPhrase from "./CrazyScrollPhrase";



function Section1({ isLoggedIn }) {
  const logo = useRef(null);
  const pslogosmallRef = useRef(null);
  const sectionImg3Ref = useRef(null);

  useEffect(() => {
    const animation = () => {
      const elements = { sectionImg3: sectionImg3Ref.current };
      if (Object.values(elements).every((el) => el)) {
        gsap.set(elements.sectionImg3, { opacity: 0 });
        const tl = gsap.timeline();
        const screenWidth = window.innerWidth;
        if (screenWidth <= 640) return;
        tl.to(
          elements.sectionImg3,
          { opacity: 0.4, top: "50%", duration: 1, ease: "linear" },
          0
        );
      }
    };
    animation();
  }, []);

  const fadeInLeft = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  };
  const fadeInRight = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  };
  const fadeInUp = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  };
  const staggerContainer = {
    hidden: { opacity: 1 },
    visible: { opacity: 1, transition: { staggerChildren: 0.3 } },
  };
  const parallaxEffect = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { type: "spring", stiffness: 100, damping: 30 },
    },
  };

  return (
    <div className='container'>
      <header>
        <div className='word-container'>
          <link
            rel='preload'
            href={pslogosmall}
            as='image'
            media='(max-width: 640px)'
          />
          <h1 className='main-header'>
            <div className='decorator'></div>
            <div className='decorator'></div>
            <div className='content'>
              Master <span className='react'>React</span> <br />
              <span className='highlight'>Through Hands-On Building</span>
            </div>
          </h1>
          <div className='sectionImages-container'>
            <img
              src={sectionImg3}
              alt='Decorative board'
              className='sectionImg3'
              ref={sectionImg3Ref}
              width='800'
              height='400'
              fetchpriority='high'
              decoding='async'
              loading='eager'
            />
            <img
              className='projectschoolImg'
              src={image1}
              alt='Project School logo'
              ref={logo}
              loading='lazy'
              width='450'
              height='200'
            />
          </div>
          <img
            src={pslogosmall}
            alt='Project School small logo'
            className='pslogosmall'
            ref={pslogosmallRef}
            width='450'
            height='300'
          />
          <div className='crazy-scroll-wrapper'>
            <CrazyScrollPhrase />
          </div>
        </div>
      </header>
      <main>
        <motion.section
          className='content-section'
          initial='hidden'
          whileInView='visible'
          viewport={{ once: false, amount: 0.5 }}
          variants={staggerContainer}
        >
          <div className='feature ipsGrid ipsGrid_collapsePhone'>
            <motion.div
              className='ipsGrid_span7 ipsType_right'
              variants={fadeInLeft}
            >
              <p>
                <span>
                  Welcome to Project School, where we redefine React learning by
                  focusing on <strong>doing</strong>, not just studying. Forget
                  endless theory—our approach dives straight into practical,
                  real-world projects to build your skills fast.
                </span>
              </p>
              <p>
                <span>
                  From live coding sessions to interview prep, we’ve got you
                  covered with hands-on experiences that bridge the gap between
                  learning and building modern web applications.
                </span>
              </p>
              <p>
                <span>
                  Join us to level up your React game—whether you're a beginner
                  or prepping for your next big gig!
                </span>
              </p>
            </motion.div>
            <motion.div className='ipsGrid_span5' variants={parallaxEffect}>
              <img
                src={image5}
                alt='Hands-on React learning'
                className='section2-image neon-flash short-circuit'
              />
            </motion.div>
          </div>
        </motion.section>

        {/* Core Sections Highlight */}
        <motion.div
          className='features'
          initial='hidden'
          whileInView='visible'
          viewport={{ once: false, amount: 0.5 }}
          variants={staggerContainer}
        >
          {/* Building Dynamic User Interfaces */}
          <motion.div
            className='feature ipsSpacer_bottom_double ipsGrid ipsGrid_collapsePhone'
            variants={fadeInLeft}
          >
            <div className='ipsGrid_span5'>
              <img
                className='section1-image'
                src={image3}
                alt='Building Dynamic UIs'
              />
            </div>
            <div className='ipsGrid_span7 ipsType_left'>
              <h2 className='heading_large'>
                Building Dynamic User Interfaces
              </h2>
              <motion.ul
                className='section1-list'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
              >
                <li>
                  Master React’s core concepts: components, state, and hooks.
                </li>
                <li>Explore the React ecosystem for modern web development.</li>
                <li>Build engaging, dynamic UIs with practical exercises.</li>
              </motion.ul>
            </div>
          </motion.div>

          {/* Live React Projects */}
          <motion.div
            className='feature ipsSpacer_bottom_double ipsGrid ipsGrid_collapsePhone'
            variants={fadeInRight}
          >
            <div className='ipsGrid_span7 ipsType_right'>
              <h2 className='heading_large'>Live React Projects</h2>
              <motion.ul
                className='section1-list'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
              >
                <li>Join live sessions to build real-world projects.</li>
                <li>Watch us source ideas from Freelancer and Fiverr.</li>
                <li>Learn React hands-on, step-by-step.</li>
              </motion.ul>
            </div>
            <div className='ipsGrid_span5'>
              <img
                className='section1-image'
                src={image4}
                alt='Live React Projects'
              />
            </div>
          </motion.div>

          {/* Build with Me */}
          <motion.div
            className='feature ipsSpacer_bottom_double ipsGrid ipsGrid_collapsePhone'
            variants={fadeInLeft}
          >
            <div className='ipsGrid_span5'>
              <img
                className='section1-image'
                src={image6}
                alt='Build with Me'
              />
            </div>
            <div className='ipsGrid_span7 ipsType_left'>
              <h2 className='heading_large'>Build with Me</h2>
              <motion.ul
                className='section1-list'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
              >
                <li>Dive into live project builds with expert guidance.</li>
                <li>See the thought process and best practices in action.</li>
                <li>Gain insights from real-world troubleshooting.</li>
              </motion.ul>
            </div>
          </motion.div>
        </motion.div>

        {/* Interview Prep Section */}
        <motion.div
          className='features'
          initial='hidden'
          whileInView='visible'
          viewport={{ once: false, amount: 0.5 }}
          variants={staggerContainer}
        >
          <motion.div
            className='feature ipsSpacer_bottom_double ipsGrid ipsGrid_collapsePhone'
            variants={fadeInRight}
          >
            <div className='ipsGrid_span7 ipsType_right'>
              <h2 className='heading_large'>Ace Your React Interview</h2>
              <motion.ul
                className='section1-list'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
              >
                <li>Practice curated React interview questions.</li>
                <li>Tackle hands-on JavaScript/React tasks.</li>
                <li>Test your skills with quizzes and code challenges.</li>
              </motion.ul>
            </div>
            <div className='ipsGrid_span5'>
              <img
                className='section1-image'
                src={image7}
                alt='React Interview Prep'
              />
            </div>
          </motion.div>
        </motion.div>

        {/* Dev Essentials & FAQs */}
        <motion.div
          className='features'
          initial='hidden'
          whileInView='visible'
          viewport={{ once: false, amount: 0.5 }}
          variants={staggerContainer}
        >
          <motion.div
            className='feature ipsSpacer_bottom_double ipsGrid ipsGrid_collapsePhone'
            variants={fadeInLeft}
          >
            <div className='ipsGrid_span5'>
              <img
                className='section1-image'
                src={image2}
                alt='Dev Essentials'
              />
            </div>
            <div className='ipsGrid_span7 ipsType_left'>
              <h2 className='heading_large'>Dev Essentials & FAQs</h2>
              <motion.ul
                className='section1-list'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
              >
                <li>
                  In-depth guides on a wide range of vital development tools.
                </li>
                <li>
                  Clear answers to essential React questions for all levels.
                </li>
                <li>
                  Practical insights to elevate your skills and project quality.
                </li>
              </motion.ul>
            </div>
          </motion.div>
        </motion.div>

        {/* Call to Action */}
        <div className='button-container'>
          {isLoggedIn ? (
            <>
              <Link to='/exercises' className='btn-16'>
                Start Building
              </Link>
              <Link to='/interview' className='btn-16'>
                Prep for Interviews
              </Link>
            </>
          ) : (
            <Link to='/signup' className='getStartedButton'>
              Join Now
            </Link>
          )}
        </div>

        {/* Quote & Chat */}
        <motion.div className='citate' variants={fadeInUp}>
          <h1 className='header-section ipsType_center'>
            "Code is like humor. When you have to explain it, it’s bad." – Cory
            House
          </h1>
        </motion.div>
        <motion.div className='citate' variants={fadeInUp}>
          <h1 className='header-section ipsType_center'>
            Need help?{" "}
            <Link to='/livechat' className='section1-chat'>
              Chat
            </Link>{" "}
            with us live!
          </h1>
        </motion.div>
      </main>
    </div>
  );
}

export default Section1;

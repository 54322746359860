/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PriceCard from "./PriceCard";
import "../styles/checkout.css";
import { auth } from "../components/firebase";

const PriceCardsList = () => {
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [stripe, setStripe] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  // Fetch admin status from MongoDB via backend
  useEffect(() => {
    const fetchAdminStatus = async () => {
      const user = auth.currentUser;
      if (user) {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/${user.uid}/admin-status`);
          if (!response.ok) {
            throw new Error("Failed to fetch admin status");
          }
          const data = await response.json();
          setIsAdmin(data.isAdmin); // Set admin status from MongoDB
          console.log("Admin status fetched:", data.isAdmin);
        } catch (error) {
          console.error("Error fetching admin status:", error);
          toast.error("Failed to fetch admin status. Please try again later.");
        }
      } else {
        console.log("No authenticated user");
      }
    };

    fetchAdminStatus();
  }, []);

  // Dynamically import Stripe
  useEffect(() => {
    const loadStripe = async () => {
      try {
        const { loadStripe } = await import("@stripe/stripe-js");
        const stripeInstance = await loadStripe(process.env.REACT_APP_STRIPE_KEY);
        setStripe(stripeInstance);
      } catch (error) {
        console.error("Failed to load Stripe:", error);
        toast.error("Failed to load payment processor. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    loadStripe();
  }, []);

  const handleCheckout = async (priceId) => {
    if (!isTermsAccepted) {
      toast.error("Please accept the terms and conditions to proceed.");
      return;
    }

    try {
      if (!stripe) {
        toast.error("Stripe is not yet loaded. Please try again.");
        return;
      }

      const user = auth.currentUser;
      if (!user) {
        toast.error("Please log in to proceed with checkout.");
        navigate("/login");
        return;
      }

      setIsLoading(true);

      const { error } = await stripe.redirectToCheckout({
        lineItems: [{ price: priceId, quantity: 1 }],
        mode: "subscription",
        clientReferenceId: user.uid,
        successUrl: `${window.location.origin}/success`,
        cancelUrl: `${window.location.origin}/cancel`,
      });

      if (error) {
        toast.error("There was an error with the checkout. Please try again.");
        console.error("Error Message:", error.message);
      }
    } catch (error) {
      toast.error("Checkout process failed. Please try again.");
      console.error("Checkout Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFreeButtonClick = () => {
    const user = auth.currentUser;
    if (user) {
      navigate("/exercises");
    } else {
      navigate("/signup");
    }
  };

  return (
    <div>
      <div className='price-cards-container'>
        <PriceCard
          title='Free'
          price='0'
          advantages={[
            "Access to all course materials.",
            "Receive 5 or more new tasks every month.",
            "Access to 24/7 free support.",
            "Solutions provided for each task.",
            "Access to a private Discord forum server.",
          ]}
          buttonText='Get started'
          trialDays=''
          onButtonClick={handleFreeButtonClick}
        />
        <PriceCard
          title='Monthly'
          price='14.99'
          advantages={[
            "Access to all course materials.",
            "Receive 5 or more new tasks every month.",
            "Access to 24/7 free support.",
            "Solutions provided for each task.",
            "Access to a private Discord forum server.",
            "Support free education.",
          ]}
          buttonText='Get started'
          trialDays=''
          onButtonClick={() => handleCheckout("price_1Pwqdd2NvwaBESkuxTiU3ozx")}
        />
        <PriceCard
          title='Annual'
          price='100'
          advantages={[
            "Access to all course materials.",
            "Receive 5 or more new tasks every month.",
            "Access to 24/7 free support.",
            "Solutions provided for each task.",
            "Access to a private Discord forum server.",
            "Support free education.",
          ]}
          buttonText='Get started'
          trialDays=''
          onButtonClick={() => handleCheckout("price_1PwqmY2NvwaBESkuFDHCkFbd")}
        />
        {isAdmin && (
          <PriceCard
            title='Admin Special'
            price='0'
            advantages={[
              "Access to all course materials.",
              "Receive 5 or more new tasks every month.",
              "Access to 24/7 free support.",
              "Solutions provided for each task.",
              "Access to a private Discord forum server.",
              "Admin-only features.",
            ]}
            buttonText='Get started'
            trialDays=''
            onButtonClick={() => alert("Admin special access granted!")}
          />
        )}
      </div>
      <div className='terms-links'>
        <label>
          <input
            className="terms-input"
            type='checkbox'
            checked={isTermsAccepted}
            onChange={() => setIsTermsAccepted(!isTermsAccepted)}
          />
          I have read and agree to the{" "}
          <a
            className="touch-target"
            href='https://projectschool.dev/#/terms'
            target='_blank'
            rel='noopener noreferrer'
          >
            Terms and Conditions
          </a>{" "}
          and{" "}
          <a
            className="touch-target"
            href='https://projectschool.dev/#/privacy'
            target='_blank'
            rel='noopener noreferrer'
          >
            Privacy Policy
          </a>
        </label>
      </div>
    </div>
  );
};

export default PriceCardsList;
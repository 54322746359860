const referenceData = [
 
    {
      img: "./images/React-icon.webp",
      title: "React",
      desc: "",
      to: "/reference/reactreference",
    },
    {
      img: "./images/live-trans.webp",
      title: "Express.js",
      desc: "",
      to: "/exercises/livelessons",
    },

  ];


  export {referenceData}
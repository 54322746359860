import React from "react";
import ExercisesCardList from "./ExercisesCardList";
import ExercisesTitle from "./ExercisesTitle";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ScrollToTopOnNavigation from "../components/ScrollToTopOnNavigation";


const Exercises = () => {
  return (
    <div>
      <ScrollToTopOnNavigation />
      <Navbar />
      <ExercisesTitle />
      <ExercisesCardList />
      <Footer />
    </div>
  );
};

export default Exercises;

const cheatsheetData = [
  {
    taskId: "3D-Interactive-Card",
    content: [
      {
        title: "Components & Hooks",
        subtitle: "App Component",
        details: [
          "Manages theme state and local storage for theme persistence.",
          "Toggles between light and dark themes.",
        ],
      },
      {
        title: "",
        subtitle: "useState Hook",
        details: ["Manages the state of the theme."],
        image: "/images/cheatsheetImages/task1/1.webp",
      },
      {
        title: "",
        subtitle: "useEffect Hook",
        details: [
          "Updates the document class and local storage when the theme changes.",
        ],
        image: "/images/cheatsheetImages/task1/2.webp",
      },
      {
        title: "Functions",
        subtitle: "getStorageTheme",
        details: [
          "Retrieves the current theme from local storage or defaults to 'light-theme'.",
        ],
        image: "/images/cheatsheetImages/task1/4.webp",
      },
      {
        title: "",
        subtitle: "toggleTheme",
        details: ["Toggles between 'light-theme' and 'dark-theme'."],
        image: "/images/cheatsheetImages/task1/6.webp",
      },
    ],
  },
  {
    taskId: "BMI-Tracker",
    content: [
      {
        title: "State Management",
        subtitle: "",
        details: [
          "The useState hook is used to manage state within functional components. Here, we create state variables for weight, height, BMI, and the message to keep track of user inputs and calculation results.",
        ],
        image: "/images/cheatsheetImages/task2/1.webp",
      },
      {
        title: "Handle Input Changes",
        subtitle: "",
        details: [
          "Controlled components in React use state to manage form inputs. The onChange event handlers ensure that the state variables weight and height are updated with the current values of the input fields as the user types.",
        ],
        image: "/images/cheatsheetImages/task2/2.webp",
      },
      {
        title: "Calculate BMI",
        subtitle: "",
        details: [
          "The BMI calculation requires converting height to meters and using the BMI formula. This function handles the computation and ensures that valid inputs are provided before performing the calculation. It also updates the state with the calculated BMI and a message indicating the BMI category.",
        ],
        image: "/images/cheatsheetImages/task2/3.webp",
      },
      {
        title: "Determine BMI Category",
        subtitle: "",
        details: [
          "This function uses the calculated BMI value to determine the user's BMI category based on predefined ranges. It returns a message that categorizes the BMI as underweight, normal, overweight, or other categories.",
        ],
        image: "/images/cheatsheetImages/task2/4.webp",
      },
      {
        title: "Reset Form",
        subtitle: "",
        details: [
          "Providing a reset button allows users to clear all the data from the form and reset the component's state. This enhances user experience by allowing users to quickly start over with new inputs.",
        ],
        image: "/images/cheatsheetImages/task2/5.webp",
      },
    ],
  },
  {
    taskId: "Dark-Light-Mode-Toggle",
    content: [
      {
        title: "State Management",
        subtitle: "",
        details: [
          "State is a way to manage dynamic data in a component. In this case, theme holds the current theme (light or dark).",
          "'useState' initializes theme with a value from local storage using the 'getStorageTheme' function.",
          "'setTheme' is a function to update the state.",
        ],
        image: "/images/cheatsheetImages/task3/1.webp",
      },

      {
        title: "Effect Hook",
        subtitle: "",
        details: [
          "'useEffect' is used for side effects, such as changing the document's class or updating local storage when theme changes.",
          "The second argument, [theme], specifies that the effect runs every time theme changes.",
        ],
        image: "/images/cheatsheetImages/task3/2.webp",
      },

      {
        title: "Event Handling",
        subtitle: "",
        details: [
          "Functions are reusable blocks of code. 'toggleTheme' switches the current theme based on its value.",
          "This function is called when the button is clicked.",
        ],
        image: "/images/cheatsheetImages/task3/3.webp",
      },
      {
        title: "Rendering Articles",
        subtitle: "",
        details: [
          "Mapping over an array to render components is common in React. Here, 'data.map' iterates through data and renders an Article component for each item.",
          "key is a unique identifier for each item in the list, helping React optimize rendering.",
        ],
        image: "/images/cheatsheetImages/task3/4.webp",
      },
      {
        title: "Moment.js for Date Formatting",
        subtitle: "",
        details: [
          "Moment.js is a library for date manipulation. It formats the date prop into a human-readable string.",
          "This enhances the user experience by displaying dates in a more accessible way.",
        ],
        image: "/images/cheatsheetImages/task3/5.webp",
      },
    ],
  },
  {
    taskId: "Sortify-Search-by-Name",
    content: [
      {
        title: "Setting up the State",
        subtitle: "",
        details: [
          "In React, state is used to manage dynamic data in the component. Here, 'search' holds the search query entered by the user, and 'contacts' holds the list of contact data from data.js",
          "A React hook used to initialize state. 'setSearch' and 'setContacts' are functions used to update the 'search' and 'contacts' state, respectively.",
        ],
        image: "/images/cheatsheetImages/task4/1.webp",
      },
      {
        title: "Handling Input Changes",
        subtitle: "",
        details: [
          "A function is used to define a specific task. Here, the 'handleSearchChange' function updates the 'search' state when the user types in the input field.",
          "e.target.value: Captures the current value typed by the user.",
        ],
        image: "/images/cheatsheetImages/task4/2.webp",
      },
      {
        title: "Sorting Contacts by First Name",
        subtitle: "",
        details: [
          "sortName function: This function sorts the contacts alphabetically by first name.",
          "spread operator (...contacts): It creates a copy of the contacts array before sorting, which avoids mutating the original state.",
          "setContacts: Updates the contacts state with the sorted data.",
        ],
        image: "/images/cheatsheetImages/task4/3.webp",
      },
      {
        title: "JSX: Rendering the Form Input and Table",
        subtitle: "",
        details: [
          "Form.Control: A Bootstrap input field to enter the search query. The 'onChange' event handler listens for user input and triggers 'handleSearchChange'.",
        ],
        image: "/images/cheatsheetImages/task4/4.webp",
      },
      {
        title: "",
        subtitle: "",
        details: [
          "Button component: A Bootstrap button that calls the 'sortName' function when clicked, sorting the contacts.",
        ],
        image: "/images/cheatsheetImages/task4/5.webp",
      },
      {
        title: "",
        subtitle: "",
        details: [
          "Table component: This uses the Bootstrap 'Table' component to render the list of contacts in a tabular format. The 'filter' method is used to display only contacts that match the search input.",
          "map method: It loops through each contact and displays the relevant information in the table rows.",
        ],
        image: "/images/cheatsheetImages/task4/6.webp",
      },
      {
        title: "Filtering Contacts",
        subtitle: "",
        details: [
          "filter method: Filters the contacts based on the search input. If the 'search' string is empty, it returns all contacts. Otherwise, it returns only those contacts whose first name includes the search term.",
        ],
        image: "/images/cheatsheetImages/task4/7.webp",
      },
    ],
  },

  {
    taskId: "Simple-Quiz-App",
    content: [
      {
        title: "State Management",
        subtitle: "",
        details: [
          "State: A way to store and manage dynamic values in React.",
          "'useState': This hook allows you to create a state variable and a function to update it.",
          "'currentQuestion': Tracks the current question index.",
          "'score': Tracks the total score of the quiz.",
          "'correctAnswers': Tracks the number of correct answers.",
          "'incorrectAnswers': Tracks the number of incorrect answers.",
        ],
        image: "/images/cheatsheetImages/task5/1.webp",
      },
      {
        title: "Array of Questions",
        subtitle: "",
        details: [
          "Array: Stores a collection of objects, each containing a 'questionText' and an 'answerOptions' array.",
          "'answerOptions': Each object inside 'answerOptions' holds an answerText and a boolean 'isCorrect' to mark if the answer is correct.",
        ],
        image: "/images/cheatsheetImages/task5/2.webp",
      },
      {
        title: "Rendering Questions and Answers",
        subtitle: "",
        details: [
          "Displaying Questions: Access the 'questionText' of the current question using currentQuestion.",
          "Mapping over answer options: map() iterates over each answer option and renders a button for each one.",
          "onClick Event: When a button is clicked, it calls 'handleAnswerButtonClick', passing whether the selected answer is correct.",
        ],
        image: "/images/cheatsheetImages/task5/3.webp",
      },
      {
        title: "Answer Button Handling",
        subtitle: "",
        details: [
          "Event Handler: Handles what happens when an answer is clicked.",
          "isCorrect: Passed in to check if the selected answer is correct.",
          "State Updates: Increments 'score' and 'correctAnswers' if correct, or 'incorrectAnswers' if incorrect.",
          "Next Question: Moves to the next question or calls 'finishQuiz' if the quiz is over.",
        ],
        image: "/images/cheatsheetImages/task5/4.webp",
      },
      {
        title: "Finishing the Quiz",
        subtitle: "",
        details: [
          "finishQuiz: Updates 'currentQuestion' to a value beyond the number of questions, which triggers the score section to render.",
        ],
        image: "/images/cheatsheetImages/task5/5.webp",
      },
      {
        title: "Conditional Rendering",
        subtitle: "",
        details: [
          "Conditional Rendering: Displays either the score section or the question section based on whether the quiz is finished (currentQuestion >= questions.length).",
        ],
        image: "/images/cheatsheetImages/task5/6.webp",
      },
    ],
  },
  {
    taskId: "FAQ",
    content: [
      {
        title: "useState Hook",
        subtitle:
          "useStat': React's useState hook is used to add state to functional components.",
        details: [
          "Here, 'questions' holds the state of the d'ata, and 'setQuestions' is the function used to update that state.",
          "State is used to store information that changes over time, and it re-renders the component when updated.",
        ],
        image: "/images/cheatsheetImages/task6/1.webp",
      },
      {
        title: "Mapping Over an Array",
        subtitle:
          "map(): The 'map()' function loops over the questions array and renders a Question component for each element.",
        details: [
          "This allows dynamic rendering of multiple 'Question' components based on the 'data'.",
          "'key={question.id}' ensures each item has a unique identifier, which is important for React's reconciliation process to optimize rendering.",
          "'{...question}' passes all properties of the 'question' object (like 'title' and 'info') as props to the 'Question' component.",
        ],
        image: "/images/cheatsheetImages/task6/2.webp",
      },
      {
        title: "Component Props",
        subtitle: "",
        details: [
          "Props are arguments passed from parent components to child components. Here, title and info are destructured from the props object.",
          "Props allow data to be passed from the App component to each Question component.",
        ],
        image: "/images/cheatsheetImages/task6/3.webp",
      },
      {
        title: "Event Handling",
        subtitle:
          "onClick Event: The button listens for a click event, and when clicked, the 'setShowInfo' function toggles the value of 'showInfo' (true/false).",
        details: [
          "Clicking the button switches between showing (true) and hiding (false) the information.",
          "This is a common way to toggle visibility in React using the component's state.",
        ],
        image: "/images/cheatsheetImages/task6/4.webp",
      },
      {
        title: "Conditional Rendering",
        subtitle:
          "Conditional Rendering: This line conditionally renders the <p> element. If 'showInfo' is true, the paragraph containing 'info' is displayed; otherwise, it is hidden.",
        details: [
          "This is used to show or hide the question's details based on the user's interaction.",
        ],
        image: "/images/cheatsheetImages/task6/5.webp",
      },
      {
        title: "Icons for UI Feedback",
        subtitle:
          "Ternary Operator: The ? : syntax is used to display one of two icons based on the state of showInfo. If showInfo is true, it shows the 'minus' icon, otherwise it shows the 'plus' icon.",
        details: [
          "This provides visual feedback to the user, indicating whether the question's details are visible or not.",
        ],
        image: "/images/cheatsheetImages/task6/6.webp",
      },
    ],
  },

  {
    taskId: "Birthday-Reminder",
    content: [
      {
        title: "",
        subtitle: "useState Hook",
        details: [
          "'useState' is used to manage the state of the people array, which holds the list of individuals. 'data' is initially imported and assigned as the default value.",
          "'setPeople([])' clears the list when the 'clear all' button is clicked.",
        ],
        image: "/images/cheatsheetImages/task7/1.webp",
      },
      {
        title: "",
        subtitle: "Event Handling",
        details: [
          "'onClick' is an event handler that listens for button clicks. When clicked, the state of 'people' is updated to an empty array, clearing the list.",
        ],
        image: "/images/cheatsheetImages/task7/2.webp",
      },
      {
        title: "",
        subtitle: "Rendering Components",
        details: [
          "The List component is responsible for displaying the filtered list of people who have birthdays today, which is calculated using BirthdayLogic.",
        ],
        image: "/images/cheatsheetImages/task7/3.webp",
      },
      {
        title: "",
        subtitle: "Date Manipulation",
        details: [
          "'Date' objects are used to get today's date and format it as MM-DD to compare with each person's date of birth (DOB).",
        ],
        image: "/images/cheatsheetImages/task7/4.webp",
      },
      {
        title: "",
        subtitle: "Filtering an Array",
        details: [
          "The 'filter()' function is used to create a new array, 'birthdaysToday', by checking whether each person's birthday matches today's date.",
        ],
        image: "/images/cheatsheetImages/task7/5.webp",
      },
      {
        title: "",
        subtitle: "Props",
        details: [
          "The 'List' component receives 'people' as props, which contains an array of people who have birthdays today. Props allow data to be passed down from parent components (like App) to child components (like List).",
        ],
        image: "/images/cheatsheetImages/task7/6.webp",
      },
      {
        title: "",
        subtitle: "Conditional Rendering",
        details: [
          "Conditional rendering is used to display the list only if there are birthdays today (people.length > 0).",
        ],
        image: "/images/cheatsheetImages/task7/7.webp",
      },
      {
        title: "",
        subtitle: "Rendering a List",
        details: [
          "The 'map()' function iterates over the 'people' array and returns JSX for each person, which includes their 'id', 'name', 'age', and 'image'.",
        ],
        image: "/images/cheatsheetImages/task7/8.webp",
      },
    ],
  },

  {
    taskId: "Dynamic-Box-Shadow-Generator",
    content: [
      {
        title: "",
        subtitle: "State Management: useState Hook",
        details: [
          "useState: The useState hook is used to manage component state. shadows and controls are initialized with an empty array and an initial control respectively. The setShadows and setControls functions update these states when changes occur.",
          "shadows: Stores an array of shadow styles as strings. Each string represents one layer of a CSS box-shadow.",
          "controls: Manages the number of ControlBox components dynamically rendered on the UI. Each control corresponds to a box shadow layer.",
        ],
        image: "/images/cheatsheetImages/task8/1.webp",
      },
      {
        title: "",
        subtitle: "Updating Box Shadows: updateShadow Function",
        details: [
          "If the inset property is true, the shadow string will include inset. Otherwise, it's a regular shadow.",
          "The corresponding shadow in the shadows array is updated, and the state is set with setShadows.",
          "s: Object representing shadow properties (x, y, blur, spread, color, and inset).",
          "id: Index of the shadow layer being updated.",
        ],
        image: "/images/cheatsheetImages/task8/2.webp",
      },
      {
        title: "",
        subtitle: "Adding New Shadow Layers: addShadow Function",
        details: [
          "A new control is appended to the controls array, allowing another ControlBox to render.",
          "A blank shadow string is added to the shadows array, ready to be filled in when the user interacts with the new ControlBox.",
        ],
        image: "/images/cheatsheetImages/task8/3.webp",
      },
      {
        title: "",
        subtitle: "Undo Last Shadow Layer: undoAddLayer Function",
        details: [
          "Checks if there is more than one layer present.",
          "Uses pop() to remove the last control and shadow, updating the state with setControls and setShadows.",
        ],
        image: "/images/cheatsheetImages/task8/4.webp",
      },
      {
        title: "",
        subtitle: "Dynamic Control Box Components: ControlBox",
        details: [
          "key={index}: React requires a key prop when rendering lists of elements to uniquely identify each component.",
          "id={index}: The id prop is passed to identify which shadow is being updated.",
          "updateShadow={updateShadow}: Passes the updateShadow function as a prop, allowing ControlBox to update the corresponding shadow in the parent component (App).",
        ],
        image: "/images/cheatsheetImages/task8/5.webp",
      },
      {
        title: "",
        subtitle: "Event Handling for Adding and Undoing Layers",
        details: [
          "The onClick event handler is used to call the respective function. addShadow adds a new shadow layer, and undoAddLayer removes the last one.",
        ],
        image: "/images/cheatsheetImages/task8/6.webp",
      },
    ],
  },
  {
    taskId: "Memory-Game",
    content: [
      {
        title: "App Component",
        subtitle: "State",
        details: [
          "useState(false) creates a 'startAgain' state, which controls when the game should reset.",
        ],
        image: "/images/cheatsheetImages/task9/1.webp",
      },
      {
        title: "",
        subtitle: "Function handleStartAgain",
        details: [
          "When the 'Start Again' button is clicked, the 'startAgain' state is set to 'true', which triggers a reset of the game.",
          "After 100 milliseconds, it resets to false, allowing the user to start the game again.",
        ],
        image: "/images/cheatsheetImages/task9/2.webp",
      },
      {
        title: "",
        subtitle: "Passing Props",
        details: [
          "The 'Cards' component is passed the 'startAgain' prop, which tells it to shuffle and reset the cards when the game restarts.",
        ],
        image: "/images/cheatsheetImages/task9/3.webp",
      },
      {
        title: "Card Component",
        subtitle: "Props",
        details: [
          "The 'Card' component receives 3 props: item, id, and handleClick. Props are used to pass data from the 'Cards' component.",
        ],
        image: "/images/cheatsheetImages/task9/4.webp",
      },
      {
        title: "",
        subtitle: "Dynamic Class",
        details: [
          "The card's class changes based on its status (item.stat). It can be 'correct', 'wrong', or '' (empty).",
        ],
        image: "/images/cheatsheetImages/task9/5.webp",
      },
      {
        title: "",
        subtitle: "Event Handling",
        details: [
          "onClick={() => handleClick(id)} is an event handler that calls the 'handleClick' function from 'Cards' when a card is clicked.",
        ],
        image: "/images/cheatsheetImages/task9/6.webp",
      },
      {
        title: "Cards Component",
        subtitle: "Shuffling Items",
        details: [
          "setItems([...items].sort(() => Math.random() - 0.5)) shuffles the card array at the beginning and when the game restarts.",
        ],
        image: "/images/cheatsheetImages/task9/7.webp",
      },
      {
        title: "",
        subtitle: "useEffect for Reset",
        details: [
          "useEffect listens for changes in the startAgain prop. When it's true, it resets the items and previous selection.",
        ],
        image: "/images/cheatsheetImages/task9/8.webp",
      },
      {
        title: "",
        subtitle: "State 'prev'",
        details: [
          "'prev' holds the index of the previously clicked card. If no card has been clicked, 'prev' is -1.",
        ],
        image: "/images/cheatsheetImages/task9/9.webp",
      },
      {
        title: "",
        subtitle: "Matching Logic",
        details: [
          "The 'check' function compares the 'id' of the current card with the previous card. If they match, both are marked as 'correct', otherwise, they are temporarily marked 'wrong'.",
        ],
        image: "/images/cheatsheetImages/task9/10.webp",
      },
      {
        title: "",
        subtitle: "Click Handling",
        details: [
          "The handleClick function controls the logic when a card is clicked. If it's the first card, its stat is set to 'active'. If it's the second card, the check function compares them.",
        ],
        image: "/images/cheatsheetImages/task9/11.webp",
      },
    ],
  },

  {
    taskId: "Color-Generator",
    content: [
      {
        title: "App Component",
        subtitle: "Setting up State and Default Color List",
        details: [
          "color: Stores the user's input (the hex code).",
          "error: Boolean state for handling invalid input.",
          "list: Stores a list of shades generated from the input color using the 'Values' library.",
        ],
        image: "/images/cheatsheetImages/task10/1.webp",
      },
      {
        title: "",
        subtitle: "Handling Form Submission and Color Generation",
        details: [
          "e.preventDefault(): Prevents the form from refreshing the page.",
          "new Values(color).all(20): Uses the 'Values' library to generate a color palette with 20 variations of the input color.",
          "setList(colors): Updates the state to hold the new color palette.",
          "setError(true): Displays an error when an invalid color is input.",
          "Use 'try...catch' for error handling when working with external libraries or user inputs.",
        ],
        image: "/images/cheatsheetImages/task10/2.webp",
      },
      {
        title: "",
        subtitle: "Form and User Input",
        details: [
          "The 'input' field captures user input for a hex color code and updates the 'color' state via 'setColor'.",
          "The onSubmit event on the 'form' triggers the 'handleSubmit' function.",
          "{error ? 'error' : null} conditionally adds the error class if the user enters invalid input.",
        ],
        image: "/images/cheatsheetImages/task10/3.webp",
      },
      {
        title: "",
        subtitle: "Displaying the Color List",
        details: [
          "The list state holds the array of colors. The map method iterates through each color, rendering the SingleColor component.",
          "key={index}: A unique key for each item.",
          "{...color}: Destructures and passes all color properties as props.",
          "hexColor: Specific prop for the color's hex value.",
        ],
        image: "/images/cheatsheetImages/task10/4.webp",
      },
      {
        title: "SingleColor Component",
        subtitle: "Managing Single Color Display",
        details: [
          "alert: State to handle when the user copies the hex value to the clipboard.",
          "rgb.join(','): Converts the RGB array into a comma-separated string for the background color.",
          "hexValue: The hex string of the color, prefixed with #.",
          "The 'rgb' and 'hexColor' props are derived from the parent 'App' component and used to display and style the color.",
        ],
        image: "/images/cheatsheetImages/task10/5.webp",
      },
      {
        title: "",
        subtitle: "Clipboard Copy Functionality",
        details: [
          "navigator.clipboard.writeText(hexValue): Copies the hex color code to the clipboard.",
          "setAlert(true): Displays an alert message confirming the color has been copied.",
        ],
        image: "/images/cheatsheetImages/task10/6.webp",
      },
      {
        title: "",
        subtitle: "Rendering Single Color Box",
        details: [
          "The className conditionally adds the color-light class for colors with a light background (based on the index).",
          "The style prop sets the backgroundColor using the RGB values.",
          "onClick={copyToClipboard} triggers the copyToClipboard function when the user clicks the color box.",
        ],
        image: "/images/cheatsheetImages/task10/7.webp",
      },
    ],
  },

  {
    taskId: "Range-Slider",
    content: [
      {
        title: "",
        subtitle: " State Management with useState Hook",
        details: [
          "'useState' is used to manage the state of 'minVal' (left slider) and 'maxVal' (right slider).",
          "Initial state values are set to the 'min' and 'max' props.",
          "'setMinVal' and 'setMaxVal' are used to update the state whenever the slider values change.",
        ],
        image: "/images/cheatsheetImages/task11/1.webp",
      },
      {
        title: "",
        subtitle: "Refs for Direct DOM Manipulation",
        details: [
          "'useRef' is used to directly access and manipulate DOM elements ('leftval', 'rightval', and 'range').",
          "This allows us to move the sliders dynamically by changing their position in the DOM without causing a full re-render of the component.",
        ],
        image: "/images/cheatsheetImages/task11/2.webp",
      },
      {
        title: "",
        subtitle: "Handling Slider Changes",
        details: [
          "<input type='range' />: This creates a slider input. The 'min', 'max', and 'value' attributes control the range limits and current value.",
          "When the slider is moved, the 'onChange' event updates 'minVal' or 'maxVal' using the 'setMinVal' or 'setMaxVal' functions.",
          "The 'Math.min' and 'Math.max' methods ensure that the sliders don't cross over each other by limiting their values.",
        ],
        image: "/images/cheatsheetImages/task11/3.webp",
      },
      {
        title: "",
        subtitle: "Dynamic CSS with useEffect",
        details: [
          "'useEffect' is used to update the slider's CSS based on changes to 'minVal' and 'maxVal'.",
          "The position (left) and the transform properties are dynamically set on the sliders (leftval, rightval) based on the current values.",
          "This ensures the sliders visually move as the user interacts with them.",
        ],
        image: "/images/cheatsheetImages/task11/4.webp",
      },
      {
        title: "",
        subtitle: "'useCallback' to Optimize Performance",
        details: [
          "useCallback memoizes the getPercent function to avoid recalculating the percentage every time the component renders.",
          "This is useful for performance optimization when the same function is used multiple times within useEffect.",
        ],
        image: "/images/cheatsheetImages/task11/5.webp",
      },
      {
        title: "",
        subtitle: "'PropTypes' for Type Checking",
        details: [
          "'PropTypes' is used to ensure the 'min', 'max', and 'onChange' props are passed correctly to the component.",
          "This provides type safety, ensuring that the correct types of data are used.",
        ],
        image: "/images/cheatsheetImages/task11/6.webp",
      },
    ],
  },
  {
    taskId: "Form-Validation-Component",
    content: [
      {
        title: "",
        subtitle: "Setting Up the State",
        details: [
          "Each 'useState' call creates a state variable and a function to update that state.",
          "Use 'useState' to manage and track form data.",
          "Separate state for error messages and styles helps keep the form dynamic.",
        ],
        image: "/images/cheatsheetImages/task12/1.webp",
      },
      {
        title: "",
        subtitle: "Validation Logic",
        details: [
          "e.preventDefault() prevents the default form submission behavior.",
          "Username must be longer than 8 characters.",
          "Email must include @gmail.",
          "Password must be at least 8 characters long.",
          "Password and confirm password must match.",
          "Use conditionals to check input validity.",
          "Dynamically update error messages and border colors for a better user experience.",
        ],
        image: "/images/cheatsheetImages/task12/2.webp",
      },
      {
        title: "",
        subtitle: "Form JSX with Dynamic Styling",
        details: [
          "Each input's border color is dynamically set based on validation (userColor, emailColor, etc.).",
          "Error messages are displayed below each input using conditional rendering (errorUserName, errorEmail, etc.).",
          "The onChange event handler updates the respective state variable whenever the input value changes.",
          "Use inline styles to change the border color dynamically for better visual feedback.",
          "Render error messages conditionally to guide the user if inputs are invalid.",
        ],
      },
      {
        title: "",
        subtitle: "Button Event Handler",
        details: [
          "Always use e.preventDefault() to stop the default form behavior if you are handling form submission via JavaScript.",
        ],
        image: "/images/cheatsheetImages/task12/3.webp",
      },
    ],
  },
  {
    taskId: "Interactive-Power-BI-Bar-Chart",
    content: [
      {
        title: "",
        subtitle: "Imports and Setup",
        details: [
          "'useState' from React is used to manage state in functional components.",
          "The 'Bar' component from 'react-chartjs-2' is used to create a bar chart.",
          "The necessary components from Chart.js are imported and registered to ensure the chart functions correctly.",
          "Always register Chart.js components like 'CategoryScale', 'LinearScale', 'BarElement', 'Tooltip', and 'Legend' before using them in a React component.",
        ],
        image: "/images/cheatsheetImages/taskLive2/1.webp",
      },
      {
        title: "",
        subtitle: "State and Initial Data",
        details: [
          "'ageGroups': An array of age group labels for the x-axis of the bar chart.",
          "'userData': An array representing the number of users for each age group.",
          "'useState(null)': 'filteredData' is used to manage the filtered version of the user data. Initially, it's set to 'null' because no filtering is applied when the page first loads.",
        ],
        image: "/images/cheatsheetImages/taskLive2/2.webp",
      },
      {
        title: "",
        subtitle: "Chart Data Setup",
        details: [
          "'labels': The x-axis labels representing the age groups.",
          "'datasets': This array defines the dataset for the chart. The 'data' property represents the number of users for each age group.",
          "'backgroundColor' and 'borderColor': Define the color scheme for the bars.",
          "'borderWidth': Defines the thickness of the bar borders.",
          "The 'data' object is the main configuration for Chart.js, allowing you to customize labels, datasets, and styles.",
        ],
        image: "/images/cheatsheetImages/taskLive2/3.webp",
      },
      {
        title: "",
        subtitle: "Chart Options with Event Handling",
        details: [
          "'responsive': Ensures the chart adapts to the size of its container.",
          "'plugins.legend.display': Controls whether the legend (key) is displayed.",
          "'onClick': Event handler that captures clicks on a specific bar. It retrieves the index of the clicked bar and uses that to determine which age group was selected.",
          "'handleBarClick': Called when a bar is clicked to apply filtering.",
        ],
        image: "/images/cheatsheetImages/taskLive2/4.webp",
      },
      {
        title: "",
        subtitle: "Handle Bar Click for Filtering",
        details: [
          "'handleBarClick': This function filters the user data based on the selected age group. It loops through the original 'userData' and sets all values to 0 except for the selected age group.",
          "'setFilteredData': Updates the chart with the filtered data, changing the dataset to show only the selected age group in a different color.",
          "You can use 'map' to transform arrays and conditionally filter data. This is useful when dynamically updating charts or UI elements based on user input.",
        ],
        image: "/images/cheatsheetImages/taskLive2/5.webp",
      },
      {
        title: "",
        subtitle: "Return JSX with Chart",
        details: [
          "The 'Bar' component from 'react-chartjs-2' is rendered here, displaying the bar chart. If 'filteredData' is available, the chart will show the filtered dataset; otherwise, it defaults to the original dataset (data).",
          "The chart also uses the 'options' for customization and event handling.",
          "Use conditional rendering (filteredData || data) to ensure your chart displays the correct data, whether it's the original or filtered version.",
        ],
        image: "/images/cheatsheetImages/taskLive2/6.webp",
      },
    ],
  },
  {
    taskId: "Markdown-Preview-App",
    content: [
      {
        title: "",
        subtitle: "State Management with useState",
        details: [
          "'useState': This is a React hook used to manage state in functional components.",
          "'markdown': This is the state variable that holds the current value of the markdown text.",
          "'setMarkdown': This is the function used to update the value of 'markdown'.",
          "'# Markdown Preview:': The initial value for the 'markdown' state.",
          "The state helps React components to keep track of data between renders. When 'setMarkdown' is called, it updates the markd'own value and re-renders the component.",
        ],
        image: "/images/cheatsheetImages/task13/1.webp",
      },
      {
        title: "",
        subtitle: "Handling User Input (textarea)",
        details: [
          "<textarea>: HTML element where the user can type their markdown text.",
          "value={markdown}: Links the value of the textarea to the 'markdown' state, so the content of the textarea reflects the current state.",
          "onChange={(e) => setMarkdown(e.target.value)}: This is an event handler that listens for user input. It triggers when the user types, capturing the new value with 'e.target.value' and updating the 'markdown' state.",
          "Event Handling in React involves passing a function to handle the user interaction (e.g., typing, clicking). The 'onChange' event is used to handle form inputs like 'textarea'.",
        ],
        image: "/images/cheatsheetImages/task13/2.webp",
      },
    ],
  },
  {
    taskId: "Menu-App",
    content: [
      {
        title: "App Component",
        subtitle: "State",
        details: [
          "'menuItems': Holds the list of items to be displayed.",
          "'categories': Stores all the unique categories from 'items' plus 'all'.",
          "The useState hook is used to manage the state of the menuItems and categories.",
        ],
        image: "/images/cheatsheetImages/task14/1.webp",
      },
      {
        title: "Event Handling",
        subtitle: "",
        details: [
          " The filterItems function filters the items based on the selected category.",
          "When the category is 'all', it resets menuItems to the entire items array. Otherwise, it filters items by category.",
        ],
        image: "/images/cheatsheetImages/task14/2.webp",
      },
      {
        title: "Categories Component",
        subtitle: "Props",
        details: [
          "The Categories component accepts categories (array of categories) and filterItems (function to filter menu items) as props.",
        ],
        image: "/images/cheatsheetImages/task14/5.webp",
      },
      {
        title: "",
        subtitle: "State",
        details: ["Manages the currently active category using useState."],
        image: "/images/cheatsheetImages/task14/3.webp",
      },
      {
        title: "",
        subtitle: "Event Handling",
        details: [
          "The handleCategoryClick function is called when a category button is clicked.",
          "This function triggers the 'filterItems' function from 'App' to update the list of menu items and sets the clicked category as the active one.",
        ],
        image: "/images/cheatsheetImages/task14/4.webp",
      },

      {
        title: "",
        subtitle: "Conditional Styling",
        details: [
          "Adds the 'active' class to the button of the currently selected category.",
        ],
        image: "/images/cheatsheetImages/task14/6.webp",
      },
      {
        title: "Menu Component",
        subtitle: "Props",
        details: [
          "The Menu component receives items as props, which is an array of menu items.",
        ],
        image: "/images/cheatsheetImages/task14/7.webp",
      },
      {
        title: "",
        subtitle: "Rendering Items",
        details: [
          " It uses the .map() method to loop through the 'items' array and display each menu item in a structured format.",
          "Menu item properties like 'id', 'title', 'img', 'desc', and 'price' are destructured for easier use within the JSX.",
        ],
        image: "/images/cheatsheetImages/task14/8.webp",
      },
    ],
  },
  {
    taskId: "Responsive-Navbar",
    content: [
      {
        title: "",
        subtitle: "State Management: useState Hook",
        details: [
          "Initializes a state variable 'showLinks' to control whether the navigation links are displayed or not.",
          "'useState' is used to declare a piece of state in functional components.",
          "'showLinks' holds the current state (either 'true' or 'false').",
          "'setShowLinks' is the function used to update the state.",
          "'useState(false)' means the links will initially be hidden.",
        ],
        image: "/images/cheatsheetImages/task15/1.webp",
      },
      {
        title: "",
        subtitle: "References: useRef Hook",
        details: [
          "'linksContainerRef' and 'linksRef' are references to DOM elements (<div> and <ul> respectively).",
          "'useRef' allows you to directly interact with DOM elements without re-rendering the component.",
          "'useRef' is commonly used when you need direct access to a DOM element.",
          "It's helpful for manipulating element styles or sizes.",
          "The 'useRef' doesn't cause re-renders when its value is changed.",
        ],
        image: "/images/cheatsheetImages/task15/2.webp",
      },
      {
        title: "",
        subtitle: "Event Handling: toggleLinks Function",
        details: [
          "Toggles the state of 'showLinks' between 'true' and 'false' when the menu icon is clicked.",
          "Event handling in React involves creating functions like 'toggleLinks' and assigning them to event listeners (e.g., 'onClick').",
          "Inverting the state with '!showLinks' ensures that every click changes the visibility of the links.",
        ],
        image: "/images/cheatsheetImages/task15/3.webp",
      },
      {
        title: "",
        subtitle: "Side Effects: useEffect Hook",
        details: [
          "Calculates the height of the 'linksRef' (<ul> element) and dynamically sets the height of the 'linksContainerRef' (<div> element) based on whether the links are visible or not.",
          "'useEffect' is used to perform side effects like DOM manipulation after the component has rendered.",
          "The 'useEffect' runs every time 'showLinks' changes, adjusting the height of the container.",
          "Without this, the height transition for the links container would be static or fixed.",
        ],
        image: "/images/cheatsheetImages/task15/4.webp",
      },
      {
        title: "",
        subtitle: "Rendering the JSX Elements",
        details: [
          "Renders a button with an event handler attached to toggle the links when clicked. The 'FaBars' component represents the menu icon.",
          "React components like 'FaBars' from 'react-icons' are reusable pieces of UI, in this case, an icon.",
          "The 'onClick' event triggers the 'toggleLinks' function to show or hide the navigation links.",
          "Use components from libraries like 'react-icons' for scalable and lightweight icons.",
        ],
        image: "/images/cheatsheetImages/task15/5.webp",
      },
      {
        title: "",
        subtitle: "Rendering the Links",
        details: [
          "Renders a list of navigation links dynamically by mapping over the 'links' array.",
          "In React, you can render lists using 'map()' to dynamically create elements based on data.",
          "Each child in a list must have a unique 'key' prop ('id' in this case) to help React optimize rendering.",
          "Use the 'map()' function to easily render multiple elements from an array.",
        ],
        image: "/images/cheatsheetImages/task15/6.webp",
      },
    ],
  },
  {
    taskId: "Pagination-Page",
    content: [
      {
        title: "App.js",
        subtitle: "State Management",
        details: [
          "Manage the app's current state, including the current page and followers.",
          "'useState': Initializes state variables 'page' (current page number) and 'followers' (list of followers to display on the current page).",
        ],
        image: "/images/cheatsheetImages/task16/1.webp",
      },
      {
        title: "",
        subtitle: "useEffect Hook",
        details: [
          "Trigger side effects (e.g., updating followers) when data or page changes.",
          "'useEffect': Runs when 'data', 'loading', or 'page' changes, updating the list of followers for the current page.",
          "",
        ],
        image: "/images/cheatsheetImages/task16/2.webp",
      },
      {
        title: "",
        subtitle: "Event Handling",
        details: [
          "Navigate between pages and jump to a specific page.",
          "'nextPage': Advances to the next page, looping back to the first if at the end.",
          "'prevPage': Goes to the previous page, looping to the last if at the first.",
          "'handlePage': Jumps to a specific page number.",
        ],
        image: "/images/cheatsheetImages/task16/3.webp",
      },
      {
        title: "",
        subtitle: "Rendering Followers",
        details: [
          "Display followers for the current page.",
          "Renders a list of followers by passing follower data to the Follower component for display.",
        ],
        image: "/images/cheatsheetImages/task16/4.webp",
      },
      {
        title: "Follower.js",
        subtitle: "Follower Component",
        details: [
          "Display a single follower's information.",
          "Receives props (avatar_url, login, html_url) and displays the follower's avatar, username, and profile link.",
          "",
        ],
        image: "/images/cheatsheetImages/task16/5.webp",
      },
      {
        title: "useFetch.js",
        subtitle: "Custom Hook",
        details: [
          "Fetch follower data from the GitHub API and paginate it.",
          "'loading': Tracks whether data is still being fetched.",
          "'data': Stores paginated follower data.",
          "'getProducts': Fetches the follower data and applies pagination.",
          "'useEffect': Ensures getProducts runs when the component mounts.",
        ],
        image: "/images/cheatsheetImages/task16/6.webp",
      },
      {
        title: "utils.js",
        subtitle: "Pagination Logic",
        details: [
          "Paginate the followers into smaller chunks for each page.",
          "Breaks the follower data into smaller arrays, each representing a page with 10 followers.",
          "",
        ],
        image: "/images/cheatsheetImages/task16/7.webp",
      },
    ],
  },

  {
    taskId: "Search-Bar",
    content: [
      {
        title: "App Component",
        subtitle: "Initializing State",
        details: [
          "Initializes an empty array 'results' to store search results.",
          "State is used to store and manage dynamic data (the search results in this case).",
          "Think of 'useState' as a way to make the UI react to changes in data. When 'results' change, the UI will update accordingly.",
        ],
        image: "/images/cheatsheetImages/task17/1.webp",
      },
      {
        title: "",
        subtitle: "Conditional Rendering of Results",
        details: [
          "This checks if there are any results before rendering the 'SearchResultsList' component.",
          "Prevents rendering the list if no search results exist.",
          "Conditional rendering is useful when you only want to display certain parts of the UI based on specific conditions (e.g., results existing or not).",
        ],
        image: "/images/cheatsheetImages/task17/2.webp",
      },
      {
        title: "",
        subtitle: "Passing Props to Child Components",
        details: [
          "Passes the setResults function as a prop to the SearchBar component.",
          "This allows the SearchBar component to update the search results in the parent component (App).",
          "Props allow parent-child communication in React. You can pass functions or data from a parent to a child to handle logic outside the child component.",
        ],
        image: "/images/cheatsheetImages/task17/3.webp",
      },
      {
        title: "SearchBar Component",
        subtitle: "Handling Input State",
        details: [
          "input stores the value typed by the user into the search bar.",
          "setInput updates the input value when the user types.",
          "State management is essential for handling user input dynamically.",
        ],
        image: "/images/cheatsheetImages/task17/4.webp",
      },
      {
        title: "",
        subtitle: "Fetching Data Based on User Input",
        details: [
          "Fetches user data from an API and filters it based on the 'value' typed by the user.",
          "Retrieves user data that matches the search query.",
          "This is a basic example of fetching data asynchronously and updating the UI based on user input.",
        ],
        image: "/images/cheatsheetImages/task17/5.webp",
      },
      {
        title: "",
        subtitle: "Handling User Typing (Input Change)",
        details: [
          "Updates the input value and triggers the 'fetchData' function to search for matching users.",
          "To fetch data dynamically as the user types.",
          "'handleChange' is an event handler that allows dynamic interaction with the search input.",
        ],
        image: "/images/cheatsheetImages/task17/6.webp",
      },
      {
        title: "",
        subtitle: "Handling Selection",
        details: [
          "Sets the selected result, hides the search results, and clears the search input.",
          "To update the state when a user selects a result and reset the search bar.",
          "This function manages the flow after the user has clicked on a search result, updating the app state and UI accordingly.",
        ],
        image: "/images/cheatsheetImages/task17/8.webp",
      },
      {
        title: "",
        subtitle: "Conditionally Displaying Selected Result",
        details: [
          "Conditionally renders the selected result's details only if a result has been selected.",
          "To display the selected user's information dynamically.",
          "This is another example of conditional rendering, which is essential when handling dynamic data.",
        ],
        image: "/images/cheatsheetImages/task17/9.webp",
      },

      {
        title: "SearchResult Component",
        subtitle: "Displaying Each User Result",
        details: [
          "Displays the name of a user and calls the 'onSelect' function when clicked.",
          "To display a search result and allow the user to select it.",
          "Using the 'onClick' event, we can detect when a user selects a search result and handle the selection.",
        ],
        image: "/images/cheatsheetImages/task17/7.webp",
      },
      {
        title: "SearchResultsList Component",
        subtitle: "Rendering the List of Results",
        details: [
          "Iterates over the 'results' array and renders a 'SearchResult' component for each user.",
          "To render each user result as an individual clickable item.",
          "Always use 'key' props when rendering lists in React to ensure each item is uniquely identified by React for performance optimization.",
        ],
        image: "/images/cheatsheetImages/task17/10.webp",
      },
    ],
  },
  {
    taskId: "Dropdown",
    content: [
      {
        title: "",
        subtitle: "Setting Up State with useState",
        details: [
          "'isOpenSelect' is a state variable that tracks whether the dropdown options are visible.",
          "'setIsOpenSelect' is the function used to update 'isOpenSelect'.",
          "'useState(false)' initializes the dropdown as closed (false).",
          "You can toggle the dropdown's visibility by updating the state ('true' for open, 'false' for closed).",
        ],
        image: "/images/cheatsheetImages/task18/1.webp",
      },
      {
        title: "",
        subtitle: "Using useRef for Uncontrolled Input",
        details: [
          "'languageInput' is a ref that directly references the DOM element (<input>).",
          "Refs are useful when you want to access or manipulate DOM elements without triggering a re-render.",
          "'useRef' is ideal for situations like focusing an input field, or in this case, updating the input value without using state.",
        ],
        image: "/images/cheatsheetImages/task18/2.webp",
      },
      {
        title: "",
        subtitle: "Handling Input Click Event to Toggle Dropdown",
        details: [
          "The input field is used to display the selected language.",
          "'onClick={toggleOptions}' triggers the dropdown to open or close when clicked.",
          "'onBlur={() => setIsOpenSelect(false)}' closes the dropdown when the input loses focus.",
          "'ref={languageInput}' associates the 'input' with the 'useRef' created earlier.",
          "The 'readOnly' attribute prevents the user from typing directly into the input field.",
          "Use 'onBlur' to close the dropdown when clicking outside of the input field.",
        ],
        image: "/images/cheatsheetImages/task18/3.webp",
      },
      {
        title: "",
        subtitle: "Toggling Dropdown Options Visibility",
        details: [
          "This function toggles the state between 'true' and 'false', effectively opening or closing the dropdown.",
          "Use '!isOpenSelect' to switch between the current state ('true' or 'false').",
        ],
        image: "/images/cheatsheetImages/task18/4.webp",
      },
      {
        title: "",
        subtitle: "Rendering the Dropdown Options Conditionally",
        details: [
          "The dropdown list is shown conditionally based on the value of 'isOpenSelect'. If 'true', the 'options' class receives the 'active' class, making the options visible.",
          "'optionsArray' is an array of languages that is mapped to a list of <li> elements.",
          "Each list item triggers the 'selectLanguage' function when clicked, updating the input value.",
          "Use '.map()' to dynamically render a list of items. Attach event listeners (onClick) to handle user interactions.",
        ],
        image: "/images/cheatsheetImages/task18/5.webp",
      },
      {
        title: "",
        subtitle: "Selecting a Language and Updating the Input",
        details: [
          "When an option is clicked, 'e.target.textContent' retrieves the text of the clicked <li> element (the language name).",
          "'languageInput.current.value' sets the input field's value to the selected language.",
          "'setIsOpenSelect(false)' closes the dropdown after selection.",
          "Use 'e.target.textContent' to capture the text of the clicked option.",
        ],
        image: "/images/cheatsheetImages/task18/6.webp",
      },
      {
        title: "",
        subtitle: "Icon Toggling with Conditional Class",
        details: [
          "The <span> contains an icon (faCaretDown) from FontAwesome, which serves as a visual cue for the dropdown.",
          "The 'className' changes based on the 'isOpenSelect' state. When 'active', the icon rotates or changes styling to indicate the dropdown is open.",
          "Use conditional classes to style elements based on component state (e.g., for rotating the caret icon).",
        ],
        image: "/images/cheatsheetImages/task18/7.webp",
      },
    ],
  },
  {
    taskId: "Sidebar",
    content: [
      {
        title: "",
        subtitle: "Props - Passing Data to SidebarItem",
        details: [
          "Key Prop: 'key={index}' helps React optimize rendering by uniquely identifying each list item.",
          "Props: 'item' is passed as a prop to 'SidebarItem', allowing the child component to receive and use data from its parent.",
        ],
        image: "/images/cheatsheetImages/task19/1.webp",
      },
      {
        title: "",
        subtitle: "useState Hook - Managing State in SidebarItem",
        details: [
          "State Management: 'open' holds the state (whether the sidebar item is expanded). 'setOpen' is used to update the state.",
          "useState Hook: Initializes the state to 'false', meaning the sidebar item is collapsed by default.",
        ],
        image: "/images/cheatsheetImages/task19/2.webp",
      },
      {
        title: "",
        subtitle: "Conditional Rendering - Toggling Sidebar Items",
        details: [
          "Conditional Rendering: Uses a ternary operator to apply different classes based on the 'open' state. When 'open' is 'true', the 'open' class is applied.",
          "Dynamic Classes: The class name changes depending on the component state, affecting the UI styling (e.g., expanding or collapsing the sidebar item).",
        ],
        image: "/images/cheatsheetImages/task19/3.webp",
      },
      {
        title: "",
        subtitle: "Event Handling - Toggling State",
        details: [
          "Event Handling: The 'onClick' event triggers a function to update the 'open' state. When clicked, 'setOpen' toggles the state between 'true' and 'false'.",
          "State Toggle: '!open' inverts the current state (expands or collapses the menu).",
        ],
        image: "/images/cheatsheetImages/task19/4.webp",
      },
      {
        title: "",
        subtitle: "Recursive Rendering - Nested Sidebar Items",
        details: [
          "Recursive Components: If an item has children (item.childrens), it recursively renders 'SidebarItem' for each child, enabling nested submenus.",
          "Mapping: Loops over the children array and renders each child as a 'SidebarItem'.",
        ],
        image: "/images/cheatsheetImages/task19/5.webp",
      },
      {
        title: "",
        subtitle: "Conditional Rendering - Render Link if No Children",
        details: [
          "No Children: If the item does not have children (!item.childrens), it renders as a clickable link (<a>).",
          "Optional Props: 'item.path' defines the link's URL; 'item.icon' displays an icon if provided.",
        ],
        image: "/images/cheatsheetImages/task19/6.webp",
      },
      {
        title: "",
        subtitle: "Sidebar Component - Mapping Items",
        details: [
          "Mapping: Iterates over the 'items' array from the 'sidebar.json' file and renders a 'SidebarItem' for each element.",
          "Key Prop: Provides a unique 'key' to help React track each item during re-renders.",
        ],
        image: "/images/cheatsheetImages/task19/7.webp",
      },
    ],
  },
  {
    taskId: "Dynamic-Table-with-Sorting-and-Copy-Functionality",
    content: [
      {
        title: "",
        subtitle: "useTable Hook - Creating a Table",
        details: [
          "useTable Hook: 'useTable' is a hook provided by 'react-table' for managing table functionalities like rows and columns.",
          "Destructuring: Functions like 'getTableProps', 'headerGroups', and 'rows' are extracted to manage table rendering, sorting, and row preparation.",
          "useSortBy: Hook used to add sorting capabilities to the table.",
        ],
        image: "/images/cheatsheetImages/task20/1.webp",
      },
      {
        title: "",
        subtitle: "Defining Columns for the Table",
        details: [
          "Columns Definition: Each object inside the array defines a column, with 'Header' being the column name and 'accessor' being the key from the data used to display the corresponding value.",
          "Memoization: 'React.useMemo' is used to optimize performance by memoizing the column structure.",
        ],
        image: "/images/cheatsheetImages/task20/2.webp",
      },
      {
        title: "",
        subtitle: "Memoizing Data",
        details: [
          "Memoizing Data: The useMemo hook is used to avoid recalculating the data on every render.",
          "fakeData: This variable holds mock data (from MOCK_DATA.json), which is then passed into the table as the data source.",
        ],
        image: "/images/cheatsheetImages/task20/3.webp",
      },
      {
        title: "",
        subtitle: "Rendering Table Headers",
        details: [
          "Dynamic Rendering: Uses 'headerGroups.map()' to dynamically render the table headers.",
          "Sorting Indicators: Shows sorting indicators (🔽 for descending and 🔼 for ascending) based on the sorting state.",
          "Sorting Props: 'getSortByToggleProps' adds sorting capabilities to the column headers.",
        ],
        image: "/images/cheatsheetImages/task20/4.webp",
      },
      {
        title: "",
        subtitle: "Rendering Table Body",
        details: [
          "Row Mapping: Loops through 'rows' and calls 'prepareRow(row)' to prepare each row for rendering.",
          "Cell Rendering: For each row, the cells are rendered dynamically with 'row.cells.map(cell => ... )'.",
        ],
        image: "/images/cheatsheetImages/task20/5.webp",
      },
      {
        title: "",
        subtitle: "Cell with Actions - Custom Button",
        details: [
          "Custom Cell Render: The 'Cell' property allows you to define custom content for a cell. In this case, a 'CopyButton' component is rendered, passing the full name as the text.",
          "Accessing Row Data: 'row.original' gives you access to the row's original data (in this case, first_name and last_name).",
        ],
        image: "/images/cheatsheetImages/task20/6.webp",
      },
      {
        title: "",
        subtitle: "CopyButton Component - Copying Text to Clipboard",
        details: [
          "Clipboard API: 'navigator.clipboard.writeText()' copies the provided text to the user's clipboard.",
          "Button Component: This reusable 'CopyButton' displays a button and triggers the 'copyToClipboard' function on click.",
        ],
        image: "/images/cheatsheetImages/task20/7.webp",
      },
      {
        title: "",
        subtitle: "React-Table Sorting Example",
        details: [
          "Sorting Indicator: Shows an arrow indicating whether the column is sorted in ascending or descending order based on the 'isSorted' and 'isSortedDesc' properties.",
        ],
        image: "/images/cheatsheetImages/task20/8.webp",
      },
    ],
  },
  {
    taskId: "Activity-Management-Dashboard",
    content: [
      {
        title: "",
        subtitle: "Display Activity Name",
        details: [
          "Use Material-UI's Typography component for consistent text styling and sizing throughout the application.",
        ],
        image: "/images/cheatsheetImages/taskLive3/1.webp",
      },
      {
        title: "",
        subtitle: "Display List of Records",
        details: [
          "The 'map()' function is essential for rendering lists in React. It ensures that each record is uniquely identified with a 'key' prop, which helps React optimize rendering performance.",
        ],
        image: "/images/cheatsheetImages/taskLive3/2.webp",
      },
      {
        title: "",
        subtitle: "Display User Claims",
        details: [
          "Combining user names with dates in the displayed text provides a clear context for the claims, improving user understanding.",
        ],
        image: "/images/cheatsheetImages/taskLive3/3.webp",
      },
      {
        title: "",
        subtitle: "Display Rules List",
        details: [
          "Using a simple list format for rules enhances readability and helps participants easily find important information.",
        ],
        image: "/images/cheatsheetImages/taskLive3/4.webp",
      },
    ],
  },
  {
    taskId: "To-Do-App",
    content: [
      {
        title: "",
        subtitle: "Managing State with useState",
        details: [
          "'useState' is a React Hook used to manage state in functional components.",
          "Here, 'todos' holds the list of todo items, and 'setTodos' is the function to update this state.",
          "To initialize state, pass the default value as an argument to 'useState'.",
        ],
        image: "/images/cheatsheetImages/task21/1.webp",
      },
      {
        title: "",
        subtitle: "Adding Todos",
        details: [
          "This function adds a new todo item to the 'todos' state.",
          "It creates a new todo object with a unique ID (using uuidv4), a task description, and initial values for 'completed' and 'isEditing'.",
          "Always spread the existing state when updating it to maintain its previous values.",
        ],
        image: "/images/cheatsheetImages/task21/2.webp",
      },
      {
        title: "",
        subtitle: "Deleting Todos",
        details: [
          "This function removes a todo from the list by filtering out the todo with the given ID.",
          "'filter' creates a new array excluding the todo that matches the ID.",
          "Use 'filter' for immutability; it returns a new array instead of modifying the existing state.",
        ],
        image: "/images/cheatsheetImages/task21/3.webp",
      },
      {
        title: "",
        subtitle: "Toggling Completion Status",
        details: [
          "This function toggles the 'completed' status of a todo item by updating the relevant todo's state.",
          "It uses 'map' to create a new array with the updated todo.",
          "Always return a new object when updating state to ensure React can detect changes.",
        ],
        image: "/images/cheatsheetImages/task21/4.webp",
      },
      {
        title: "",
        subtitle: "Editing Todos",
        details: [
          "This function updates the task of a todo and toggles its editing state.",
          "Similar to toggling completion, it uses 'map' to create a new array with the updated todo.",
          "When modifying objects in state, always use spread syntax to preserve immutability.",
        ],
        image: "/images/cheatsheetImages/task21/5.webp",
      },
      {
        title: "",
        subtitle: "Handling Form Submission",
        details: [
          "This function handles the form submission for adding a new todo.",
          "'e.preventDefault()' prevents the default form submission behavior, allowing for custom handling.",
          "It checks if the input value is not empty before calling 'addTodo'.",
        ],
        image: "/images/cheatsheetImages/task21/6.webp",
      },
      {
        title: "",
        subtitle: "Using Props",
        details: [
          "Props allow data to be passed from parent components to child components.",
          "Here, 'editTodo' and 'task' are received as props, enabling the 'EditTodoForm' to access the necessary functionality and data.",
          "Use destructuring to easily access props in functional components.",
        ],
        image: "/images/cheatsheetImages/task21/7.webp",
      },
      {
        title: "",
        subtitle: "Displaying Toast Notifications",
        details: [
          "The application uses 'react-toastify' to display notifications when todos are marked as completed or incomplete.",
          "'toast.success()' creates a success notification.",
          "Notifications improve user experience by providing feedback on actions taken.",
        ],
        image: "/images/cheatsheetImages/task21/8.webp",
      },
    ],
  },
  {
    taskId: "Testimonials",
    content: [
      {
        title: "",
        subtitle: "State Initialization",
        details: [
          "'currentIndex': Tracks which testimonial is currently displayed.",
          "'setCurrentIndex': Updates the value of currentIndex.",
          "'useState(0)': Initializes the state variable 'currentIndex' to 0, meaning the first testimonial will be displayed initially.",
        ],
        image: "/images/cheatsheetImages/task22/4.webp",
      },
      {
        title: "",
        subtitle: "GSAP Animation using useEffect",
        details: [
          "'useEffect': This Hook runs after the component renders. The empty [] ensures it runs only once (on mount).",
          "'gsap.to': Animates the '.testimonials' class by changing the background color over 4 seconds, repeating infinitely (repeat: -1) with a 'yoyo' effect (reverses the animation).",
          "GSAP is highly customizable for adding animations and transitions.",
        ],
        image: "/images/cheatsheetImages/task22/1.webp",
      },
      {
        title: "",
        subtitle: "Navigation Functions",
        details: [
          "'handlePrevClick': Decreases the current index to show the previous testimonial. Uses modulus (%) to loop back to the last item when reaching the beginning.",
          "'handleNextClick': Increases the current index to show the next testimonial. Loops back to the first item when reaching the end.",
          "Using 'modulus' (%) is a common trick for creating circular navigation.",
        ],
        image: "/images/cheatsheetImages/task22/2.webp",
      },
      {
        title: "",
        subtitle: "Rendering the Testimonials",
        details: [
          "'testimonials[currentIndex]': Displays the current testimonial's quote and author based on the 'currentIndex' state.",
        ],
        image: "/images/cheatsheetImages/task22/3.webp",
      },
    ],
  },
  {
    taskId: "Voice-to-Text",
    content: [
      {
        title: "",
        subtitle: "Component Setup",
        details: [
          "'regenerator-runtime': Required for async functions (like 'SpeechRecognition') in environments that don't support them natively.",
          "'useSpeechRecognition': A hook from 'react-speech-recognition' that converts speech to text.",
          "'useClipboard': A hook from 'react-use-clipboard' that handles copying text to the clipboard.",
        ],
        image: "/images/cheatsheetImages/task23/1.webp"
      },
         {
        title: "",
        subtitle: "State Initialization",
        details: [
          "'textToCopy': Stores the text to be copied to the clipboard.",
          "'setTextToCopy': Updates the 'textToCopy' value.",
          "'useClipboard': Takes two parameters: the text to copy (textToCopy) and an optional settings object (successDuration: 1000), which defines how long the 'Copied!' message will be displayed.",
          "'isCopied': A boolean value that becomes 'true' when the text is successfully copied.",
        ],
        image: "/images/cheatsheetImages/task23/2.webp"
      },
         {
        title: "",
        subtitle: "Speech Recognition Hook",
        details: [
          "'startListening': A function that starts listening for speech input using the 'SpeechRecognition.startListening' method.",
          "'continuous: true': Ensures the speech input continues until manually stopped.",
          "'language: 'en-IN'': Specifies the input language (English - India in this case).",
          "'transcript': Holds the text converted from speech.",
          "'browserSupportsSpeechRecognition': Boolean that indicates if the user's browser supports speech recognition.",
        ],
        image: "/images/cheatsheetImages/task23/3.webp"
      },
         {
        title: "",
        subtitle: "Rendering the App UI",
        details: [
          "Start Listening Button: Starts speech recognition using 'startListening()'.",
          "Stop Listening Button: Stops speech recognition with 'SpeechRecognition.stopListening()'.",
        ],
        image: "/images/cheatsheetImages/task23/4.webp"
      },
       
    ],
  },
  {
    taskId: "Text-to-Voice",
    content: [
      {
        title: "Event Handlers",
        subtitle: "Handling Input Changes",
        details: [
          "Updates 'inputText' when the user types in the textarea.",
          "'event' Object: Contains information about the event, such as the current value of the input.",
          "Event Handling allows your application to respond to user interactions.",
          "Always extract necessary data from the event object to update the state accordingly.",
        ],
        image: "/images/cheatsheetImages/task24/1.webp"
      },
         {
        title: "",
        subtitle: "Handling the Speak Action",
        details: [
          "Checks if 'inputText' is not empty or just whitespace.",
          "'SpeechSynthesisUtterance': Creates a speech request with the provided text.",
          "'speechSynthesis.speak': Initiates the speech.",
          "Conditional Logic ensures that actions are performed only when appropriate.",
          "Always validate user input before performing actions to enhance user experience and prevent errors."
        ],
        image: "/images/cheatsheetImages/task24/2.webp"
      },
         {
        title: "",
        subtitle: "Speech Synthesis API",
        details: [
          "'SpeechSynthesisUtterance': An interface representing a speech request.",
          "'speechSynthesis': The controller interface for the Speech Synthesis API.",
          "Web APIs can be integrated with React to add advanced functionalities.",
          "Familiarize yourself with browser APIs to extend the capabilities of your React applications."
        ],
        image: "/images/cheatsheetImages/task24/3.webp"
      },
        
    ],
  },
  {
    taskId: "Course-Finder",
    content: [
      {
        title: "",
        subtitle: "State Management with Hooks",
        details: [
          "State management is crucial for managing and tracking dynamic data in a React application. It allows components to respond to user input and render updated information.",
          "React provides the 'useState' hook to manage state in functional components. When you call 'useState', it returns a pair: the current state value and a function that lets you update it. Every time the state updates, React re-renders the component with the new state.",
          "Use meaningful names for state variables to reflect their purpose.",
          "Avoid mutating state directly; always use the setter function provided by 'useState'."
        ],
        image: "/images/cheatsheetImages/task25/1.webp"
      },
         {
        title: "",
        subtitle: "Component Composition",
        details: [
          "Component composition allows you to build complex UIs from simple components. This approach promotes code reusability and maintainability.",
          "In React, components can be nested within one another, allowing you to break down the UI into smaller, manageable pieces. This separation of concerns makes it easier to manage the application.",
          "Keep components small and focused on a single responsibility.",
          "Use props to pass data and functions down to child components."
        ],
        image: "/images/cheatsheetImages/task25/2.webp"
      },
         {
        title: "",
        subtitle: "Handling Events",
        details: [
          "Handling events is essential for creating interactive user interfaces. React provides a way to handle events in a declarative manner.",
          "You can add event listeners to JSX elements directly, similar to how you would in regular HTML. React normalizes events so that they behave consistently across different browsers.",
          "Use arrow functions or bind methods to avoid losing context for this.",
          "Consider using useCallback for optimizing performance with event handlers.",
        ],
        image: "/images/cheatsheetImages/task25/3.webp"
      },
         {
        title: "",
        subtitle: "Conditional Rendering",
        details: [
          "Conditional rendering allows you to render different UI elements based on the state of your application, enhancing user experience.",
          "You can use JavaScript expressions to conditionally render elements in React. This can be done using logical operators or ternary expressions.",
          "Keep conditional logic simple; consider creating separate components for complex conditions.",
          "Use short-circuit evaluation for cleaner code.",
        ],
        image: "/images/cheatsheetImages/task25/4.webp"
      },
         {
        title: "",
        subtitle: "Lifting State Up",
        details: [
          "Lifting state up helps manage shared state between components, ensuring data consistency across the application.",
          "When multiple components need to share state, you can lift the state up to their closest common ancestor. This ancestor component will then pass the state down as props.",
          "Only lift state up when necessary to avoid prop drilling.",
          "Use context or state management libraries for deep component trees.",
        ],
        image: "/images/cheatsheetImages/task25/5.webp"
      },
         {
        title: "",
        subtitle: "Using Effect Hook",
        details: [
          "The useEffect hook manages side effects in functional components, such as data fetching, subscriptions, or manually changing the DOM.",
          "The useEffect hook runs after the render, allowing you to perform operations that might not be directly related to rendering. You can also clean up effects when components unmount.",
          "Always clean up effects that subscribe to external data sources to prevent memory leaks.",
          "Use dependencies in the effect array wisely to control when the effect runs.",
        ],
        image: "/images/cheatsheetImages/task25/6.webp"
      },
      {
        title: "",
        subtitle: "Managing Styles",
        details: [
          "Styling components properly enhances user experience and improves the visual appeal of your application.",
          "React supports various ways to style components, including CSS stylesheets, inline styles, CSS modules, and styled-components. Choose a method that suits your project's needs.",
          "Use BEM (Block Element Modifier) methodology for class naming to maintain clarity.",
          "Consider using a CSS-in-JS library for dynamic styling based on props."
        ],
        image: "/images/cheatsheetImages/task25/7.webp"
      },
      {
        title: "",
        subtitle: "PropTypes for Type Checking",
        details: [
          "PropTypes help ensure that components receive the correct data types, making your code more robust and easier to debug.",
          "React's prop-types library allows you to define the expected data types for props. This feature helps catch bugs by warning you when the wrong data types are passed.",
          "Use default props for optional props.",
          "Consider TypeScript for more advanced type checking in larger applications."
        ],
        image: "/images/cheatsheetImages/task25/8.webp"
      },
    ],
  },
  {
    taskId: "React-Firebase-Application-with-ML-and-Payments",
    content: [
      {
        title: "Project Setup",
        subtitle: "Creating a React App",
        details: [
          "Create a new React app using 'npx create-react-app'.",
          "This command sets up a new React application with a default configuration, including a basic file structure, development server, and build tools.",
          "npx runs the package directly without installing it globally. This command creates a directory named your-app-name and initializes a new React app inside it.",
          "Choose a meaningful app name to reflect the functionality or purpose of the app."
        ],
      },
         {
        title: "Firebase Configuration",
        subtitle: "",
        details: [
          "This code initializes Firebase in your application, allowing access to Firebase services.",
          "You import Firebase and specific services (Auth, Firestore, Storage). The firebaseConfig object contains your project-specific configuration values obtained from the Firebase Console. Calling firebase.initializeApp(firebaseConfig) connects your app to Firebase.",
          "Ensure you include all necessary Firebase services according to your application needs.",
        ],
        image: "/images/cheatsheetImages/taskLive5/1.webp"
      },
         {
        title: "Authentication with Firebase",
        subtitle: "User Registration",
        details: [
          "This function handles user registration via Firebase Authentication.",
          "The createUserWithEmailAndPassword method creates a new user account with the provided email and password. If successful, a success message is logged; if an error occurs, it is caught and logged.",
          "Implement input validation for email and password to enhance security and user experience.",
        ],
        image: "/images/cheatsheetImages/taskLive5/2.webp"
      },
         {
        title: "",
        subtitle: "Event Handling",
        details: [
          "This function handles the form submission event.",
          "'e.preventDefault()' prevents the default form submission behavior, allowing you to handle the registration logic programmatically. It calls the registerUser function with the email and password values.",
          "Bind event handlers to component state to dynamically update the UI based on user input.",
        ],
        image: "/images/cheatsheetImages/taskLive5/3.webp"
      },
         {
        title: "Firestore Database Operations",
        subtitle: "Adding Data",
        details: [
          "This function adds a new document to a Firestore collection.",
          "It uses the Firestore instance to access a specified collection and adds a new document with the data passed as an argument. If successful, a success message is logged; otherwise, it catches and logs any errors.",
          "Structure your data logically to optimize query performance and ensure ease of use in the application.",
        ],
        image: "/images/cheatsheetImages/taskLive5/4.webp"
      },
         {
        title: "",
        subtitle: "Fetching Data",
        details: [
          "This function retrieves all documents from a Firestore collection.",
          "The 'get()' method retrieves the documents, and snapshot.docs.map() transforms the documents into a more usable format by extracting the data and adding the document ID. This array is then logged to the console.",
          "Handle loading states to improve user experience while data is being fetched; consider using local state to manage this.",
        ],
        image: "/images/cheatsheetImages/taskLive5/5.webp"
      },
      {
        title: "Handling File Uploads with Firebase Storage",
        subtitle: "",
        details: [
          "This function uploads files to Firebase Storage.",
          "The put() method uploads the specified file to the storage reference created with storageRef.child(file.name). If successful, a success message is logged; if an error occurs, it is caught and logged.",
          "Implement file type and size validation before uploads to ensure compatibility and provide user feedback for better interaction.",
        ],
        image: "/images/cheatsheetImages/taskLive5/6.webp"
      },
      {
        title: "Using State and Props",
        subtitle: "State Management",
        details: [
          "The useState hook allows functional components to manage local state.",
          "useState(0) initializes the count state variable to 0. The incrementCount function updates the state when called, triggering a re-render with the new count.",
          "Keep state updates functional to avoid stale state issues, especially in asynchronous operations.",
        ],
        image: "/images/cheatsheetImages/taskLive5/7.webp"
      },
      {
        title: "",
        subtitle: "Using Props",
        details: [
          "Props are used to pass data and event handlers from parent to child components.",
          "The Greeting component receives a name prop and renders a greeting message. This allows for dynamic content based on the passed prop.",
          "Validate props using PropTypes to catch issues early in development and ensure the component receives the expected data type.",
        ],
        image: "/images/cheatsheetImages/taskLive5/8.webp"
      },
      {
        title: "Event Handling",
        subtitle: "",
        details: [
          "Controlled components keep form inputs in sync with component state.",
          "The onChange event handler updates the email state with the current input value, ensuring the input reflects the state.",
          "Consider using useEffect to perform side effects based on state changes, such as validation or API calls.",
        ],
        image: "/images/cheatsheetImages/taskLive5/9.webp"
      },
      {
        title: "Testing and Deployment",
        subtitle: "Testing Functionality",
        details: [
          "Ensure each feature works as intended before going live to provide a smooth user experience.",
          "Use browser developer tools to monitor console logs, network requests, and state changes during development.",
        ],
      },
      {
        title: "",
        subtitle: "Deployment",
        details: [
          "Firebase Hosting provides a simple way to deploy web apps globally.",
          "Running the firebase deploy command uploads your app files to Firebase and makes them accessible via a hosting URL.",
          "Set up environment variables for sensitive information (like API keys) during deployment to enhance security.",
        ],
      },
    ],
  },
  {
    taskId: "Course-Shop",
    content: [
      {
        title: "",
        subtitle: "Props",
        details: [
          "Props (short for properties) allow components to receive data from parent components, enabling dynamic and reusable UI elements.",
          "Props are immutable, meaning that a component cannot change its own props. This ensures that data flows in one direction, making the app easier to debug and reason about.",
        ],
        image: "/images/cheatsheetImages/task26/1.webp"
      },
         {
        title: "",
        subtitle: "State",
        details: [
          "State is used to manage dynamic data within a component, allowing components to react and re-render when data changes.",
          "State is mutable and is typically managed using the useState hook in functional components. Changing the state triggers a re-render of the component.",
        ],
        image: "/images/cheatsheetImages/task26/2.webp"
      },
         {
        title: "",
        subtitle: "Context API",
        details: [
          "The Context API allows sharing state across the component tree without having to pass props down manually at every level (prop drilling).",
          "Using the Context API helps manage global state, such as user authentication or themes, making the code cleaner and easier to maintain.",
        ],
        image: "/images/cheatsheetImages/task26/3.webp"
      },
         {
        title: "",
        subtitle: "React Router",
        details: [
          "React Router enables navigation between different components based on the URL, allowing for a multi-page experience in single-page applications (SPAs).",
          "By defining routes, you can control what components render based on the browser's address, enhancing user experience through client-side routing.",
        ],
        image: "/images/cheatsheetImages/task26/4.webp"
      },
         {
        title: "",
        subtitle: "Hooks",
        details: [
          "Hooks allow functional components to manage state and side effects, providing the ability to use state and lifecycle features without class components.",
          "The useEffect hook is particularly powerful for handling side effects like data fetching or subscriptions, running the provided effect after the component renders.",
        ],
        image: "/images/cheatsheetImages/task26/5.webp"
      },
         {
        title: "",
        subtitle: "Managing Forms",
        details: [
          "Managing forms is crucial for handling user inputs, validations, and submitting data to APIs or other services.",
          "React makes it easy to create controlled components where form data is handled by state, ensuring that the displayed data is always in sync with the state.",
        ],
        image: "/images/cheatsheetImages/task26/7.webp"
      },
      {
        title: "",
        subtitle: "Error Boundaries",
        details: [
          "Error boundaries catch JavaScript errors in child components and provide a fallback UI, improving user experience by preventing the entire app from crashing.",
          "Error boundaries are implemented using lifecycle methods in class components. They can handle errors gracefully and log errors for further investigation.",
        ],
        image: "/images/cheatsheetImages/task26/8.webp"
      },
    ],
  },
  {
    taskId: "MATERIALIZECSS-Portfolio",
    content: [
      {
        title: "",
        subtitle: "Importing Dependencies",
        details: [
          "import React from 'react';: This imports the React library, which is essential for creating React components and managing the component lifecycle.",
          "import 'materialize-css/dist/css/materialize.min.css': This imports the CSS file for Materialize, a modern responsive front-end framework. It provides predefined styles and components for building user interfaces.",
          "import 'material-icons/iconfont/material-icons.css': This imports Material Icons, which allows you to use a variety of icons in your app.",
          "import M from 'materialize-css': This imports the JavaScript functionalities of Materialize CSS, enabling you to use its JavaScript components, such as parallax and sidenav.",
        ],
        image: "/images/cheatsheetImages/task27/0.webp"
      },
      {
        title: "",
        subtitle: "useEffect for Initializing Materialize CSS",
        details: [
          "'useEffect' is used to initialize Materialize CSS components (Parallax and Sidenav) after the component is rendered. The empty array [] ensures this only happens once, mimicking componentDidMount in class components.",
          "'useEffect' is great for initializing third-party libraries or performing side effects when the component first mounts.",
        ],
        image: "/images/cheatsheetImages/task27/1.webp"
      },
         {
        title: "",
        subtitle: "Event Handling for Smooth Scrolling",
        details: [
          "This function prevents the default behavior of links and uses 'window.scrollTo' to smoothly scroll to the targeted section of the page. The 'section' parameter determines which part of the page to scroll to.",
          "Use event handling with 'window.scrollTo' for smooth navigation between sections. event.preventDefault() prevents reloading the page when a button is clicked.",
        ],
        image: "/images/cheatsheetImages/task27/2.webp"
      },
       
    ],
  },
  {
    taskId: "MaterializeCSS-Web-Toolkit",
    content: [
      {
        title: "",
        subtitle: "Setup and Initialization",
        details: [
          "The 'M' object is imported from the Materialize CSS library, giving access to its JavaScript components (such as collapsibles).",
          "The CSS file is imported to style the collapsible elements and other Materialize components.",
          "Ensure 'materialize-css' is installed via 'npm' or 'yarn' for the imports to work.",
        ],
        image: "/images/cheatsheetImages/task28/1.webp"
      },
         {
        title: "",
        subtitle: "Collapsible Initialization with useEffect",
        details: [
          "'useEffect' is a React hook that runs after the component mounts. Here, it's used to ensure that the collapsibles are initialized after the DOM elements are rendered.",
          "document.querySelectorAll(.collapsible): Selects all elements with the class '.collapsible' to apply Materialize's collapsible functionality.",
          "M.Collapsible.init: Initializes the collapsible components, applying the following options: 'accordion: false', 'inDuration', 'outDuration.'",
        ],
        image: "/images/cheatsheetImages/task28/2.webp"
      },
        
    ],
  },


  {
    taskId: "Cube-Image-Gallery",
    content: [
      {
        title: "",
        subtitle: "State Management",
        details: [
          "This state will control which cube face image is currently visible.",
         
        ],
        image: "/images/cheatsheetImages/task30/1.webp"
      },
         {
        title: "",
        subtitle: "Event Handler Function",
        details: [
          "This function handles image click events.",
          "It checks if the clicked image class (targetClass) is different from the current cubeImageClass.",
          "If they are different, it logs the image number and updates the state to show the selected image.",
        ],
        image: "/images/cheatsheetImages/task30/2.webp"
      },
         {
        title: "",
        subtitle: "Cube Image Display",
        details: [
          "A 'div' with a dynamic class name that includes both a static class (initial-position) and a state-driven class (cubeImageClass).",
          "The 'img' tags represent each face of the cube. The images will rotate based on the current class set in the state.",
        ],
        image: "/images/cheatsheetImages/task30/3.webp"
      },
         {
        title: "",
        subtitle: "Image Thumbnails",
        details: [
          "Renders a series of image inputs as buttons. Each button: Is of type image, displaying a thumbnail of the cube faces.",
          "Has an onClick event that triggers the handleImageClick function with the respective class name.",
          "This allows users to click thumbnails to change the visible cube face.",
        ],
        image: "/images/cheatsheetImages/task30/4.webp"
      },
        
    ],
  },
  {
    taskId: "Analog-Clock",
    content: [
      {
        title: "",
        subtitle: "useEffect Hook",
        details: [
          "Sets up the clock to update every second.",
          "'getCurrentTime' Function: Retrieves the current time from the system and converts it into degrees for the clock hands.",
          "seconds * 6: Converts seconds to degrees (360 degrees / 60 seconds = 6 degrees per second).",
          "(minutes + seconds / 60) * 6: Converts minutes to degrees.",
          "(hours + (minutes + seconds / 60) / 60) * 30: Converts hours to degrees (360 degrees / 12 hours = 30 degrees per hour).",
        ],
        image: "/images/cheatsheetImages/task31/1.webp"
      },
         {
        title: "",
        subtitle: "Interval Setup",
        details: [
          "'setInterval': Calls a function every 1000 milliseconds (1 second).",
          "Uses the previous state (prevTime) to increment the angles for each hand",
          "prevTime.seconds + 6: Updates seconds.",
          "prevTime.minutes + 6 / 60: Updates minutes.",
          "prevTime.hours + 30 / 3600: Updates hours.",
        ],
        image: "/images/cheatsheetImages/task31/2.webp"
      },
         {
        title: "",
        subtitle: "Cleanup Function",
        details: [
          "Clears the interval when the component unmounts to prevent memory leaks.",
        ],
        image: "/images/cheatsheetImages/task31/3.webp"
      },
    ],
  },
  {
    taskId: "Dynamic-Menu-Highlighter",
    content: [
      {
        title: "",
        subtitle: "Refs for DOM Manipulation",
        details: [
          "'menuRef': A reference to the menu DOM element for direct manipulation.",
          "'borderRef': A reference for the menu border to animate its position.",
        ],
        image: "/images/cheatsheetImages/task32/1.webp"
      },
         {
        title: "",
        subtitle: "Click Event Handler",
        details: [
          "'clickItem': A function that handles the click events on menu items.",
          "'setActiveIndex': Updates the state to the clicked index.",
          "Dynamically changes the body background color based on the selected menu item.",
          "'offsetMenuBorder': Adjusts the position of the menu border according to the active item.",
        ],
        image: "/images/cheatsheetImages/task32/2.webp"
      },
         {
        title: "",
        subtitle: "Positioning the Menu Border",
        details: [
          "'getBoundingClientRect': Provides the size of an element and its position relative to the viewport.",
          "Calculating Left Position: Computes the left offset for the menu border so that it is centered under the active menu item.",
          "Uses CSS transform to animate the border position.",
        ],
        image: "/images/cheatsheetImages/task32/3.webp"
      },
         {
        title: "",
        subtitle: "Effect Hook for Resize Handling",
        details: [
          "'useEffect': Runs the 'offsetMenuBorder' function when the component mounts and whenever the 'activeIndex' changes.",
          "'handleResize': Updates the border position on window resize events.",
          "Returns a function to remove the event listener when the component unmounts to prevent memory leaks.",
        ],
        image: "/images/cheatsheetImages/task32/4.webp"
      },
        
    ],
  },
  {
    taskId: "Order-Tracker-Mobile-App",
    content: [
      {
        title: "",
        subtitle: "Using useRef for DOM Manipulation",
        details: [
          "useRef Hook: This hook creates mutable objects that persist for the full lifetime of the component. 'ctaRef' and 'drawerRef' are references to specific DOM elements.",
          "Useful for directly manipulating DOM elements without causing re-renders.",
        ],
        image: "/images/cheatsheetImages/task33/1.webp"
      },
         {
        title: "",
        subtitle: "Side Effects with useEffect",
        details: [
          "useEffect Hook: Used to perform side effects in function components, such as setting initial styles with GSAP.",
          "The empty array [] ensures this effect runs only once when the component mounts.",
        ],
        image: "/images/cheatsheetImages/task33/2.webp"
      },
         {
        title: "",
        subtitle: "Animating Elements with GSAP",
        details: [
          "This function animates the CTA button and text, changing their position and opacity.",
          "y: Vertical position.",
          "opacity: Visibility.",
          "duration: Duration of the animation.",
          "ease: Easing function for smoothness.",
          "stagger: Creates a delay between animations of multiple elements.",
          "onComplete: Callback function triggered after the animation completes.",
        
        ],
        image: "/images/cheatsheetImages/task33/3.webp"
      },
         {
        title: "",
        subtitle: "Event Handling",
        details: [
          " The 'onClick' prop is used to specify a function (hideCTA) to run when the button is clicked. Event handlers are crucial for user interactions.",
         
        ],
        image: "/images/cheatsheetImages/task33/4.webp"
      },
         {
        title: "",
        subtitle: "Conditional Rendering and Logic",
        details: [
          "This logic checks if the 'drawerOpen' state is true and executes the 'slideDown' function if so. This pattern can help manage what to render based on component state.",
          
        ],
        image: "/images/cheatsheetImages/task33/5.webp"
      },
         {
        title: "",
        subtitle: "Dynamic Classes and Styles",
        details: [
          "Using classList.add() to change the classes of a DOM element dynamically based on interactions or state. This allows for more complex styles and behaviors.",
          
        ],
        image: "/images/cheatsheetImages/task33/6.webp"
      },
    ],
  },
  {
    taskId: "Timer-App",
    content: [
      {
        title: "",
        subtitle: "Initializing State",
        details: [
          "This block initializes the state for the timer (time) and the timer's active status (isActive). The timer is set to 600 seconds (10 minutes).",
          "'setTime' and 'setIsActive' are functions that update the respective state variables.",
          "Adjust the initial value of 'time' as needed for different countdown durations.",
        ],
        image: "/images/cheatsheetImages/task34/1.webp"
      },
         {
        title: "",
        subtitle: "Setting Up the Effect Hook",
        details: [
          "This block manages the timer's countdown logic. It sets up an interval that decrements the timer every second when active. It also cleans up the interval to avoid memory leaks when the component unmounts or when the timer is paused.",
          "The dependency array ([isActive, time]) ensures that the effect runs only when these values change.",
          "Be careful to clear the interval to prevent multiple intervals from running simultaneously.",
        ],
        image: "/images/cheatsheetImages/task34/2.webp"
      },
         {
        title: "Timer Control Functions",
        subtitle: "Start Timer",
        details: [
          "Activates the timer, allowing it to start counting down.",
          "This function can be linked to a button click to enable user control over the timer.",
        ],
        image: "/images/cheatsheetImages/task34/3.webp"
      },
         {
        title: "",
        subtitle: "Pause Timer",
        details: [
          "Pauses the timer, stopping the countdown without resetting the time.",
          "This function helps users take breaks without losing their progress.",
        ],
        image: "/images/cheatsheetImages/task34/4.webp"
      },
         {
        title: "",
        subtitle: "Reset Timer",
        details: [
          "Stops the timer and resets the countdown back to the initial state (10 minutes).",
          "Reset functionality is essential for user experience, allowing users to restart the timer easily",
        ],
        image: "/images/cheatsheetImages/task34/5.webp"
      },
         {
        title: "",
        subtitle: "",
        details: [
          "Converts the time in seconds to a string formatted as 'MM' for easier readability.",
          "'padStart(2, '0')' ensures that single-digit minutes or seconds are displayed with a leading zero.",
          "This formatting enhances user experience by providing a familiar time display.",
        ],
        image: "/images/cheatsheetImages/task34/6.webp"
      },
    ],
  },
  {
    taskId: "Portfolio-Website",
    content: [
      {
        title: "Header.js Component",
        subtitle: "Defining the Functional Component and State",
        details: [
          "To define the Header component and initialize the state variable isNavOpen to manage the navigation menu's open or closed status.",
          "Use descriptive state names to enhance code readability.",
          "Initialize state with a relevant default value.",
        ],
        image: "/images/cheatsheetImages/task35/1.webp"
      },
         {
        title: "",
        subtitle: "Toggle Navigation Function",
        details: [
          "To define a function that toggles the value of isNavOpen, allowing the navigation menu to open or close.",
          "Keep functions concise to improve maintainability.",
          "Consider using 'useCallback' for optimization in larger components.",
        ],
        image: "/images/cheatsheetImages/task35/2.webp"
      },
         {
        title: "",
        subtitle: "Structuring the Header Layout",
        details: [
          "To create the main structure of the header, including the logo and a conditional class based on the navigation state.",
          "Use semantic elements like <header> for better accessibility.",
          "Utilize conditional classes to manage styles dynamically.",
        ],
        image: "/images/cheatsheetImages/task35/3.webp"
      },
         {
        title: "Portfolio.js Component",
        subtitle: "Defining the Functional Component and State",
        details: [
          "To define the Portfolio component and initialize the state variable currentFilter to track the active filter.",
          "Use descriptive state names to clarify their purpose in the component.",
          "Initialize state with a relevant default value.",
        ],
        image: "/images/cheatsheetImages/task35/4.webp"
      },
         {
        title: "",
        subtitle: "Filter Click Handler",
        details: [
          "To define a function that updates the 'currentFilter' state based on user interaction.",
          "Keep the handler function concise and focused on a single task.",
          "Use arrow functions for cleaner syntax.",
        ],
        image: "/images/cheatsheetImages/task35/5.webp"
      },
         {
        title: "",
        subtitle: "Filter Button Group",
        details: [
          "To create a group of buttons for filtering portfolio items by category, highlighting the active filter.",
          "Use conditional class names for dynamic styling based on the state.",
          "Ensure button elements are keyboard accessible for improved usability.",
        ],
        image: "/images/cheatsheetImages/task35/6.webp"
      },
    ],
  },
  {
    taskId: "Materialize-Portfolio",
    content: [
      {
        title: "",
        subtitle: "Setting Up Materialize CSS",
        details: [
          "The Materialize CSS library is imported for easy access to pre-designed components and JavaScript functionality, helping us style the app without extensive custom CSS.",
         
        ],
        image: "/images/cheatsheetImages/task37/1.webp"
      },
         {
        title: "",
        subtitle: "Initializing Materialize",
        details: [
          "'useEffect' is a React hook that runs code after the component renders. Here, it initializes Materialize components when the app loads using 'M.AutoInit()'.",
          "This auto-initializes any Materialize elements used in the app, saving manual initialization of individual components.",
        ],
        image: "/images/cheatsheetImages/task37/2.webp"
      },
         {
        title: "",
        subtitle: "Handling Button Clicks and Smooth Scrolling",
        details: [
          "'handleDirectionsClick' is a function triggered when the button is clicked. It uses 'scrollIntoView' to smoothly scroll to the element with the id='googleMapIframe'.",
          "Use scrollIntoView to focus on specific sections. Great for user experience in single-page apps.",
        ],
        image: "/images/cheatsheetImages/task37/3.webp"
      },
       
    ],
  },

  {
    taskId: "File-Uploader",
    content: [
      {
        title: "",
        subtitle: "Setting Up Component State",
        details: [
          "Initializes a state variable filesData as an empty array.",
          "Stores metadata for each uploaded file, like its name and URL, which are needed to display the file information and enable download.",
          "You can update this array to add or remove files as they're selected or deleted.",
        ],
        image: "/images/cheatsheetImages/task38/1.webp"
      },
         {
        title: "",
        subtitle: "Creating a Reference for the File Input",
        details: [
          "Creates a 'ref' for the file input element.",
          "Allows control over the <input> element without using the DOM directly.",
          "Using refs can be helpful when you need direct access to an element (e.g., to trigger a click programmatically).",
        ],
        image: "/images/cheatsheetImages/task38/2.webp"
      },
         {
        title: "",
        subtitle: "File Upload Button Handler",
        details: [
          "This function triggers a click on the file input.",
          "Opens the file dialog without the need for a visible input field.",
          "This approach improves the user interface by using a custom button instead of a standard file input.",
        ],
        image: "/images/cheatsheetImages/task38/3.webp"
      },
         {
        title: "",
        subtitle: "Handling File Selection and Updating State",
        details: [
          "This function handles file selection by:",
          "Reading selected files from the input.",
          "Mapping each file to an object with 'name' and a 'url' for preview/download.",
          "Updating 'filesData' state with the new files, preserving any previously selected files.",
          "URL.createObjectURL(file): Generates a temporary URL for the file, allowing it to be displayed or downloaded.",
          "...prevFilesData: Ensures previous files are not overwritten by new selections.",
          
        ],
        image: "/images/cheatsheetImages/task38/4.webp"
      },
         {
        title: "",
        subtitle: "Deleting a File",
        details: [
          "Removes a file from 'filesData' by filtering it out based on its index.",
          "Updating the state this way ensures React re-renders the component without the deleted file.",
        ],
        image: "/images/cheatsheetImages/task38/5.webp"
      },
         {
        title: "",
        subtitle: "Rendering the Component UI",
        details: [
          "The <input type='file' multiple> allows multiple files to be selected.",
          "'onChange' triggers 'handleFileChange', updating 'filesData' whenever files are selected.",
          "Set multiple to allow multiple file selection at once.",
        ],
        image: "/images/cheatsheetImages/task38/6.webp"
      },
      {
        title: "",
        subtitle: "Displaying Download Links and Delete Button",
        details: [
          "Download Link: Uses the 'file.url' and 'file.name' for each file to create a download link.",
          "Delete Button: Triggers handleFileDelete, removing the file from filesData.",
          "Each file requires a unique key (index is used here) to help React efficiently re-render only the updated items.",
        ],
        image: "/images/cheatsheetImages/task38/7.webp"
      },
    ],
  },
  {
    taskId: "Cake-Website",
    content: [
      {
        title: "",
        subtitle: "State Management",
        details: [
          "The 'useState' hook initializes 'count' with a default value of 0. The 'count' variable holds the current state, and 'setCount' is the function to update this state.",
          "The 'increment' function calls 'setCount' to update the 'count' state by 1",
          "State is used to store and manage dynamic data in functional components, which re-renders the component when updated.",
        ],
        image: "/images/cheatsheetImages/task39/1.webp"
      },
         {
        title: "",
        subtitle: "Props (Passing Data)",
        details: [
          "Props allow you to pass data from one component to another. Here, 'name' is passed as a prop to the Greeting component.",
          "Props are read-only and cannot be modified by the receiving component. They are commonly used to customize components by passing in values from parent components.",
        ],
        image: "/images/cheatsheetImages/task39/2.webp"
      },
         {
        title: "",
        subtitle: "Event Handling",
        details: [
          "The onClick attribute is used to handle button clicks. 'handleClick' is the event handler function, which displays an alert when the button is clicked.",
          "React uses camelCase syntax for event handlers (e.g., onClick, onChange). Events in React are wrapped in a synthetic event system to provide consistent behavior across browsers.",
        ],
        image: "/images/cheatsheetImages/task39/3.webp"
      },
         {
        title: "",
        subtitle: "Conditional Rendering",
        details: [
          "This example uses the ternary operator to render different messages based on the 'isLoggedIn' prop.",
          "You can conditionally render components or content using JavaScript expressions, like ternary operators or && for short-circuit evaluation.",
        ],
        image: "/images/cheatsheetImages/task39/4.webp"
      },
         {
        title: "",
        subtitle: "Lists and Keys",
        details: [
          "Keys are a special prop required when rendering lists to help React identify each element uniquely. Here, 'item.id' is used as the 'key' prop.",
          "Always provide unique keys for list items to avoid performance issues or unexpected behavior. Keys should be stable and unique across each render.",
        ],
        image: "/images/cheatsheetImages/task39/5.webp"
      },
         {
        title: "",
        subtitle: "Form Handling",
        details: [
          "The form input is controlled by React state. 'name' represents the current input value, which updates as the user types.",
          "The 'onChange' event updates the input field's state, and the 'onSubmit' event prevents default form submission behavior, triggering an alert instead.",
          "Controlled inputs maintain their state in React, which gives you more control over form behavior and validation.",
        ],
        image: "/images/cheatsheetImages/task39/6.webp"
      },
      {
        title: "",
        subtitle: "Component Lifecycle (Using useEffect)",
        details: [
          "'useEffect' is a hook that runs side effects in functional components. Here, it's used to increment the 'seconds' state every second.",
          "The 'clearInterval' function prevents memory leaks by stopping the interval when the component unmounts.",
          "Use 'useEffect' with an empty dependency array to run the effect once on mount. Add dependencies to re-run the effect when specific values change.",
        ],
        image: "/images/cheatsheetImages/task39/7.webp"
      },
         {
        title: "",
        subtitle: "Default Props",
        details: [
          "Setting default values for props ensures a fallback when no value is passed. Here, 'name' defaults to 'Guest' if not provided.",
          "Use default props to prevent errors or unexpected behavior when props are missing.",
        ],
        image: "/images/cheatsheetImages/task39/8.webp"
      },
    ],
  },
  {
    taskId: "Interactive-3D-Card-Viewer",
    content: [
      {
        title: "",
        subtitle: "Event Handlers for Mouse and Touch Movements",
        details: [
          "Handles both mouse and touch events to apply 3D rotation on the .card element based on user input coordinates. e.pageX, e.pageY, e.touches[0].pageX, and e.touches[0].pageY retrieve cursor/finger positions for dynamic styling. useEffect is helpful here to manage DOM manipulation in functional components.",
          
        ],
        image: "/images/cheatsheetImages/task40/1.webp"
      },
         {
        title: "",
        subtitle: "Adding and Removing Event Listeners",
        details: [
          "Adds mousemove and touchmove listeners on component mount to allow interaction with the card element. The return cleanup function removes these listeners on unmount, preventing potential memory leaks. When working with useEffect, cleanup is essential to avoid side effects when the component lifecycle ends.",
          
        ],
        image: "/images/cheatsheetImages/task40/2.webp"
      },
         
    ],
  },
  {
    taskId: "Dev-Portfolio",
    content: [
      {
        title: "",
        subtitle: "React Router Basics",
        details: [
          "'Router' provides routing capabilities, allowing navigation between pages. 'Route' defines the path and the component to render for each route.",
          "Use 'Routes' to wrap all 'Route' components for React Router v6. Paths like /about, /portfolio, and /contact correspond to the URL paths that render specific components.",
        ],
        image: "/images/cheatsheetImages/task41/1.webp"
      },
         {
        title: "",
        subtitle: "Components Structure and Layout",
        details: [
          "This structure allows navigation through Navbar and Routes. Components like Home, About, etc., are loaded based on the path.",
          "Placing Navbar and Footer outside Routes keeps them static across all pages, while content changes dynamically within Routes.",
        ],
        image: "/images/cheatsheetImages/task41/2.webp"
      },
         {
        title: "",
        subtitle: "State and Event Handling",
        details: [
          "'useState' initializes 'formData' as an object with fields 'name', 'email', and 'message'. 'setFormData' updates this data.",
          "'useState' to store and manage form data. When the form changes, 'setFormData' is called with the updated field values.",
        ],
        image: "/images/cheatsheetImages/task41/3.webp"
      },
         {
        title: "",
        subtitle: "Form Submission",
        details: [
          "'handleSubmit' prevents the page from refreshing and logs the form data when the form is submitted.",
          "'e.preventDefault()' is crucial to avoid page reloads. It keeps the user on the same page and allows data handling within React.",
        ],
        image: "/images/cheatsheetImages/task41/4.webp"
      },
         {
        title: "",
        subtitle: "Conditional Rendering",
        details: [
          "If 'filter' is set to 'all', it displays all projects; otherwise, it filters based on category.",
          "Use JavaScript array methods like 'filter' to control which data appears based on user actions.",
        ],
        image: "/images/cheatsheetImages/task41/5.webp"
      },
         {
        title: "",
        subtitle: "Event Listeners and Scroll Handling",
        details: [
          "'isScrolled' is updated based on the window's scroll position. If 'scrolled' past 50px, 'isScrolled' becomes 'true', triggering the 'scrolled' CSS class.",
          "Use 'useEffect' with event listeners for dynamic behaviors like updating 'isScrolled' based on scroll.",
        ],
        image: "/images/cheatsheetImages/task41/6.webp"
      },
      {
        title: "",
        subtitle: "Passing Props",
        details: [
          "'skills' is an array that holds skill names. Using 'map', each 'skill' is rendered in an <li>.",
          "Use 'key' props for lists to help React track elements. Avoid using array indices as keys for large or dynamic lists.",
        ],
        image: "/images/cheatsheetImages/task41/7.webp"
      },
         {
        title: "",
        subtitle: "",
        details: [
          "<Link> is used for in-app navigation without reloading the page, unlike <a>, which triggers a full reload.",
          "Use <Link> with 'to' attributes to define the path. It's essential for a smooth single-page app (SPA) experience.",
        ],
        image: "/images/cheatsheetImages/task41/8.webp"
      },
    ],
  },
  {
    taskId: "Fruit-Carousel",
    content: [
      {
        title: "",
        subtitle: "Updating the Visible Items",
        details: [
          "This function calculates the previous, current, and next items to display based on the activeIndex.",
          "(index - 1 + items.length) % items.length: This ensures the carousel wraps around when reaching the beginning of the array.",
          "setVisibleItems([...]): Updates the visibleItems state with the three items to display.",
          "'useCallback': Memoizes the 'updateVisibleItems' function, ensuring that it doesn't get recreated on every render, improving performance.",
        ],
        image: "/images/cheatsheetImages/task42/1.webp"
      },
         {
        title: "",
        subtitle: "Setting up useEffect for Initial and Re-render Updates",
        details: [
          "'useEffect': Runs the updateVisibleItems function whenever activeIndex changes, ensuring that the visible items are updated.",
          " It depends on activeIndex and updateVisibleItems to avoid unnecessary re-renders.",
        ],
        image: "/images/cheatsheetImages/task42/2.webp"
      },
         {
        title: "",
        subtitle: "Next and Previous Slide Handlers",
        details: [
          "Functions to move to the next or previous carousel item.",
          "'setActiveIndex': Updates the 'activeIndex' state, cycling through items by using modulo (%).",
          "'useCallback': Ensures the slide functions are not recreated on every render, improving performance.",
        ],
        image: "/images/cheatsheetImages/task42/3.webp"
      },
         {
        title: "",
        subtitle: "Handling Mouse Wheel Scroll for Navigation",
        details: [
          "Listens for mouse wheel events to navigate through slides.",
          "event.deltaY: A positive value indicates scrolling down (next slide), while a negative value indicates scrolling up (previous slide).",
          "nextSlide() & prevSlide(): Calls the corresponding slide functions to update the carousel.",
        ],
        image: "/images/cheatsheetImages/task42/4.webp"
      },
         {
        title: "",
        subtitle: "Adding Event Listeners with useEffect",
        details: [
          "'useEffect': Sets up the event listener when the component mounts and cleans it up when the component unmounts to avoid memory leaks.",
          "addEventListener('wheel', handleScroll): Listens for the mouse wheel event on the carousel container.",
          "'removeEventListener': Ensures the event listener is removed when the component is removed from the DOM.",
        ],
        image: "/images/cheatsheetImages/task42/5.webp"
      },
         {
        title: "",
        subtitle: "",
        details: [
          "'handleItemClick': A function to update the active slide when a carousel item is clicked.",
          "'setActiveIndex(index)': Sets the clicked item as the active one by updating the 'activeIndex'.",
        ],
        image: "/images/cheatsheetImages/task42/6.webp"
      },
    ],
  },
  {
    taskId: "FitLife-Website",
    content: [
      {
        title: "",
        subtitle: "State Management with useState Hook",
        details: [
          "State is used to store and manage dynamic data that will change over time.",
          "'useState' is used to declare state variables.",
          "'formData' is the state object holding the form fields (name, email, message).",
          "'setFormData' is the function used to update formData.",
          "The 'useState' hook returns an array: the first element is the state value, and the second is the function to update that state.",
        ],
        image: "/images/cheatsheetImages/task43/1.webp"
      },
         {
        title: "",
        subtitle: "Handling Form Inputs",
        details: [
          "To update the state based on user input, you use onChange handlers that call state-updating functions.",
          "The 'handleChange' function updates the 'formData' state whenever an input changes.",
          "It uses the spread operator (...formData) to retain previous values and updates only the relevant field (name, email, or message) based on e.target.name.",
          "To manage multiple form fields with the same 'onChange' handler, use dynamic keys (e.g., e.target.name).",
        ],
        image: "/images/cheatsheetImages/task43/2.webp"
      },
         {
        title: "",
        subtitle: "Event Handling with onSubmit",
        details: [
          "Form submission can trigger events, like sending data to a server.",
          "'handleSubmit' prevents the default form submission (e.preventDefault()).",
          "After logging the form data, it triggers 'setIsSubmitted(true)', which could show a success message.",
          "Always prevent the default behavior of forms using 'e.preventDefault()' when handling form submissions in React.",
        ],
        image: "/images/cheatsheetImages/task43/3.webp"
      },
         {
        title: "",
        subtitle: "Conditional Rendering",
        details: [
          "React allows conditionally rendering parts of the UI based on state values.",
          "The 'isSubmitted' state is used to conditionally render either the success message or the form.",
          "If 'isSubmitted' is 'true', a success message is shown. Otherwise, the form is displayed.",
          "Use ternary operators for conditional rendering of components based on state.",
        ],
        image: "/images/cheatsheetImages/task43/4.webp"
      },
         {
        title: "",
        subtitle: "Props for Passing Data",
        details: [
          "Props are used to pass data from a parent component to a child component.",
          "Link is a component from 'react-scroll', and it receives props like 'to', 'smooth', and 'duration'.",
          "These props are used to scroll smoothly to a section on the page (e.g., 'contact').",
          "Props are immutable inside the child component. The parent is responsible for passing them down.",
        ],
        image: "/images/cheatsheetImages/task43/5.webp"
      },
         {
        title: "",
        subtitle: "Dynamic Class Names Based on State",
        details: [
          "You can apply different class names dynamically based on state or other conditions.",
          "The 'navbar' class is conditionally combined with the 'scrolled' class if the page has been scrolled.",
          "'isScrolled' is managed by a scroll event handler that updates state when the page is scrolled.",
          "Conditional class names help you apply styles dynamically based on component states or conditions.",
        ],
        image: "/images/cheatsheetImages/task43/6.webp"
      },
      {
        title: "",
        subtitle: "UseEffect Hook for Side Effects",
        details: [
          "The 'useEffect' hook runs side effects like fetching data or subscribing to events.",
          "The 'useEffect' hook listens for scroll events and updates 'isScrolled' when the page is scrolled past 50 pixels.",
          "The return function inside 'useEffect' cleans up the event listener when the component is unmounted.",
          "The second argument ([]) to 'useEffect' ensures the effect only runs once, similar to 'componentDidMount' in class components.",
        ],
        image: "/images/cheatsheetImages/task43/7.webp"
      },
      
    ],
  },
  {
    taskId: "FAQ-Toggle",
    content: [
      {
        title: "",
        subtitle: "State Initialization with useState",
        details: [
          "'activeIndex' is the state variable used to store the index of the currently active (expanded) FAQ item.",
          "'setActiveIndex' is the function that updates the 'activeIndex' state.",
          "'useState(null)' initializes it to 'null' (meaning no item is active initially).",
          "State is used to manage dynamic data within the component. In this case, it controls which FAQ answer is shown.",
        ],
        image: "/images/cheatsheetImages/task44/1.webp"
      },
         {
        title: "",
        subtitle: "Toggling Active FAQ",
        details: [
          "'toggleFAQ' is a function that toggles the active state when a user clicks on a FAQ question.",
          "activeIndex === index ? null : index: If the clicked FAQ is already active (i.e., its index is equal to activeIndex), the function will set it to 'null' (closing it). Otherwise, it sets activeIndex to the clicked index (opening it).",
          "Event handlers like 'toggleFAQ' allow interaction with the component. In this case, it controls which FAQ answer is visible based on user clicks.",
        ],
        image: "/images/cheatsheetImages/task44/2.webp"
      },
         {
        title: "",
        subtitle: "Mapping FAQ Data to Render List",
        details: [
          "faqData.map() iterates through the 'faqData' array and renders a list item (<li>) for each FAQ question and answer.",
          "key={index}: React requires a unique 'key' prop for each item in a list to efficiently update and render components.",
          "The 'active' class is conditionally applied to the FAQ item if its index matches 'activeIndex'.",
          "Props are used in lists like this to map over data and generate multiple elements. Here, 'faqData' is the list of FAQs being passed into the component.",
        ],
        image: "/images/cheatsheetImages/task44/3.webp"
      },
         {
        title: "",
        subtitle: " CSS Class Application Based on State",
        details: [
          "Dynamically adds the active class to the FAQ item when its index matches activeIndex. This allows for conditional styling (e.g., showing or hiding the FAQ answer) when the item is active.",
          "React allows you to dynamically set class names based on state or props. This is useful for applying styles conditionally.",
        ],
        image: "/images/cheatsheetImages/task44/4.webp"
      },
        
    ],
  },
  {
    taskId: "Pomodoro-Focus-Timer",
    content: [
      {
        title: "",
        subtitle: "Managing State with useState",
        details: [
          "'workTime': Time in minutes for the work session (default is 25 minutes).",
          "'breakTime': Time in minutes for the break session (default is 5 minutes).",
          "'seconds': Time in seconds, which is used for countdown logic (converted from 'workTime' or 'breakTime').",
          "'isActive': A boolean that tracks whether the timer is active or paused.",
          "'isRotating': A boolean to handle animation state for a rotating clock.",
          "Initializes and updates the state variables. It takes two arguments: the initial state and a function to update that state (setWorkTime, setSeconds, etc.).",
        ],
        image: "/images/cheatsheetImages/task45/1.webp"
      },
         {
        title: "",
        subtitle: "Using useEffect for Side Effects (Timer Countdown)",
        details: [
          "This effect starts a countdown when the timer is active (isActive is true). The 'setInterval' function updates the 'seconds' state every second. When the timer reaches 0, it stops and resets the state.",
          "The effect runs whenever 'isActive' or 'seconds' change, ensuring the countdown updates correctly.",
        ],
        image: "/images/cheatsheetImages/task45/2.webp"
      },
         {
        title: "",
        subtitle: "Starting a Countdown with 'startCountdown'",
        details: [
          "This function starts the countdown by setting the 'seconds' based on the given 'duration' (in minutes).",
          "It resets and triggers the rotation animation (isRotating).",
        ],
        image: "/images/cheatsheetImages/task45/3.webp"
      },
         {
        title: "",
        subtitle: "Starting the Work and Break Timers",
        details: [
          "These functions call 'startCountdown' with the respective time ('workTime' for work sessions and 'breakTime' for breaks).",
        ],
        image: "/images/cheatsheetImages/task45/4.webp"
      },
         {
        title: "",
        subtitle: "Resetting the Timer",
        details: [
          "The reset function stops the timer and resets the state to the initial values (work session time in seconds).",
        ],
        image: "/images/cheatsheetImages/task45/5.webp"
      },
         {
        title: "",
        subtitle: "Formatting the Timer Display",
        details: [
          "This helper function formats the time into a MM:SS format for displaying the countdown.",
          "'Math.floor(seconds / 60)' gives the total minutes.",
          "'seconds % 60' gives the remaining seconds.",
        ],
        image: "/images/cheatsheetImages/task45/6.webp"
      },
    ],
  },
  {
    taskId: "Profolio",
    content: [
      {
        title: "",
        subtitle: "Framer Motion",
        details: [
          "Adds a fade-in animation to the entire app on load, with opacity transitioning from 0 to 1.",
          "Use Framer Motion's motion.div for easy animations on component load.",
        ],
        image: "/images/cheatsheetImages/task46/1.webp"
      },
         {
        title: "",
        subtitle: "Component Rendering",
        details: [
          "Renders each section of the webpage by using individual components (Navbar, Header, About, etc.).",
          "Organizing each section as separate components keeps the code modular and easier to maintain.",
        ],
        image: "/images/cheatsheetImages/task46/2.webp"
      },
         {
        title: "",
        subtitle: "State Management",
        details: [
          "Uses 'useState' to control the visibility of additional text. Clicking the button toggles the 'showMore' state, causing conditional rendering of extra content.",
          "'useState' is useful for handling dynamic UI elements based on user interaction.",
        ],
        image: "/images/cheatsheetImages/task46/3.webp"
      },
         {
        title: "",
        subtitle: "Conditional Rendering",
        details: [
          "Conditionally displays the additional text only if showMore is true.",
          "Combine state and conditional rendering to show or hide specific content based on user actions.",
        ],
        image: "/images/cheatsheetImages/task46/4.webp"
      },
         {
        title: "",
        subtitle: "Animated Button",
        details: [
          "A button that toggles between “More” and “Less” text based on 'showMore'. Adds hover and tap animations.",
          "Framer Motion provides 'whileHover' and 'whileTap' props for adding subtle animations that enhance the user experience.",
        ],
        image: "/images/cheatsheetImages/task46/5.webp"
      },
         {
        title: "",
        subtitle: "Event Handler",
        details: [
          "Handles clicking on a navbar item to set it as active, update the background color, and smoothly scroll to the target section.",
          "Use 'window.scrollTo' with an offset for smooth scrolling; 'getBoundingClientRect()' helps adjust for any fixed headers.",
        ],
        image: "/images/cheatsheetImages/task46/6.webp"
      },
      {
        title: "",
        subtitle: "Dynamic Menu Border Animation",
        details: [
          "Positions the animated border underneath the active menu item.",
          "'transform': 'translate3d' provides smooth, efficient animations.",
        ],
        image: "/images/cheatsheetImages/task46/7.webp"
      },
         {
        title: "",
        subtitle: "Rendering Menu Items with Icons",
        details: [
          "Maps over the 'icons' array to render each icon in the navbar. Each item changes color when active, using dynamic background color and an 'active' class.",
          "Use 'map' for dynamic content rendering based on an array; this pattern keeps code clean and manageable for components with repeated structures.",
        ],
        image: "/images/cheatsheetImages/task46/8.webp"
      },
    ],
  },
  {
    taskId: "Animated-Progress-Loader",
    content: [
      {
        title: "",
        subtitle: "App Setup",
        details: [
          " Sets up core dependencies. 'useState' and 'useEffect' help manage component states and lifecycle events, while 'AnimatePresence' and 'motion' enable smooth animations.",
          
        ],
        image: "/images/cheatsheetImages/task47/1.webp"
      },
         {
        title: "",
        subtitle: "State Management",
        details: [
          "'loading': Controls whether the loading screen or main content is displayed.",
          "'progress': Tracks the progress of the loading animation, updating it from 0 to 100.",
          "'useState' allows you to define and update component-specific values (e.g., 'loading' and 'progress') over time.",
        ],
        image: "/images/cheatsheetImages/task47/2.webp"
      },
         {
        title: "",
        subtitle: "Progress Update Logic with useEffect",
        details: [
          "This hook triggers the loading animation by periodically updating 'progress' until it reaches 100.",
          "'setInterval' is used to increment progress every 30 ms.",
          "'clearInterval' stops the interval once progress reaches 100, and setLoading(false) displays the main content.",
          "Dependency Array ([]): Ensures 'useEffect' only runs once when the component mounts.",
          "'useEffect' is a powerful hook for running side effects, such as setting intervals or fetching data when the component loads.",
        ],
        image: "/images/cheatsheetImages/task47/3.webp"
      },
         {
        title: "",
        subtitle: "Conditional Rendering with AnimatePresence",
        details: [
          "Uses conditional rendering to show the loader animation while 'loading' is 'true', then switches to main content.",
          "Handles animations on elements entering and leaving the DOM, adding a smooth transition when switching from the loader to content.",
          "'AnimatePresence' helps manage exit animations for conditional components, improving the visual flow.",
        ],
        image: "/images/cheatsheetImages/task47/4.webp"
      },
         {
        title: "",
        subtitle: "Main Content Display After Loading Completes",
        details: [
          "Displays main content with a fade-in and upward motion once loading is complete.",
          "'initial': Starts 20px down with opacity 0.",
          "'animate': Fades in and moves into position.",
          "'transition': Controls animation duration.",
          "'transition' allows for fine-tuning animation speed and timing, creating a smooth experience.",
        ],
        image: "/images/cheatsheetImages/task47/5.webp"
      },
        
    ],
  },
  {
    taskId: "Dynamic-Loader",
    content: [
      {
        title: "",
        subtitle: "State Management",
        details: [
          "'loading': A boolean state that toggles between loading and content display.",
          "'progress': Tracks the loading progress percentage.",
        ],
        image: "/images/cheatsheetImages/task48/1.webp"
      },
         {
        title: "",
        subtitle: "useEffect with setInterval",
        details: [
          "Automatically increases 'progress' by 1 every 30ms.",
          "Once 'progress' reaches 100, the interval stops, and 'loading' is set to fals'e.",
          "The use of 'clearInterval' within the condition prevents memory leaks by stopping the interval when no longer needed.",
        ],
        image: "/images/cheatsheetImages/task48/2.webp"
      },
        
    ],
  },
  {
    taskId: "Speech-Synth",
    content: [
      {
        title: "",
        subtitle: "Setting Up State and Managing Input Changes",
        details: [
          "'inputText': Holds the text the user types in.",
          "'loading': Controls the button state, showing when speech generation is in progress.",
          "'symbolCount': Tracks the number of characters (symbols) in 'inputText'.",
        ],
        image: "/images/cheatsheetImages/task49/1.webp"
      },
         {
        title: "",
        subtitle: "Updating Symbol Count with useEffect",
        details: [
          "'useEffect' listens for changes in 'inputText'. Whenever 'inputText' changes, it updates 'symbolCount' with the length of the text.",
          "This ensures 'symbolCount' always reflects the latest number of characters, providing real-time feedback to the user.",
        ],
        image: "/images/cheatsheetImages/task49/2.webp"
      },
         {
        title: "",
        subtitle: "",
        details: [
          "'handleInputChange' updates inputText state based on user input.",
          "By passing 'event.target.value', this function ensures 'inputText' reflects the current textarea content.",
        ],
        image: "/images/cheatsheetImages/task49/3.webp"
      },
         {
        title: "",
        subtitle: "",
        details: [
          "'handleSpeak' makes a POST request to the server with the input text to generate speech.",
          "The server response (audio file) is temporarily saved as a URL, allowing the user to download it.",
          "The 'responseType: 'blob'' specifies that we expect binary data (an audio file) in the response, suitable for downloading files.",
        ],
        image: "/images/cheatsheetImages/task49/4.webp"
      },
         {
        title: "",
        subtitle: "Express Server Setup",
        details: [
          "Sets up an Express server to handle requests. cors allows cross-origin requests from the React frontend.",
          "Define the port the server listens on (3001) to avoid conflicts with the frontend.",
        ],
        image: "/images/cheatsheetImages/task49/5.webp"
      },
         {
        title: "",
        subtitle: " Text-to-Speech Synthesis Endpoint",
        details: [
          "Receives the text input and uses 'gTTS' to convert it to speech.",
          "Saves the speech file as 'output.mp3' and sends it back to the frontend for download.",
          "The 'fs.unlink' function deletes the file after it's sent, keeping storage clean.",
        ],
        image: "/images/cheatsheetImages/task49/6.webp"
      },
    ],
  },
  {
    taskId: "Smooth-Scroll-Navigator",
    content: [
      {
        title: "",
        subtitle: "Managing State with useState",
        details: [
          "'activeIndex': Tracks the index of the currently active section. Updates when scrolling or clicking navigation buttons.",
          "'scrollY': Stores the current vertical scroll position (window.scrollY).",
          "Use 'useState' for values that dynamically change based on user actions.",
        ],
        image: "/images/cheatsheetImages/task50/1.webp"
      },
         {
        title: "",
        subtitle: "Scrolling Event Listener with useEffect",
        details: [
          "Tracks the user's scroll position to update the active section dynamically.",
          "Adds a 'scroll' event listener when the component mounts and removes it during cleanup.",
          "Updates 'scrollY' and determines 'activeIndex' based on the position of each section.",
          "Always clean up event listeners in 'useEffect' to prevent memory leaks.",

        ],
        image: "/images/cheatsheetImages/task50/2.webp"
      },
         {
        title: "",
        subtitle: "Handling Navigation Button Clicks",
        details: [
          "Sets 'activeIndex' to the clicked button's index.",
          "Smoothly scrolls to the target section using the 'react-scroll' library.",
          "The 'offset' adjusts the scroll position to account for a fixed header or margin.",
        ],
        image: "/images/cheatsheetImages/task50/3.webp"
      },
       
    ],
  },
  {
    taskId: "Tesla-Experience",
    content: [
      {
        title: "",
        subtitle: "Routing with React Router",
        details: [
          "Router: Wraps the entire app to enable routing.",
          "Routes: Defines a collection of Route components.",
          "Route: Maps a URL path to a React component.",
          "models.map: Dynamically generates routes for each model defined in models.js.",
          "Use useParams from react-router-dom to access dynamic route parameters if needed.",
        ],
        image: "/images/cheatsheetImages/task51/1.webp"
      },
         {
        title: "",
        subtitle: "Dynamic Routing with Props",
        details: [
          "Dynamically creates a route for each model.",
          "Passes the properties of each model (e.g., modelName, speed) to the Model component using the spread operator ...model.",
          "To debug, log model to check the properties being passed to Model.",
        ],
        image: "/images/cheatsheetImages/task51/2.webp"
      },
         {
        title: "",
        subtitle: "Framer Motion Animations",
        details: [
          "initial: The starting state of the animation.",
          "animate: The final state after animation.",
          "transition: Defines the duration and easing.",
          "Use Framer Motion to enhance the user experience with smooth animations.",

        ],
        image: "/images/cheatsheetImages/task51/3.webp"
      },
         {
        title: "",
        subtitle: "Home Component Animation with useAnimation",
        details: [
          "useAnimation: Hook from Framer Motion for controlling animations programmatically.",
          "useEffect: Ensures the animation starts when the component mounts.",
          "This setup is ideal for triggering animations based on user interactions or lifecycle events.",
        ],
        image: "/images/cheatsheetImages/task51/4.webp"
      },
       
    ],
  },
  {
    taskId: "Dynamic-Dashboard",
    content: [
      {
        title: "",
        subtitle: "",
        details: [
          "'useState' manages whether the sidebar menu is open (menuOpen).",
          "'toggleMenu' toggles the menu state, and 'closeMenu' ensures it closes.",
        ],
        image: "/images/cheatsheetImages/task52/1.webp"
      },
         {
        title: "",
        subtitle: "",
        details: [
          "Receives 'menuOpen' to toggle the sidebar and 'closeMenu' to handle link clicks.",
          "'motion.aside' (from framer-motion) adds smooth sidebar transitions.",
        ],
        image: "/images/cheatsheetImages/task52/2.webp"
      },
         {
        title: "",
        subtitle: "",
        details: [
          "'generateData' creates a table dynamically with 5 rows and 5 columns.",
          "Adds smooth fade-in effects using 'motion.table'.",
          "Utilizes <thead> for headers and <tbody> for data rows.",
        ],
        image: "/images/cheatsheetImages/task52/3.webp"
      },
         {
        title: "",
        subtitle: "",
        details: [
          "Uses 'useState' to track loading and fetched data.",
          "'useEffect' simulates fetching data with a 'setTimeout'.",
          "Displays a loading spinner while fetching data.",
        ],
        image: "/images/cheatsheetImages/task52/4.webp"
      },
         {
        title: "",
        subtitle: "",
        details: [
          "Holds a list of users and loading state.",
          "Maps over the users array to display each user.",
        ],
        image: "/images/cheatsheetImages/task52/5.webp"
      },
        
    ],
  },
  {
    taskId: "Quiz-Master",
    content: [
      {
        title: "",
        subtitle: " State Management with useState",
        details: [
          "State in React is used to store data that will change over time. In this quiz app, the state is used to manage the current question, score, selected option, and timing.",
          "currentQuestion: Tracks which question the user is on (starts at 0).",
          "score: Tracks the user's score (number of correct answers).",
          "selectedOption: Stores the index of the option the user selects",
          "startTime: Marks the time when a question starts",
          "questionTimes: Stores the time taken for each question and whether it was answered correctly",
          "quizFinished: Tracks whether the quiz is finished.",
        ],
        image: "/images/cheatsheetImages/task53/1.webp"
      },
         {
        title: "",
        subtitle: " Effect Hook for Timing",
        details: [
          "'useEffect' is used to update the sta'rtTime whenever the question changes and to prevent re-setting it once the quiz is finished.",
          "This 'useEffect' runs every time 'currentQuestion' changes (i.e., when a user answers a question).",
          "If the quiz is finished ('quizFinished' is 'true'), it stops updating the start time.",
        ],
        image: "/images/cheatsheetImages/task53/2.webp"
      },
         {
        title: "",
        subtitle: "Prevent Multiple Selections & Track Time",
        details: [
          "The check prevents the user from selecting more than one option or interacting with the quiz once it is finished.",
          "'timeTaken' measures how long the user took to answer the current question by calculating the difference between 'endTime' and 'startTime'.",
          "The answer is marked as correct if the selected option's index matches the correct answer.",
          "'setQuestionTimes': This stores information about the current question, how long it took to answer, and whether the answer was correct, allowing later analysis of the quiz results.",
        ],
        image: "/images/cheatsheetImages/task53/3.webp"
      },
         {
        title: "",
        subtitle: "Handle Option Selection & Transition",
        details: [
          "'setSelectedOption(index)' highlights the selected option, which helps with UI styling and feedback.",
          "After a brief delay (500ms), the quiz either moves to the next question or ends. If there are more questions, the currentQuestion state is incremented. If all questions have been answered, 'setQuizFinished(true)' ends the quiz and shows the results.",
        ],
        image: "/images/cheatsheetImages/task53/4.webp"
      },
         {
        title: "",
        subtitle: "Restarting the Quiz",
        details: [
          "The 'restartQuiz' function resets the state to allow the user to play the quiz again.",
          "All relevant states (currentQuestion, score, selectedOption, questionTimes, quizFinished) are reset to their initial values.",
        ],
        image: "/images/cheatsheetImages/task53/5.webp"
      },
       
    ],
  },
  {
    taskId: "Profile-Popup-Animation",
    content: [
      {
        title: "",
        subtitle: "Using State with useState",
        details: [
          "The 'useState' hook is used to manage the state of the popup.",
          "'showPopup' holds the current state (true/false) of the popup.",
          "'setShowPopup' is a function to update the state.",
          "Use 'useState' whenever you need to toggle or store a value that changes dynamically.",
        ],
        image: "/images/cheatsheetImages/task54/1.webp"
      },
         {
        title: "",
        subtitle: "Event Handling in React",
        details: [
          "This function toggles the 'showPopup' state between true and false.",
          "Used as the 'onClick' handler for buttons.",
          "Define event handler functions for better reusability and clarity.",
        ],
        image: "/images/cheatsheetImages/task54/2.webp"
      },
         {
        title: "",
        subtitle: "Motion Components from Framer Motion",
        details: [
          "'motion.button' is a Framer Motion component that adds animations.",
          "'whileHover' and 'whileTap' define animations for hover and click states.",
          "Use Framer Motion to add smooth animations easily.",
        ],
        image: "/images/cheatsheetImages/task54/3.webp"
      },
         {
        title: "",
        subtitle: "Conditional Rendering with AnimatePresence",
        details: [
          "'AnimatePresence' ensures animations play when elements are added or removed.",
          "The 'showPopup' condition controls the visibility of the popup.",
          "Wrap components that enter/exit with animations inside 'AnimatePresence'.",
        ],
        image: "/images/cheatsheetImages/task54/4.webp"
      },
         {
        title: "",
        subtitle: "Animating Components",
        details: [
          "'initial', 'animate', and 'exit' define animation states for entry and exit.",
          "'transition' specifies animation timing and behavior.",
          "Use 'spring' transitions for bouncy effects.",
        ],
        image: "/images/cheatsheetImages/task54/5.webp"
      },
      {
        title: "",
        subtitle: "Close Button with FontAwesome Icon",
        details: [
          "The close button uses FontAwesome for the 'X' icon.",
          "Animations make the close interaction more engaging.",
          "Use consistent styles and animations for better UX.",
        ],
        image: "/images/cheatsheetImages/task54/6.webp"
      },  {
        title: "",
        subtitle: "Styling with External CSS",
        details: [
          "Styles are defined in the styles.css file.",
          "Organized CSS ensures a clean layout and appearance.",
          "Use classes to keep styles modular and reusable.",
        ],
        image: "/images/cheatsheetImages/task54/7.webp"
      },  {
        title: "",
        subtitle: "Component Structure and Return Statement",
        details: [
          "The return statement defines the structure of the UI.",
          "Components like 'table', 'motion.button', and 'motion.div' are composed hierarchically.",
          "Keep the structure simple and readable for easy maintenance.",
        ],
        image: "/images/cheatsheetImages/task54/8.webp"
      },
    ],
  },
  {
    taskId: "Movies4u",
    content: [
      {
        title: "Header Component",
        subtitle: "State Management",
        details: [
          "'mobileMenuOpen': Tracks if the mobile menu is open.",
          "'searchQuery': Stores the user's input for the search bar.",
        ],
        image: "/images/cheatsheetImages/task55/1.webp"
      },
         {
        title: "",
        subtitle: "Props and Event Handling",
        details: [
          "'onSearch': A function passed as a prop to handle the search query.",
          "React event 'onChange' is used to detect changes in the search input.",
        ],
        image: "/images/cheatsheetImages/task55/2.webp"
      },
         {
        title: "",
        subtitle: "Rendering Dynamic Content",
        details: [
          "Maps through menuItems array to create navigation links.",
        ],
        image: "/images/cheatsheetImages/task55/3.webp"
      },
         {
        title: "Modal Component",
        subtitle: "Conditional Rendering",
        details: [
          "Prevents rendering the modal if it's not open.",
        ],
        image: "/images/cheatsheetImages/task55/5.webp"
      },
         {
        title: "",
        subtitle: "Event Propagation",
        details: [
          "Prevents click events on the modal content from propagating to the overlay.",
        ],
        image: "/images/cheatsheetImages/task55/6.webp"
      },
         {
        title: "Slider Component",
        subtitle: "State Management",
        details: [
          "'activeIndex': Tracks the currently active slide.",
          "'filteredImages': Stores the filtered images based on the search query.",
        ],
        image: "/images/cheatsheetImages/task55/7.webp"
      },
      {
        title: "",
        subtitle: "Lifecycle Methods with useEffect",
        details: [
          "Automatically transitions slides every 5 seconds.",
        ],
        image: "/images/cheatsheetImages/task55/8.webp"
      },  {
        title: "",
        subtitle: "Search Filtering",
        details: [
          "Filters images based on searchQuery and resets the active slide.",
        ],
        image: "/images/cheatsheetImages/task55/9.webp"
      },  {
        title: "",
        subtitle: "Dynamic Content",
        details: [
          "Maps through filteredImages to create slides.",
        ],
        image: "/images/cheatsheetImages/task55/10.webp"
      },  {
        title: "",
        subtitle: "Modal Integration",
        details: [
          "Opens a modal with detailed information about the currently active slide.",
        ],
        image: "/images/cheatsheetImages/task55/11.webp"
      }, 
    ],
  },
  {
    taskId: "Natures-Beauty",
    content: [
      {
        title: "Navbar Component",
        subtitle: "toggleMenu function",
        details: [
          "Handles the menu toggle event. It uses document.querySelector to find the .container element and toggles the active class.",
        ],
        image: "/images/cheatsheetImages/task56/1.webp"
      },
         {
        title: "Links Component",
        subtitle: "CSS Variables",
        details: [
          "The style prop defines a custom CSS variable --i for each link, which can be used to create delay effects in animations.",
        ],
        image: "/images/cheatsheetImages/task56/2.webp"
      },
        
    ],
  },
  {
    taskId: "Captcha-Guard",
    content: [
      {
        title: "",
        subtitle: "State Variables",
        details: [
          "'captcha': Stores the randomly generated captcha string.",
          "'userInput': Holds the user-entered captcha.",
          "'message': Provides feedback to the user (e.g., success or error messages).",
          "'isSuccess': Boolean indicating whether the captcha matched.",
          "Use 'useState' for reactive state management in functional components.",

        ],
        image: "/images/cheatsheetImages/task57/1.webp"
      },
         {
        title: "",
        subtitle: "Generating a Captcha",
        details: [
          "Creates a 6-character random captcha using letters and numbers.",
          "Resets feedback and input fields when a new captcha is generated.",
          "Use 'Math.random' and 'Math.floor' to randomly pick characters from an array.",
        ],
        image: "/images/cheatsheetImages/task57/2.webp"
      },
         {
        title: "",
        subtitle: "Checking Captcha",
        details: [
          "Compares user input with the generated captcha (ignoring spaces).",
          "Sets appropriate feedback messages and triggers a new captcha on success.",
          "Use 'setTimeout' for delayed actions like refreshing the captcha.",
        ],
        image: "/images/cheatsheetImages/task57/3.webp"
      },
         {
        title: "",
        subtitle: "Lifecycle Hook: useEffect",
        details: [
          "Runs 'generateCaptcha' when the component mounts (empty dependency array).",
          "Use 'useEffect' for initialization logic in functional components.",
        ],
        image: "/images/cheatsheetImages/task57/4.webp"
      },
        
    ],
  },
  {
    taskId: "Calculator",
    content: [
      {
        title: "",
        subtitle: "Managing State with useState",
        details: [
          "State Variable (display): Holds the current value displayed on the calculator.",
          "State Setter Function (setDisplay): Updates the 'display' value.",
          "Initial State: The display starts with an empty string.",
          "Use 'useState' to create reactive data that updates the UI automatically when changed.",
        ],
        image: "/images/cheatsheetImages/task58/1.webp"
      },
         {
        title: "",
        subtitle: "Showing Button Values",
        details: [
          "Concatenates the value of the pressed button to the current 'display' value.",
          "Event Handling: Triggered when a button is clicked.",
          "Event handlers like 'onClick' can call custom functions to update the state.",
        ],
        image: "/images/cheatsheetImages/task58/2.webp"
      },
         {
        title: "",
        subtitle: "Calculating the Result",
        details: [
          "Uses the 'evaluate' function to compute the mathematical expression in the 'display'.",
          "Error Handling: If the calculation fails, it displays 'Error'.",
          "Wrap calculations in a 'try-catch' block to gracefully handle invalid inputs.",
        ],
        image: "/images/cheatsheetImages/task58/3.webp"
      },
         {
        title: "",
        subtitle: "Resetting the Display",
        details: [
          "Resets the calculator by clearing the 'display'.",
          "Use this function to return the calculator to its initial state.",
        ],
        image: "/images/cheatsheetImages/task58/4.webp"
      },
         {
        title: "",
        subtitle: "Deleting the Last Character",
        details: [
          "Removes the last character from the 'display' using 'slice'.",
          "Useful for correcting input errors without clearing everything.",
        ],
        image: "/images/cheatsheetImages/task58/5.webp"
      },
        
    ],
  },
  {
    taskId: "Copy-To-Clipboard",
    content: [
      {
        title: "",
        subtitle: "Setting Up State",
        details: [
          "'copied' tracks whether the text was successfully copied to the clipboard.",
          "'setCopied' is the function to update the 'copied' state.",
          "Use 'copied' to dynamically update the button label.",
        ],
        image: "/images/cheatsheetImages/task59/1.webp"
      },
         {
        title: "",
        subtitle: "Referencing the TextArea",
        details: [
          "'useRef' creates a mutable reference to the <textarea> DOM element.",
          "This reference is used to fetch the value of the text to copy.",
          "Use 'textAreaRef.current.value' to access the text inside the textarea.",
        ],
        image: "/images/cheatsheetImages/task59/2.webp"
      },
         {
        title: "",
        subtitle: "Initializing Clipboard.js",
        details: [
          "Clipboard listens for a button click and copies the referenced text.",
          "clipboard.on('success'): Sets 'copied' to 'true' when the text is successfully copied, with a 2-second delay to reset.",
          "clipboard.on('error'): Logs an error if the copy action fails.",
          "clipboard.destroy(): Cleans up the Clipboard instance on component unmount.",
          "Always clean up side effects in 'useEffect' to prevent memory leaks.",
        ],
        image: "/images/cheatsheetImages/task59/3.webp"
      },
        
    ],
  },
  {
    taskId: "English-Dictionary",
    content: [
      {
        title: "",
        subtitle: "Setting Up State with useState",
        details: [
          "word: Stores the word entered by the user.",
          "result: Holds the data returned from the API (e.g., word meaning, example, etc.).",
          "infoText: Displays information or status messages to the user.",
          "Use useState to manage dynamic data that changes with user interaction.",

        ],
        image: "/images/cheatsheetImages/task60/1.webp"
      },
         {
        title: "",
        subtitle: "Searching for a Word",
        details: [
          "Validates the input and initiates the API call if valid.",
        ],
        image: "/images/cheatsheetImages/task60/2.webp"
      },
         {
        title: "",
        subtitle: "Clearing Input and Results",
        details: [
          "Resets the search bar, result data, and informational text.",
          "Use event handlers to respond to user actions like clicks or input changes.",
        ],
        image: "/images/cheatsheetImages/task60/3.webp"
      },
         {
        title: "",
        subtitle: "Rendering Conditional Content",
        details: [
          "Conditionally renders the result only if data is available.",
          "Use conditional rendering to show or hide UI elements based on state.",
        ],
        image: "/images/cheatsheetImages/task60/4.webp"
      },
         {
        title: "",
        subtitle: "Input Handling",
        details: [
          "Captures user input and updates the 'word' state dynamically.",
          "Use 'onChange' to track changes in form fields.",
        ],
        image: "/images/cheatsheetImages/task60/5.webp"
      },
        
    ],
  },
  {
    taskId: "day7",
    content: [
      {
        title: "Setting Up the Environment",
        subtitle: "Environment Variables",
        details: [
          "'dotenv.config()' loads environment variables from a .env file into process.env. This is used to securely store sensitive information, such as database connection strings or API keys, which you don't want to hard-code into your application.",
         
        ],
      },
         {
        title: "Initializing the Express App",
        subtitle: "",
        details: [
          "A minimal web framework for building RESTful APIs and web applications.",
          "'express()' initializes the server instance.",
          "cors(): Enables Cross-Origin Resource Sharing, which is necessary for APIs to handle requests from different domains.",
          "express.json(): Parses incoming JSON requests and makes the data accessible in req.body.",
        ],
      },
         {
        title: "Database Connection",
        subtitle: "",
        details: [
          "A NoSQL database that stores data in JSON-like format. It's highly scalable and flexible for modern applications.",
          "mongoose.connect() establishes a connection to MongoDB using the URI provided in the .env file.",
          "If the connection fails, the catch block captures the error and logs it to the console.",
          "process.exit(1) ensures the application exits gracefully with a failure code.",
        ],
      },
         {
        title: "Creating a Test API Endpoint",
        subtitle: "GET / Route",
        details: [
          "Responds with a simple message ('API is running...') to verify that the server is working.",
        ],
      },
         {
        title: " Starting the Server",
        subtitle: "",
        details: [
          "The PORT is taken from the .env file or defaults to 5000 if not specified.",
          "app.listen(PORT, callback) starts the server and listens for incoming requests on the specified port.",
        ],
      },
         {
        title: "Code Execution Flow",
        subtitle: "",
        details: [
          "dotenv reads the .env file and populates process.env.",
          "The Express app is created, and middleware is applied.",
          "The connectDB function attempts to establish a MongoDB connection.",
          "A test route (GET /) is set up to ensure the API is functional.",
          "The app begins listening for requests on the specified port.",
        ],
      },
      {
        title: "Best Practices",
        subtitle: "",
        details: [
          "Keep your database connection logic (connectDB) in a separate file for modularity and reusability.",
          "Use try-catch blocks to handle runtime errors gracefully.",
          "Never hard-code sensitive credentials into your codebase. Use .env files and ensure they're excluded from version control using .gitignore.",
        ],
      },
    ],
  },
  {
    taskId: "day8",
    content: [
      {
        title: "What is a Schema?",
        subtitle: "",
        details: [
          "A schema in Mongoose is a structure that defines the shape of the documents within a MongoDB collection. It acts as a blueprint for your data, ensuring consistency and applying validations.",
          "A User schema might define fields like name, email, and password.",
          "You can specify data types, required fields, default values, and more.",
        ],
      },
         {
        title: "What is a Model?",
        subtitle: "",
        details: [
          "A model is a compiled version of a schema. It provides an interface to interact with the database, allowing you to perform operations like creating, reading, updating, and deleting documents.",
         
        ],
      },
         {
        title: "Data Types",
        subtitle: "Mongoose provides various data types for schema fields:",
        details: [
          "String: For text values (e.g., name, email).",
          "Number: For numeric values (e.g., price, rating).",
          "Boolean: For true/false values (e.g., isActive, approved).", 
          "Date: For timestamps (e.g., createdAt, birthDate).",
          "Array: For lists of values (e.g., tags, skills).",
          "ObjectId: For references to other documents.",
        ],
      },
         {
        title: "Validations",
        subtitle: "Validations ensure data integrity by applying rules:",
        details: [
          "required: true: Ensures the field must have a value.",
          "unique: true: Prevents duplicate values (e.g., for email).",
          "min and max: Define numeric or string length constraints.",
        ],
      },
         {
        title: "Default Values",
        subtitle: "Fields can have default values if none are provided:",
        details: [
          "createdAt: { type: Date, default: Date.now }.",
         
        ],
      },
         {
        title: "Relationships with ref",
        subtitle: "Schemas can reference documents from other collections using 'ObjectId' and 'ref':",
        details: [          
          "A BlogPost schema's author field references the User schema.",
        ],
      },
      {
        title: "Enumerations (enum)",
        subtitle: "Restrict a field to specific values:",
        details: [
          "role: { type: String, enum: ['Student', 'Teacher', 'Admin'] }.",
       
        ],
      },
         {
        title: "Tips for Schema Design",
        subtitle: "",
        details: [
          "Normalize Relationships: Use references (ref) to connect related documents, ensuring scalability. For example: A LessonSchedule references User for both teacher and student.",
          "Embed Data When Needed: For small, unchanging data, you can embed it directly instead of using references. For example, tags in a BlogPost.",
          "Use Timestamps: Add { timestamps: true } to schemas to automatically manage createdAt and updatedAt fields.",
          "Optimize Queries with Indexes: For frequently queried fields (e.g., email), add an index to improve query performance: Example: email: { type: String, unique: true, index: true }.",

        ],
      },
     
    ],
  },
  {
    taskId: "day9",
    content: [
      {
        title: "Direct Code Implementation",
        subtitle: "Load Environment Variables",
        details: [
          "Sensitive data like database URIs should never be hardcoded. Using environment variables keeps your application secure and allows easy configuration across environments (development, staging, production).",
          
        ],
      },
         {
        title: "",
        subtitle: "Check for Missing Environment Variables",
        details: [
          "Missing or incorrect environment variables can lead to runtime errors. Checking and handling these early ensures smoother operations and avoids debugging issues later.",
         
        ],
      },
         {
        title: "",
        subtitle: "Set Up MongoDB Connection",
        details: [
          "Connecting to MongoDB is essential to perform CRUD (Create, Read, Update, Delete) operations. Using mongoose.connect() establishes a reliable connection and allows interaction with the database.",
          
        ],
      },
         {
        title: "",
        subtitle: "Create a New User Object",
        details: [
          "The user object defines the data structure in line with the database schema. This ensures data consistency and validation before saving to MongoDB.",
         
        ],
      },
         {
        title: "",
        subtitle: "Save the User to the Database",
        details: [
          "Persisting the user data in MongoDB allows it to be retrieved later for authentication, display, or other operations. Calling .save() writes the data to the database.",
          
        ],
      },
         {
        title: "",
        subtitle: "Optional Cleanup",
        details: [
          "For testing purposes, developers may want to remove the test data after verifying functionality to keep the database clean. This step highlights how to remove the saved data if necessary.",
          
        ],
      },
      {
        title: "",
        subtitle: "Disconnect from MongoDB",
        details: [
          "Closing the database connection once operations are complete prevents resource leaks and ensures the application runs efficiently.",
          
        ],
      },
         {
        title: "",
        subtitle: "Error Handling",
        details: [
          "Errors during database connection, schema validation, or operations can disrupt the application. A try-catch block ensures graceful error handling and helps in debugging.",
         
        ],
      },
         {
        title: "Using Postman",
        subtitle: "Start Your Server",
        details: [
          "To test the API, the server needs to be running and listening for requests. This ensures the API is ready to handle incoming requests from Postman.",
         
        ],
      },
         {
        title: "",
        subtitle: "Open Postman",
        details: [
          "Postman is an effective tool for API testing, allowing you to send requests, check responses, and debug errors with ease.",
          
        ],
      },
         {
        title: "",
        subtitle: "Set Up the POST Request",
        details: [
          "Configuring a POST request lets you send data to the API to create a new resource (user). The API endpoint URL defines the route where the request will be handled.",
          
        ],
      },
      {
        title: "",
        subtitle: "Add Request Body (User Data)",
        details: [
          "The request body contains the data for the new user, such as name, email, and password. Sending it in JSON format ensures compatibility with modern APIs.",
         
        ],
      },    {
        title: "",
        subtitle: "Send the Request",
        details: [
          "This action triggers the server to process the user data, validate it, and save it to the database. It confirms if the API works as intended.",
          
        ],
      },    {
        title: "",
        subtitle: "Check the Response",
        details: [
          "The response from the server provides feedback on whether the operation was successful. A successful response confirms that the user was created and saved in the database.",
         
        ],
      },    {
        title: "",
        subtitle: "Verify the User in the Database",
        details: [
          "This step ensures the data was stored correctly and can be retrieved as needed. It acts as a final validation step for the API functionality.",
         
        ],
      },    {
        title: "",
        subtitle: "Handle Errors",
        details: [
          "Testing edge cases like missing fields or duplicate entries ensures that the API behaves predictably and returns appropriate error messages.",
         
        ],
      },  
    ],
  },
  {
    taskId: "day10",
    content: [
      {
        title: "Backend Development Basics",
        subtitle: "Node.js",
        details: [
          "Node.js is a JavaScript runtime that executes code outside of the browser, typically used for building server-side applications.",
          "Non-blocking I/O: Handles multiple requests simultaneously without waiting for one to complete.",
          "Event-driven: Executes operations asynchronously using event loops.",
        ],
      },
         {
        title: "",
        subtitle: "Express.js",
        details: [
          "A minimal and flexible framework for Node.js, used to build web applications and APIs.",
          "Middleware: Functions that run during the request/response cycle.",
          "Routing: Maps URLs to functions (e.g., /users to a function that fetches user data).",
          "Error handling: Built-in support for managing errors.",
        ],
        image: "/images/musicAcademyCheatsheet/day10/1.webp"

      },
         {
        title: "RESTful API Design",
        subtitle: "What is REST?",
        details: [
          "REST (Representational State Transfer) is an architectural style for designing web services.",
          "RESTful APIs allow interaction with resources (like users, posts) using standard HTTP methods.",
          "Resources are identified by URIs (e.g., /users for a collection of users, /users/:id for a single user).",
        ],
      },
         {
        title: "",
        subtitle: "Key Principles of REST",
        details: [
          "Statelessness: Each request from the client contains all the information needed to process the request (no reliance on stored session data on the server).",
          "Resource-Based: Everything is a resource, and each resource is identified by a unique URI.",
          "HTTP Methods: Specific operations (CRUD) are mapped to HTTP methods: GET: Retrieve data, POST: Create data, PUT: Update data, DELETE: Remove data.",
          "Uniform Interface: Consistent and predictable structure (e.g., /users always refers to the users resource).",
       
        ],
      },
         {
        title: "",
        subtitle: "RESTful API Example",
        details: [
          "Scenario: You have a users resource, and you need to perform CRUD operations.",
          
        ],
        image: "/images/musicAcademyCheatsheet/day10/2.webp",
      },
         {
        title: "",
        subtitle: "Example with Express.js",
        details: [
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/3.webp",

      },
      {
        title: "Database Integration",
        subtitle: "MongoDB",
        details: [
          "A NoSQL database that stores data in collections of documents (similar to JSON objects).",
          "Schema-less: Flexible structure, no predefined schema needed.",
          "Scalable: Ideal for applications with large data volumes.",
        ],
      },
         {
        title: "",
        subtitle: "Mongoose",
        details: [
          "An ODM (Object Data Modeling) library for MongoDB.",
          "Defines schemas and provides a way to interact with MongoDB using JavaScript objects.",
        ],
        image: "/images/musicAcademyCheatsheet/day10/4.webp",

      },
         {
        title: "Data Validation and Modeling",
        subtitle: "Why Validation Matters",
        details: [
          "Ensures data integrity.",
          "Prevents invalid data from being stored in the database.",
          "Improves application security.",
        
        ],

      },
         {
        title: "",
        subtitle: "Mongoose Validation Example",
        details: [
        
        ],
        image: "/images/musicAcademyCheatsheet/day10/5.webp",

      },
         {
        title: "Asynchronous Programming",
        subtitle: "What is Asynchronous Programming?",
        details: [
          "A programming paradigm where tasks run independently of the main application flow.",
          "Helps handle time-consuming operations (e.g., database queries) without blocking the application.",
          "A Promise represents the eventual result of an asynchronous operation.",
        ],
        image: "/images/musicAcademyCheatsheet/day10/6.webp",

      },
         {
        title: "",
        subtitle: "Async/Await Example:",
        details: [
        
        ],
        image: "/images/musicAcademyCheatsheet/day10/7.webp",

      },
      {
        title: "Error Handling",
        subtitle: "Why It's Important",
        details: [
          "Improves user experience by providing meaningful error messages.",
          "Prevents crashes from unhandled errors.",
        ],
      },
         {
        title: "",
        subtitle: "Using Try-Catch:",
        details: [
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/8.webp",

      },
         {
        title: "Middleware in Express",
        subtitle: "What is Middleware?",
        details: [
          "Middleware is a function in Express.js that has access to the request (req), response (res), and the next middleware in the stack.",
          "It can modify the request and response objects, end the request-response cycle, call the next middleware, or trigger an error.",

          
        ],
      },
         {
        title: "",
        subtitle: "Types of Middleware",
        details: [
          "express.json(): Parses incoming JSON payloads.",
          "express.static(): Serves static files like images or CSS.",
          "'cors' for handling cross-origin requests, helmet for securing HTTP headers.",
          "Your own functions for logging, validation, or authentication.",
        ],
        image: "/images/cheatsheetImages/task/.webp"
      },
         {
        title: "",
        subtitle: "Middleware Flow Example:",
        details: [
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/9.webp",

      },
      {
        title: "",
        subtitle: "Practical Middleware Use Cases:",
        details: [
          "Log every request to the console.",
          
        ],
        image: "/images/musicAcademyCheatsheet/day10/10.webp",

      }, {
        title: "",
        subtitle: "",
        details: [
          "Catch errors and send a generic error message.",
          
        ],
        image: "/images/musicAcademyCheatsheet/day10/11.webp",

      }, {
        title: "",
        subtitle: "",
        details: [
          "Verify if the user is logged in.",
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/12.webp",

      }, {
        title: "HTTP Routing",
        subtitle: "What is Routing?",
        details: [
          "Routing in Express defines how the server responds to specific client requests.",
          "Routes are matched based on the request method and URL path.",
          "Each route can have multiple handlers (middleware functions).",

          
        ],
        
      }, {
        title: "",
        subtitle: "Each route is associated with:",
        details: [
          "HTTP Method: Defines the operation (GET, POST, etc.).",
          "Path/Endpoint: The URL or part of it (e.g., /users).",
          "Handler Function: The logic to execute when the route is matched.",
        ],
      }, {
        title: "",
        subtitle: "Basic Route:",
        details: [
        
        ],
        image: "/images/musicAcademyCheatsheet/day10/13.webp",

      }, {
        title: "",
        subtitle: "Dynamic Route:",
        details: [
          "Capture parameters from the URL using :parameterName.",
          
        ],
        image: "/images/musicAcademyCheatsheet/day10/14.webp",

      }, {
        title: "",
        subtitle: "Chaining Routes",
        details: [
          "Use the same route path with different HTTP methods.",
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/15.webp",

      }, {
        title: "",
        subtitle: "Router Example (Organizing Routes)",
        details: [
          "Use express.Router to modularize routes for better maintainability.",
        
        ],
        image: "/images/musicAcademyCheatsheet/day10/16.webp",

      }, {
        title: "",
        subtitle: "Query Parameters",
        details: [
          "Use req.query to handle optional query strings like /search?name=John.",
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/17.webp",

      }, {
        title: "",
        subtitle: "Middleware in Routes",
        details: [
          "Apply middleware to specific routes.",
          
        ],
        image: "/images/musicAcademyCheatsheet/day10/18.webp",

      }, {
        title: "",
        subtitle: "404 Handling",
        details: [
          "Catch-all route for undefined endpoints.",
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/19.webp",

      }, {
        title: "Environment Variables",
        subtitle: "",
        details: [
          "Keep sensitive data (e.g., database URIs, API keys) out of your codebase.",
          "Use environment variables to store configuration values.",

        
        ],
        image: "/images/musicAcademyCheatsheet/day10/20.webp",

      }, {
        title: "",
        subtitle: "",
        details: [
          "Load .env variables using dotenv",

         
        ],
                image: "/images/musicAcademyCheatsheet/day10/20.webp",

      }, {
        title: "Authentication and Authorization",
        subtitle: "Authentication",
        details: [
          "Verifies the identity of a user (e.g., logging in with a username and password).",
          "Creates a session or token to maintain the user's authenticated state.",
          "Common methods include JWT (JSON Web Tokens) or sessions with cookies.",

         
        ],
      }, {
        title: "",
        subtitle: "Authorization",
        details: [
          "Ensures a user has the necessary permissions to access a resource.",
          "Roles and permissions are used to control access to specific routes or actions.",
          "Middleware functions can check the user's role before allowing access.",

          
        ],
      },
    ],
  },
  {
    taskId: "day11",
    content: [
      {
        title: "Backend Development Basics",
        subtitle: "Node.js",
        details: [
          "Node.js is a JavaScript runtime that executes code outside of the browser, typically used for building server-side applications.",
          "Non-blocking I/O: Handles multiple requests simultaneously without waiting for one to complete.",
          "Event-driven: Executes operations asynchronously using event loops.",
        ],
      },
         {
        title: "",
        subtitle: "Express.js",
        details: [
          "A minimal and flexible framework for Node.js, used to build web applications and APIs.",
          "Middleware: Functions that run during the request/response cycle.",
          "Routing: Maps URLs to functions (e.g., /users to a function that fetches user data).",
          "Error handling: Built-in support for managing errors.",
        ],
        image: "/images/musicAcademyCheatsheet/day10/1.webp"

      },
         {
        title: "RESTful API Design",
        subtitle: "What is REST?",
        details: [
          "REST (Representational State Transfer) is an architectural style for designing web services.",
          "RESTful APIs allow interaction with resources (like users, posts) using standard HTTP methods.",
          "Resources are identified by URIs (e.g., /users for a collection of users, /users/:id for a single user).",
        ],
      },
         {
        title: "",
        subtitle: "Key Principles of REST",
        details: [
          "Statelessness: Each request from the client contains all the information needed to process the request (no reliance on stored session data on the server).",
          "Resource-Based: Everything is a resource, and each resource is identified by a unique URI.",
          "HTTP Methods: Specific operations (CRUD) are mapped to HTTP methods: GET: Retrieve data, POST: Create data, PUT: Update data, DELETE: Remove data.",
          "Uniform Interface: Consistent and predictable structure (e.g., /users always refers to the users resource).",
       
        ],
      },
         {
        title: "",
        subtitle: "RESTful API Example",
        details: [
          "Scenario: You have a users resource, and you need to perform CRUD operations.",
          
        ],
        image: "/images/musicAcademyCheatsheet/day10/2.webp",
      },
         {
        title: "",
        subtitle: "Example with Express.js",
        details: [
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/3.webp",

      },
      {
        title: "Database Integration",
        subtitle: "MongoDB",
        details: [
          "A NoSQL database that stores data in collections of documents (similar to JSON objects).",
          "Schema-less: Flexible structure, no predefined schema needed.",
          "Scalable: Ideal for applications with large data volumes.",
        ],
      },
         {
        title: "",
        subtitle: "Mongoose",
        details: [
          "An ODM (Object Data Modeling) library for MongoDB.",
          "Defines schemas and provides a way to interact with MongoDB using JavaScript objects.",
        ],
        image: "/images/musicAcademyCheatsheet/day10/4.webp",

      },
         {
        title: "Data Validation and Modeling",
        subtitle: "Why Validation Matters",
        details: [
          "Ensures data integrity.",
          "Prevents invalid data from being stored in the database.",
          "Improves application security.",
        
        ],

      },
         {
        title: "",
        subtitle: "Mongoose Validation Example",
        details: [
        
        ],
        image: "/images/musicAcademyCheatsheet/day10/5.webp",

      },
         {
        title: "Asynchronous Programming",
        subtitle: "What is Asynchronous Programming?",
        details: [
          "A programming paradigm where tasks run independently of the main application flow.",
          "Helps handle time-consuming operations (e.g., database queries) without blocking the application.",
          "A Promise represents the eventual result of an asynchronous operation.",
        ],
        image: "/images/musicAcademyCheatsheet/day10/6.webp",

      },
         {
        title: "",
        subtitle: "Async/Await Example:",
        details: [
        
        ],
        image: "/images/musicAcademyCheatsheet/day10/7.webp",

      },
      {
        title: "Error Handling",
        subtitle: "Why It's Important",
        details: [
          "Improves user experience by providing meaningful error messages.",
          "Prevents crashes from unhandled errors.",
        ],
      },
         {
        title: "",
        subtitle: "Using Try-Catch:",
        details: [
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/8.webp",

      },
         {
        title: "Middleware in Express",
        subtitle: "What is Middleware?",
        details: [
          "Middleware is a function in Express.js that has access to the request (req), response (res), and the next middleware in the stack.",
          "It can modify the request and response objects, end the request-response cycle, call the next middleware, or trigger an error.",

          
        ],
      },
         {
        title: "",
        subtitle: "Types of Middleware",
        details: [
          "express.json(): Parses incoming JSON payloads.",
          "express.static(): Serves static files like images or CSS.",
          "'cors' for handling cross-origin requests, helmet for securing HTTP headers.",
          "Your own functions for logging, validation, or authentication.",
        ],
        image: "/images/cheatsheetImages/task/.webp"
      },
         {
        title: "",
        subtitle: "Middleware Flow Example:",
        details: [
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/9.webp",

      },
      {
        title: "",
        subtitle: "Practical Middleware Use Cases:",
        details: [
          "Log every request to the console.",
          
        ],
        image: "/images/musicAcademyCheatsheet/day10/10.webp",

      }, {
        title: "",
        subtitle: "",
        details: [
          "Catch errors and send a generic error message.",
          
        ],
        image: "/images/musicAcademyCheatsheet/day10/11.webp",

      }, {
        title: "",
        subtitle: "",
        details: [
          "Verify if the user is logged in.",
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/12.webp",

      }, {
        title: "HTTP Routing",
        subtitle: "What is Routing?",
        details: [
          "Routing in Express defines how the server responds to specific client requests.",
          "Routes are matched based on the request method and URL path.",
          "Each route can have multiple handlers (middleware functions).",

          
        ],
        
      }, {
        title: "",
        subtitle: "Each route is associated with:",
        details: [
          "HTTP Method: Defines the operation (GET, POST, etc.).",
          "Path/Endpoint: The URL or part of it (e.g., /users).",
          "Handler Function: The logic to execute when the route is matched.",
        ],
      }, {
        title: "",
        subtitle: "Basic Route:",
        details: [
        
        ],
        image: "/images/musicAcademyCheatsheet/day10/13.webp",

      }, {
        title: "",
        subtitle: "Dynamic Route:",
        details: [
          "Capture parameters from the URL using :parameterName.",
          
        ],
        image: "/images/musicAcademyCheatsheet/day10/14.webp",

      }, {
        title: "",
        subtitle: "Chaining Routes",
        details: [
          "Use the same route path with different HTTP methods.",
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/15.webp",

      }, {
        title: "",
        subtitle: "Router Example (Organizing Routes)",
        details: [
          "Use express.Router to modularize routes for better maintainability.",
        
        ],
        image: "/images/musicAcademyCheatsheet/day10/16.webp",

      }, {
        title: "",
        subtitle: "Query Parameters",
        details: [
          "Use req.query to handle optional query strings like /search?name=John.",
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/17.webp",

      }, {
        title: "",
        subtitle: "Middleware in Routes",
        details: [
          "Apply middleware to specific routes.",
          
        ],
        image: "/images/musicAcademyCheatsheet/day10/18.webp",

      }, {
        title: "",
        subtitle: "404 Handling",
        details: [
          "Catch-all route for undefined endpoints.",
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/19.webp",

      }, {
        title: "Environment Variables",
        subtitle: "",
        details: [
          "Keep sensitive data (e.g., database URIs, API keys) out of your codebase.",
          "Use environment variables to store configuration values.",

        
        ],
        image: "/images/musicAcademyCheatsheet/day10/20.webp",

      }, {
        title: "",
        subtitle: "",
        details: [
          "Load .env variables using dotenv",

         
        ],
                image: "/images/musicAcademyCheatsheet/day10/20.webp",

      }, {
        title: "Authentication and Authorization",
        subtitle: "Authentication",
        details: [
          "Verifies the identity of a user (e.g., logging in with a username and password).",
          "Creates a session or token to maintain the user's authenticated state.",
          "Common methods include JWT (JSON Web Tokens) or sessions with cookies.",

         
        ],
      }, {
        title: "",
        subtitle: "Authorization",
        details: [
          "Ensures a user has the necessary permissions to access a resource.",
          "Roles and permissions are used to control access to specific routes or actions.",
          "Middleware functions can check the user's role before allowing access.",

          
        ],
      },
    ],
  },{
    taskId: "day12",
    content: [
      {
        title: "Backend Development Basics",
        subtitle: "Node.js",
        details: [
          "Node.js is a JavaScript runtime that executes code outside of the browser, typically used for building server-side applications.",
          "Non-blocking I/O: Handles multiple requests simultaneously without waiting for one to complete.",
          "Event-driven: Executes operations asynchronously using event loops.",
        ],
      },
         {
        title: "",
        subtitle: "Express.js",
        details: [
          "A minimal and flexible framework for Node.js, used to build web applications and APIs.",
          "Middleware: Functions that run during the request/response cycle.",
          "Routing: Maps URLs to functions (e.g., /users to a function that fetches user data).",
          "Error handling: Built-in support for managing errors.",
        ],
        image: "/images/musicAcademyCheatsheet/day10/1.webp"

      },
         {
        title: "RESTful API Design",
        subtitle: "What is REST?",
        details: [
          "REST (Representational State Transfer) is an architectural style for designing web services.",
          "RESTful APIs allow interaction with resources (like users, posts) using standard HTTP methods.",
          "Resources are identified by URIs (e.g., /users for a collection of users, /users/:id for a single user).",
        ],
      },
         {
        title: "",
        subtitle: "Key Principles of REST",
        details: [
          "Statelessness: Each request from the client contains all the information needed to process the request (no reliance on stored session data on the server).",
          "Resource-Based: Everything is a resource, and each resource is identified by a unique URI.",
          "HTTP Methods: Specific operations (CRUD) are mapped to HTTP methods: GET: Retrieve data, POST: Create data, PUT: Update data, DELETE: Remove data.",
          "Uniform Interface: Consistent and predictable structure (e.g., /users always refers to the users resource).",
       
        ],
      },
         {
        title: "",
        subtitle: "RESTful API Example",
        details: [
          "Scenario: You have a users resource, and you need to perform CRUD operations.",
          
        ],
        image: "/images/musicAcademyCheatsheet/day10/2.webp",
      },
         {
        title: "",
        subtitle: "Example with Express.js",
        details: [
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/3.webp",

      },
      {
        title: "Database Integration",
        subtitle: "MongoDB",
        details: [
          "A NoSQL database that stores data in collections of documents (similar to JSON objects).",
          "Schema-less: Flexible structure, no predefined schema needed.",
          "Scalable: Ideal for applications with large data volumes.",
        ],
      },
         {
        title: "",
        subtitle: "Mongoose",
        details: [
          "An ODM (Object Data Modeling) library for MongoDB.",
          "Defines schemas and provides a way to interact with MongoDB using JavaScript objects.",
        ],
        image: "/images/musicAcademyCheatsheet/day10/4.webp",

      },
         {
        title: "Data Validation and Modeling",
        subtitle: "Why Validation Matters",
        details: [
          "Ensures data integrity.",
          "Prevents invalid data from being stored in the database.",
          "Improves application security.",
        
        ],

      },
         {
        title: "",
        subtitle: "Mongoose Validation Example",
        details: [
        
        ],
        image: "/images/musicAcademyCheatsheet/day10/5.webp",

      },
         {
        title: "Asynchronous Programming",
        subtitle: "What is Asynchronous Programming?",
        details: [
          "A programming paradigm where tasks run independently of the main application flow.",
          "Helps handle time-consuming operations (e.g., database queries) without blocking the application.",
          "A Promise represents the eventual result of an asynchronous operation.",
        ],
        image: "/images/musicAcademyCheatsheet/day10/6.webp",

      },
         {
        title: "",
        subtitle: "Async/Await Example:",
        details: [
        
        ],
        image: "/images/musicAcademyCheatsheet/day10/7.webp",

      },
      {
        title: "Error Handling",
        subtitle: "Why It's Important",
        details: [
          "Improves user experience by providing meaningful error messages.",
          "Prevents crashes from unhandled errors.",
        ],
      },
         {
        title: "",
        subtitle: "Using Try-Catch:",
        details: [
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/8.webp",

      },
         {
        title: "Middleware in Express",
        subtitle: "What is Middleware?",
        details: [
          "Middleware is a function in Express.js that has access to the request (req), response (res), and the next middleware in the stack.",
          "It can modify the request and response objects, end the request-response cycle, call the next middleware, or trigger an error.",

          
        ],
      },
         {
        title: "",
        subtitle: "Types of Middleware",
        details: [
          "express.json(): Parses incoming JSON payloads.",
          "express.static(): Serves static files like images or CSS.",
          "'cors' for handling cross-origin requests, helmet for securing HTTP headers.",
          "Your own functions for logging, validation, or authentication.",
        ],
        image: "/images/cheatsheetImages/task/.webp"
      },
         {
        title: "",
        subtitle: "Middleware Flow Example:",
        details: [
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/9.webp",

      },
      {
        title: "",
        subtitle: "Practical Middleware Use Cases:",
        details: [
          "Log every request to the console.",
          
        ],
        image: "/images/musicAcademyCheatsheet/day10/10.webp",

      }, {
        title: "",
        subtitle: "",
        details: [
          "Catch errors and send a generic error message.",
          
        ],
        image: "/images/musicAcademyCheatsheet/day10/11.webp",

      }, {
        title: "",
        subtitle: "",
        details: [
          "Verify if the user is logged in.",
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/12.webp",

      }, {
        title: "HTTP Routing",
        subtitle: "What is Routing?",
        details: [
          "Routing in Express defines how the server responds to specific client requests.",
          "Routes are matched based on the request method and URL path.",
          "Each route can have multiple handlers (middleware functions).",

          
        ],
        
      }, {
        title: "",
        subtitle: "Each route is associated with:",
        details: [
          "HTTP Method: Defines the operation (GET, POST, etc.).",
          "Path/Endpoint: The URL or part of it (e.g., /users).",
          "Handler Function: The logic to execute when the route is matched.",
        ],
      }, {
        title: "",
        subtitle: "Basic Route:",
        details: [
        
        ],
        image: "/images/musicAcademyCheatsheet/day10/13.webp",

      }, {
        title: "",
        subtitle: "Dynamic Route:",
        details: [
          "Capture parameters from the URL using :parameterName.",
          
        ],
        image: "/images/musicAcademyCheatsheet/day10/14.webp",

      }, {
        title: "",
        subtitle: "Chaining Routes",
        details: [
          "Use the same route path with different HTTP methods.",
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/15.webp",

      }, {
        title: "",
        subtitle: "Router Example (Organizing Routes)",
        details: [
          "Use express.Router to modularize routes for better maintainability.",
        
        ],
        image: "/images/musicAcademyCheatsheet/day10/16.webp",

      }, {
        title: "",
        subtitle: "Query Parameters",
        details: [
          "Use req.query to handle optional query strings like /search?name=John.",
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/17.webp",

      }, {
        title: "",
        subtitle: "Middleware in Routes",
        details: [
          "Apply middleware to specific routes.",
          
        ],
        image: "/images/musicAcademyCheatsheet/day10/18.webp",

      }, {
        title: "",
        subtitle: "404 Handling",
        details: [
          "Catch-all route for undefined endpoints.",
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/19.webp",

      }, {
        title: "Environment Variables",
        subtitle: "",
        details: [
          "Keep sensitive data (e.g., database URIs, API keys) out of your codebase.",
          "Use environment variables to store configuration values.",

        
        ],
        image: "/images/musicAcademyCheatsheet/day10/20.webp",

      }, {
        title: "",
        subtitle: "",
        details: [
          "Load .env variables using dotenv",

         
        ],
                image: "/images/musicAcademyCheatsheet/day10/20.webp",

      }, {
        title: "Authentication and Authorization",
        subtitle: "Authentication",
        details: [
          "Verifies the identity of a user (e.g., logging in with a username and password).",
          "Creates a session or token to maintain the user's authenticated state.",
          "Common methods include JWT (JSON Web Tokens) or sessions with cookies.",

         
        ],
      }, {
        title: "",
        subtitle: "Authorization",
        details: [
          "Ensures a user has the necessary permissions to access a resource.",
          "Roles and permissions are used to control access to specific routes or actions.",
          "Middleware functions can check the user's role before allowing access.",

          
        ],
      },
    ],
   },
   {
    taskId: "day13",
    content: [
      {
        title: "Backend Development Basics",
        subtitle: "Node.js",
        details: [
          "Node.js is a JavaScript runtime that executes code outside of the browser, typically used for building server-side applications.",
          "Non-blocking I/O: Handles multiple requests simultaneously without waiting for one to complete.",
          "Event-driven: Executes operations asynchronously using event loops.",
        ],
      },
         {
        title: "",
        subtitle: "Express.js",
        details: [
          "A minimal and flexible framework for Node.js, used to build web applications and APIs.",
          "Middleware: Functions that run during the request/response cycle.",
          "Routing: Maps URLs to functions (e.g., /users to a function that fetches user data).",
          "Error handling: Built-in support for managing errors.",
        ],
        image: "/images/musicAcademyCheatsheet/day10/1.webp"

      },
         {
        title: "RESTful API Design",
        subtitle: "What is REST?",
        details: [
          "REST (Representational State Transfer) is an architectural style for designing web services.",
          "RESTful APIs allow interaction with resources (like users, posts) using standard HTTP methods.",
          "Resources are identified by URIs (e.g., /users for a collection of users, /users/:id for a single user).",
        ],
      },
         {
        title: "",
        subtitle: "Key Principles of REST",
        details: [
          "Statelessness: Each request from the client contains all the information needed to process the request (no reliance on stored session data on the server).",
          "Resource-Based: Everything is a resource, and each resource is identified by a unique URI.",
          "HTTP Methods: Specific operations (CRUD) are mapped to HTTP methods: GET: Retrieve data, POST: Create data, PUT: Update data, DELETE: Remove data.",
          "Uniform Interface: Consistent and predictable structure (e.g., /users always refers to the users resource).",
       
        ],
      },
         {
        title: "",
        subtitle: "RESTful API Example",
        details: [
          "Scenario: You have a users resource, and you need to perform CRUD operations.",
          
        ],
        image: "/images/musicAcademyCheatsheet/day10/2.webp",
      },
         {
        title: "",
        subtitle: "Example with Express.js",
        details: [
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/3.webp",

      },
      {
        title: "Database Integration",
        subtitle: "MongoDB",
        details: [
          "A NoSQL database that stores data in collections of documents (similar to JSON objects).",
          "Schema-less: Flexible structure, no predefined schema needed.",
          "Scalable: Ideal for applications with large data volumes.",
        ],
      },
         {
        title: "",
        subtitle: "Mongoose",
        details: [
          "An ODM (Object Data Modeling) library for MongoDB.",
          "Defines schemas and provides a way to interact with MongoDB using JavaScript objects.",
        ],
        image: "/images/musicAcademyCheatsheet/day10/4.webp",

      },
         {
        title: "Data Validation and Modeling",
        subtitle: "Why Validation Matters",
        details: [
          "Ensures data integrity.",
          "Prevents invalid data from being stored in the database.",
          "Improves application security.",
        
        ],

      },
         {
        title: "",
        subtitle: "Mongoose Validation Example",
        details: [
        
        ],
        image: "/images/musicAcademyCheatsheet/day10/5.webp",

      },
         {
        title: "Asynchronous Programming",
        subtitle: "What is Asynchronous Programming?",
        details: [
          "A programming paradigm where tasks run independently of the main application flow.",
          "Helps handle time-consuming operations (e.g., database queries) without blocking the application.",
          "A Promise represents the eventual result of an asynchronous operation.",
        ],
        image: "/images/musicAcademyCheatsheet/day10/6.webp",

      },
         {
        title: "",
        subtitle: "Async/Await Example:",
        details: [
        
        ],
        image: "/images/musicAcademyCheatsheet/day10/7.webp",

      },
      {
        title: "Error Handling",
        subtitle: "Why It's Important",
        details: [
          "Improves user experience by providing meaningful error messages.",
          "Prevents crashes from unhandled errors.",
        ],
      },
         {
        title: "",
        subtitle: "Using Try-Catch:",
        details: [
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/8.webp",

      },
         {
        title: "Middleware in Express",
        subtitle: "What is Middleware?",
        details: [
          "Middleware is a function in Express.js that has access to the request (req), response (res), and the next middleware in the stack.",
          "It can modify the request and response objects, end the request-response cycle, call the next middleware, or trigger an error.",

          
        ],
      },
         {
        title: "",
        subtitle: "Types of Middleware",
        details: [
          "express.json(): Parses incoming JSON payloads.",
          "express.static(): Serves static files like images or CSS.",
          "'cors' for handling cross-origin requests, helmet for securing HTTP headers.",
          "Your own functions for logging, validation, or authentication.",
        ],
        image: "/images/cheatsheetImages/task/.webp"
      },
         {
        title: "",
        subtitle: "Middleware Flow Example:",
        details: [
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/9.webp",

      },
      {
        title: "",
        subtitle: "Practical Middleware Use Cases:",
        details: [
          "Log every request to the console.",
          
        ],
        image: "/images/musicAcademyCheatsheet/day10/10.webp",

      }, {
        title: "",
        subtitle: "",
        details: [
          "Catch errors and send a generic error message.",
          
        ],
        image: "/images/musicAcademyCheatsheet/day10/11.webp",

      }, {
        title: "",
        subtitle: "",
        details: [
          "Verify if the user is logged in.",
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/12.webp",

      }, {
        title: "HTTP Routing",
        subtitle: "What is Routing?",
        details: [
          "Routing in Express defines how the server responds to specific client requests.",
          "Routes are matched based on the request method and URL path.",
          "Each route can have multiple handlers (middleware functions).",

          
        ],
        
      }, {
        title: "",
        subtitle: "Each route is associated with:",
        details: [
          "HTTP Method: Defines the operation (GET, POST, etc.).",
          "Path/Endpoint: The URL or part of it (e.g., /users).",
          "Handler Function: The logic to execute when the route is matched.",
        ],
      }, {
        title: "",
        subtitle: "Basic Route:",
        details: [
        
        ],
        image: "/images/musicAcademyCheatsheet/day10/13.webp",

      }, {
        title: "",
        subtitle: "Dynamic Route:",
        details: [
          "Capture parameters from the URL using :parameterName.",
          
        ],
        image: "/images/musicAcademyCheatsheet/day10/14.webp",

      }, {
        title: "",
        subtitle: "Chaining Routes",
        details: [
          "Use the same route path with different HTTP methods.",
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/15.webp",

      }, {
        title: "",
        subtitle: "Router Example (Organizing Routes)",
        details: [
          "Use express.Router to modularize routes for better maintainability.",
        
        ],
        image: "/images/musicAcademyCheatsheet/day10/16.webp",

      }, {
        title: "",
        subtitle: "Query Parameters",
        details: [
          "Use req.query to handle optional query strings like /search?name=John.",
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/17.webp",

      }, {
        title: "",
        subtitle: "Middleware in Routes",
        details: [
          "Apply middleware to specific routes.",
          
        ],
        image: "/images/musicAcademyCheatsheet/day10/18.webp",

      }, {
        title: "",
        subtitle: "404 Handling",
        details: [
          "Catch-all route for undefined endpoints.",
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/19.webp",

      }, {
        title: "Environment Variables",
        subtitle: "",
        details: [
          "Keep sensitive data (e.g., database URIs, API keys) out of your codebase.",
          "Use environment variables to store configuration values.",

        
        ],
        image: "/images/musicAcademyCheatsheet/day10/20.webp",

      }, {
        title: "",
        subtitle: "",
        details: [
          "Load .env variables using dotenv",

         
        ],
                image: "/images/musicAcademyCheatsheet/day10/20.webp",

      }, {
        title: "Authentication and Authorization",
        subtitle: "Authentication",
        details: [
          "Verifies the identity of a user (e.g., logging in with a username and password).",
          "Creates a session or token to maintain the user's authenticated state.",
          "Common methods include JWT (JSON Web Tokens) or sessions with cookies.",

         
        ],
      }, {
        title: "",
        subtitle: "Authorization",
        details: [
          "Ensures a user has the necessary permissions to access a resource.",
          "Roles and permissions are used to control access to specific routes or actions.",
          "Middleware functions can check the user's role before allowing access.",

          
        ],
      },
    ],
   },
   {
    taskId: "day14",
    content: [
      {
        title: "Backend Development Basics",
        subtitle: "Node.js",
        details: [
          "Node.js is a JavaScript runtime that executes code outside of the browser, typically used for building server-side applications.",
          "Non-blocking I/O: Handles multiple requests simultaneously without waiting for one to complete.",
          "Event-driven: Executes operations asynchronously using event loops.",
        ],
      },
         {
        title: "",
        subtitle: "Express.js",
        details: [
          "A minimal and flexible framework for Node.js, used to build web applications and APIs.",
          "Middleware: Functions that run during the request/response cycle.",
          "Routing: Maps URLs to functions (e.g., /users to a function that fetches user data).",
          "Error handling: Built-in support for managing errors.",
        ],
        image: "/images/musicAcademyCheatsheet/day10/1.webp"

      },
         {
        title: "RESTful API Design",
        subtitle: "What is REST?",
        details: [
          "REST (Representational State Transfer) is an architectural style for designing web services.",
          "RESTful APIs allow interaction with resources (like users, posts) using standard HTTP methods.",
          "Resources are identified by URIs (e.g., /users for a collection of users, /users/:id for a single user).",
        ],
      },
         {
        title: "",
        subtitle: "Key Principles of REST",
        details: [
          "Statelessness: Each request from the client contains all the information needed to process the request (no reliance on stored session data on the server).",
          "Resource-Based: Everything is a resource, and each resource is identified by a unique URI.",
          "HTTP Methods: Specific operations (CRUD) are mapped to HTTP methods: GET: Retrieve data, POST: Create data, PUT: Update data, DELETE: Remove data.",
          "Uniform Interface: Consistent and predictable structure (e.g., /users always refers to the users resource).",
       
        ],
      },
         {
        title: "",
        subtitle: "RESTful API Example",
        details: [
          "Scenario: You have a users resource, and you need to perform CRUD operations.",
          
        ],
        image: "/images/musicAcademyCheatsheet/day10/2.webp",
      },
         {
        title: "",
        subtitle: "Example with Express.js",
        details: [
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/3.webp",

      },
      {
        title: "Database Integration",
        subtitle: "MongoDB",
        details: [
          "A NoSQL database that stores data in collections of documents (similar to JSON objects).",
          "Schema-less: Flexible structure, no predefined schema needed.",
          "Scalable: Ideal for applications with large data volumes.",
        ],
      },
         {
        title: "",
        subtitle: "Mongoose",
        details: [
          "An ODM (Object Data Modeling) library for MongoDB.",
          "Defines schemas and provides a way to interact with MongoDB using JavaScript objects.",
        ],
        image: "/images/musicAcademyCheatsheet/day10/4.webp",

      },
         {
        title: "Data Validation and Modeling",
        subtitle: "Why Validation Matters",
        details: [
          "Ensures data integrity.",
          "Prevents invalid data from being stored in the database.",
          "Improves application security.",
        
        ],

      },
         {
        title: "",
        subtitle: "Mongoose Validation Example",
        details: [
        
        ],
        image: "/images/musicAcademyCheatsheet/day10/5.webp",

      },
         {
        title: "Asynchronous Programming",
        subtitle: "What is Asynchronous Programming?",
        details: [
          "A programming paradigm where tasks run independently of the main application flow.",
          "Helps handle time-consuming operations (e.g., database queries) without blocking the application.",
          "A Promise represents the eventual result of an asynchronous operation.",
        ],
        image: "/images/musicAcademyCheatsheet/day10/6.webp",

      },
         {
        title: "",
        subtitle: "Async/Await Example:",
        details: [
        
        ],
        image: "/images/musicAcademyCheatsheet/day10/7.webp",

      },
      {
        title: "Error Handling",
        subtitle: "Why It's Important",
        details: [
          "Improves user experience by providing meaningful error messages.",
          "Prevents crashes from unhandled errors.",
        ],
      },
         {
        title: "",
        subtitle: "Using Try-Catch:",
        details: [
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/8.webp",

      },
         {
        title: "Middleware in Express",
        subtitle: "What is Middleware?",
        details: [
          "Middleware is a function in Express.js that has access to the request (req), response (res), and the next middleware in the stack.",
          "It can modify the request and response objects, end the request-response cycle, call the next middleware, or trigger an error.",

          
        ],
      },
         {
        title: "",
        subtitle: "Types of Middleware",
        details: [
          "express.json(): Parses incoming JSON payloads.",
          "express.static(): Serves static files like images or CSS.",
          "'cors' for handling cross-origin requests, helmet for securing HTTP headers.",
          "Your own functions for logging, validation, or authentication.",
        ],
        image: "/images/cheatsheetImages/task/.webp"
      },
         {
        title: "",
        subtitle: "Middleware Flow Example:",
        details: [
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/9.webp",

      },
      {
        title: "",
        subtitle: "Practical Middleware Use Cases:",
        details: [
          "Log every request to the console.",
          
        ],
        image: "/images/musicAcademyCheatsheet/day10/10.webp",

      }, {
        title: "",
        subtitle: "",
        details: [
          "Catch errors and send a generic error message.",
          
        ],
        image: "/images/musicAcademyCheatsheet/day10/11.webp",

      }, {
        title: "",
        subtitle: "",
        details: [
          "Verify if the user is logged in.",
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/12.webp",

      }, {
        title: "HTTP Routing",
        subtitle: "What is Routing?",
        details: [
          "Routing in Express defines how the server responds to specific client requests.",
          "Routes are matched based on the request method and URL path.",
          "Each route can have multiple handlers (middleware functions).",

          
        ],
        
      }, {
        title: "",
        subtitle: "Each route is associated with:",
        details: [
          "HTTP Method: Defines the operation (GET, POST, etc.).",
          "Path/Endpoint: The URL or part of it (e.g., /users).",
          "Handler Function: The logic to execute when the route is matched.",
        ],
      }, {
        title: "",
        subtitle: "Basic Route:",
        details: [
        
        ],
        image: "/images/musicAcademyCheatsheet/day10/13.webp",

      }, {
        title: "",
        subtitle: "Dynamic Route:",
        details: [
          "Capture parameters from the URL using :parameterName.",
          
        ],
        image: "/images/musicAcademyCheatsheet/day10/14.webp",

      }, {
        title: "",
        subtitle: "Chaining Routes",
        details: [
          "Use the same route path with different HTTP methods.",
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/15.webp",

      }, {
        title: "",
        subtitle: "Router Example (Organizing Routes)",
        details: [
          "Use express.Router to modularize routes for better maintainability.",
        
        ],
        image: "/images/musicAcademyCheatsheet/day10/16.webp",

      }, {
        title: "",
        subtitle: "Query Parameters",
        details: [
          "Use req.query to handle optional query strings like /search?name=John.",
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/17.webp",

      }, {
        title: "",
        subtitle: "Middleware in Routes",
        details: [
          "Apply middleware to specific routes.",
          
        ],
        image: "/images/musicAcademyCheatsheet/day10/18.webp",

      }, {
        title: "",
        subtitle: "404 Handling",
        details: [
          "Catch-all route for undefined endpoints.",
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/19.webp",

      }, {
        title: "Environment Variables",
        subtitle: "",
        details: [
          "Keep sensitive data (e.g., database URIs, API keys) out of your codebase.",
          "Use environment variables to store configuration values.",

        
        ],
        image: "/images/musicAcademyCheatsheet/day10/20.webp",

      }, {
        title: "",
        subtitle: "",
        details: [
          "Load .env variables using dotenv",

         
        ],
                image: "/images/musicAcademyCheatsheet/day10/20.webp",

      }, {
        title: "Authentication and Authorization",
        subtitle: "Authentication",
        details: [
          "Verifies the identity of a user (e.g., logging in with a username and password).",
          "Creates a session or token to maintain the user's authenticated state.",
          "Common methods include JWT (JSON Web Tokens) or sessions with cookies.",

         
        ],
      }, {
        title: "",
        subtitle: "Authorization",
        details: [
          "Ensures a user has the necessary permissions to access a resource.",
          "Roles and permissions are used to control access to specific routes or actions.",
          "Middleware functions can check the user's role before allowing access.",

          
        ],
      },
    ],
   },
   {
    taskId: "day15",
    content: [
      {
        title: "Backend Development Basics",
        subtitle: "Node.js",
        details: [
          "Node.js is a JavaScript runtime that executes code outside of the browser, typically used for building server-side applications.",
          "Non-blocking I/O: Handles multiple requests simultaneously without waiting for one to complete.",
          "Event-driven: Executes operations asynchronously using event loops.",
        ],
      },
         {
        title: "",
        subtitle: "Express.js",
        details: [
          "A minimal and flexible framework for Node.js, used to build web applications and APIs.",
          "Middleware: Functions that run during the request/response cycle.",
          "Routing: Maps URLs to functions (e.g., /users to a function that fetches user data).",
          "Error handling: Built-in support for managing errors.",
        ],
        image: "/images/musicAcademyCheatsheet/day10/1.webp"

      },
         {
        title: "RESTful API Design",
        subtitle: "What is REST?",
        details: [
          "REST (Representational State Transfer) is an architectural style for designing web services.",
          "RESTful APIs allow interaction with resources (like users, posts) using standard HTTP methods.",
          "Resources are identified by URIs (e.g., /users for a collection of users, /users/:id for a single user).",
        ],
      },
         {
        title: "",
        subtitle: "Key Principles of REST",
        details: [
          "Statelessness: Each request from the client contains all the information needed to process the request (no reliance on stored session data on the server).",
          "Resource-Based: Everything is a resource, and each resource is identified by a unique URI.",
          "HTTP Methods: Specific operations (CRUD) are mapped to HTTP methods: GET: Retrieve data, POST: Create data, PUT: Update data, DELETE: Remove data.",
          "Uniform Interface: Consistent and predictable structure (e.g., /users always refers to the users resource).",
       
        ],
      },
         {
        title: "",
        subtitle: "RESTful API Example",
        details: [
          "Scenario: You have a users resource, and you need to perform CRUD operations.",
          
        ],
        image: "/images/musicAcademyCheatsheet/day10/2.webp",
      },
         {
        title: "",
        subtitle: "Example with Express.js",
        details: [
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/3.webp",

      },
      {
        title: "Database Integration",
        subtitle: "MongoDB",
        details: [
          "A NoSQL database that stores data in collections of documents (similar to JSON objects).",
          "Schema-less: Flexible structure, no predefined schema needed.",
          "Scalable: Ideal for applications with large data volumes.",
        ],
      },
         {
        title: "",
        subtitle: "Mongoose",
        details: [
          "An ODM (Object Data Modeling) library for MongoDB.",
          "Defines schemas and provides a way to interact with MongoDB using JavaScript objects.",
        ],
        image: "/images/musicAcademyCheatsheet/day10/4.webp",

      },
         {
        title: "Data Validation and Modeling",
        subtitle: "Why Validation Matters",
        details: [
          "Ensures data integrity.",
          "Prevents invalid data from being stored in the database.",
          "Improves application security.",
        
        ],

      },
         {
        title: "",
        subtitle: "Mongoose Validation Example",
        details: [
        
        ],
        image: "/images/musicAcademyCheatsheet/day10/5.webp",

      },
         {
        title: "Asynchronous Programming",
        subtitle: "What is Asynchronous Programming?",
        details: [
          "A programming paradigm where tasks run independently of the main application flow.",
          "Helps handle time-consuming operations (e.g., database queries) without blocking the application.",
          "A Promise represents the eventual result of an asynchronous operation.",
        ],
        image: "/images/musicAcademyCheatsheet/day10/6.webp",

      },
         {
        title: "",
        subtitle: "Async/Await Example:",
        details: [
        
        ],
        image: "/images/musicAcademyCheatsheet/day10/7.webp",

      },
      {
        title: "Error Handling",
        subtitle: "Why It's Important",
        details: [
          "Improves user experience by providing meaningful error messages.",
          "Prevents crashes from unhandled errors.",
        ],
      },
         {
        title: "",
        subtitle: "Using Try-Catch:",
        details: [
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/8.webp",

      },
         {
        title: "Middleware in Express",
        subtitle: "What is Middleware?",
        details: [
          "Middleware is a function in Express.js that has access to the request (req), response (res), and the next middleware in the stack.",
          "It can modify the request and response objects, end the request-response cycle, call the next middleware, or trigger an error.",

          
        ],
      },
         {
        title: "",
        subtitle: "Types of Middleware",
        details: [
          "express.json(): Parses incoming JSON payloads.",
          "express.static(): Serves static files like images or CSS.",
          "'cors' for handling cross-origin requests, helmet for securing HTTP headers.",
          "Your own functions for logging, validation, or authentication.",
        ],
        image: "/images/cheatsheetImages/task/.webp"
      },
         {
        title: "",
        subtitle: "Middleware Flow Example:",
        details: [
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/9.webp",

      },
      {
        title: "",
        subtitle: "Practical Middleware Use Cases:",
        details: [
          "Log every request to the console.",
          
        ],
        image: "/images/musicAcademyCheatsheet/day10/10.webp",

      }, {
        title: "",
        subtitle: "",
        details: [
          "Catch errors and send a generic error message.",
          
        ],
        image: "/images/musicAcademyCheatsheet/day10/11.webp",

      }, {
        title: "",
        subtitle: "",
        details: [
          "Verify if the user is logged in.",
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/12.webp",

      }, {
        title: "HTTP Routing",
        subtitle: "What is Routing?",
        details: [
          "Routing in Express defines how the server responds to specific client requests.",
          "Routes are matched based on the request method and URL path.",
          "Each route can have multiple handlers (middleware functions).",

          
        ],
        
      }, {
        title: "",
        subtitle: "Each route is associated with:",
        details: [
          "HTTP Method: Defines the operation (GET, POST, etc.).",
          "Path/Endpoint: The URL or part of it (e.g., /users).",
          "Handler Function: The logic to execute when the route is matched.",
        ],
      }, {
        title: "",
        subtitle: "Basic Route:",
        details: [
        
        ],
        image: "/images/musicAcademyCheatsheet/day10/13.webp",

      }, {
        title: "",
        subtitle: "Dynamic Route:",
        details: [
          "Capture parameters from the URL using :parameterName.",
          
        ],
        image: "/images/musicAcademyCheatsheet/day10/14.webp",

      }, {
        title: "",
        subtitle: "Chaining Routes",
        details: [
          "Use the same route path with different HTTP methods.",
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/15.webp",

      }, {
        title: "",
        subtitle: "Router Example (Organizing Routes)",
        details: [
          "Use express.Router to modularize routes for better maintainability.",
        
        ],
        image: "/images/musicAcademyCheatsheet/day10/16.webp",

      }, {
        title: "",
        subtitle: "Query Parameters",
        details: [
          "Use req.query to handle optional query strings like /search?name=John.",
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/17.webp",

      }, {
        title: "",
        subtitle: "Middleware in Routes",
        details: [
          "Apply middleware to specific routes.",
          
        ],
        image: "/images/musicAcademyCheatsheet/day10/18.webp",

      }, {
        title: "",
        subtitle: "404 Handling",
        details: [
          "Catch-all route for undefined endpoints.",
         
        ],
        image: "/images/musicAcademyCheatsheet/day10/19.webp",

      }, {
        title: "Environment Variables",
        subtitle: "",
        details: [
          "Keep sensitive data (e.g., database URIs, API keys) out of your codebase.",
          "Use environment variables to store configuration values.",

        
        ],
        image: "/images/musicAcademyCheatsheet/day10/20.webp",

      }, {
        title: "",
        subtitle: "",
        details: [
          "Load .env variables using dotenv",

         
        ],
                image: "/images/musicAcademyCheatsheet/day10/20.webp",

      }, {
        title: "Authentication and Authorization",
        subtitle: "Authentication",
        details: [
          "Verifies the identity of a user (e.g., logging in with a username and password).",
          "Creates a session or token to maintain the user's authenticated state.",
          "Common methods include JWT (JSON Web Tokens) or sessions with cookies.",

         
        ],
      }, {
        title: "",
        subtitle: "Authorization",
        details: [
          "Ensures a user has the necessary permissions to access a resource.",
          "Roles and permissions are used to control access to specific routes or actions.",
          "Middleware functions can check the user's role before allowing access.",

          
        ],
      },
    ],
   },
   {
    taskId: "day16",
    content: [
      {
        title: "Authentication vs. Authorization",
        subtitle: "Authentication",
        details: [
          "The process of verifying a user's identity (e.g., through email and password). It answers the question, 'Who are you?'",
        
        ],
        
      },
         {
        title: "",
        subtitle: "Authorization",
        details: [
          " The process of determining what an authenticated user is allowed to do. It answers the question, 'What are you allowed to do?'",
        
        ],
      },
         {
        title: "",
        subtitle: "",
        details: [
          "In this API, authentication is handled during login (via JWT), while authorization is managed by checking the user's role (e.g., admin, user) in protected routes.",
         
        ],
      },
         {
        title: "JSON Web Tokens (JWT)",
        subtitle: "A JWT is a compact, URL-safe token that consists of three parts:",
        details: [
          "A JWT typically looks like this:"
        ],
        image: "/images/musicAcademyCheatsheet/day16/1.webp",

      },
         {
        title: "",
        subtitle: "Header",
        details: [
          "Contains metadata about the token, such as the signing algorithm.",
         
        ],
        image: "/images/musicAcademyCheatsheet/day16/2.webp",

      },
         {
        title: "",
        subtitle: "Payload",
        details: [
          "Contains claims (e.g., user ID, role, expiration time).",
         
        ],
        image: "/images/musicAcademyCheatsheet/day16/3.webp",

      },
      {
        title: "",
        subtitle: "Signature",
        details: [
          "Ensures the token's integrity by combining the header, payload, and a secret key.",
          
        ],
      }, {
        title: "",
        subtitle: "",
        details: [
          "WTs are stateless, meaning the server does not need to store session data. Instead, the token itself contains all the necessary information for authentication.",
        
        ],
      }, {
        title: "Password Hashing",
        subtitle: "Why Hash Passwords?",
        details: [
          "Storing plain-text passwords is a security risk. If the database is compromised, attackers can easily access user credentials.",
          "Hashing converts passwords into a fixed-length string of characters, making it nearly impossible to reverse-engineer the original password.",
        ],
      }, {
        title: "",
        subtitle: "Salt",
        details: [
          "A random string added to the password before hashing. It ensures that even if two users have the same password, their hashed values will be different.",
         
        ],
      }, {
        title: "",
        subtitle: "bcrypt",
        details: [
          "A popular hashing algorithm that automatically handles salting and is computationally intensive, making it resistant to brute-force attacks.",
        
        ],
        image: "/images/musicAcademyCheatsheet/day16/4.webp",

      }, {
        title: "Middleware in Authentication",
        subtitle: "",
        details: [
          "Middleware functions act as gatekeepers for your routes.",
          
        ],
      }, {
        title: "",
        subtitle: "Protect Middleware",
        details: [
          "Verifies the JWT sent by the client.",
          "Decodes the token to extract the user's ID and role.",
          "Attaches the user's information to the req object, making it available to subsequent route handlers.",
          "If the token is invalid or expired, the middleware denies access and returns an error response."
        ],
      }, {
        title: "",
        subtitle: "",
        details: [
          "Middleware ensures that authentication logic is centralized and reusable across multiple routes.",
       
        ],
        image: "/images/musicAcademyCheatsheet/day16/5.webp",
      }, {
        title: "Role-Based Access Control (RBAC)",
        subtitle: "",
        details: [
          "RBAC is a method of restricting system access based on user roles.",
          "In this API, the role field in the user schema determines what actions a user can perform.",
         
        ],
      }, {
        title: "",
        subtitle: "For example:",
        details: [
          "An admin might have access to all routes.",
          "A user might only have access to their own profile or limited resources.",
        ],
      }, {
        title: "",
        subtitle: "",
        details: [
          "Protected routes can use the user's role (from the JWT payload) to enforce these restrictions.",
         
        ],
        image: "/images/musicAcademyCheatsheet/day16/6.webp",

      }, {
        title: "Environment Variables and Security",
        subtitle: "Why Use Environment Variables?",
        details: [
          "They keep sensitive information (e.g., API keys, database credentials) out of the codebase.",
          "They allow configuration to vary between environments (e.g., development, production) without changing the code.",
        ],
      }, {
        title: "",
        subtitle: "Security Best Practices:",
        details: [
          "Never hardcode secrets in your code.",
          "Use a .env file for local development but ensure it is added to .gitignore to avoid exposing secrets in version control.",
          "In production, use secure methods (e.g., cloud provider secrets manager) to manage environment variables.",
        ],
        image: "/images/musicAcademyCheatsheet/day16/7.webp",
      }, {
        title: "Error Handling",
        subtitle: "Proper error handling ensures that the API responds gracefully to issues such as:",
        details: [
          "Invalid user input (e.g., missing fields, incorrect email format).",
          "Database errors (e.g., connection issues, duplicate entries).",
          "Authentication failures (e.g., invalid credentials, expired tokens).",
        ],
      }, {
        title: "",
        subtitle: "",
        details: [
          "Error responses should include a clear message and an appropriate HTTP status code (e.g., 400 for bad requests, 401 for unauthorized access).",
          
        ],
        image: "/images/musicAcademyCheatsheet/day16/8.webp",
      }, {
        title: "Scalability and Modularity",
        subtitle: "Scalability",
        details: [
          "The API should be designed to handle increasing numbers of users and requests. Techniques like caching, load balancing, and database optimization can help.",
          
        ],
      }, {
        title: "",
        subtitle: "Modularity",
        details: [
          "Breaking the application into smaller, reusable components (e.g., routes, middleware, models) makes the codebase easier to maintain and extend.",
          
        ],
      }, {
        title: "Statelessness in REST APIs",
        subtitle: "",
        details: [
          "REST APIs are stateless, meaning each request from the client must contain all the information needed to process it.",
          "JWTs align with this principle because they contain all the necessary authentication data, eliminating the need for server-side session storage.",
        ],
      }, {
        title: "Security Considerations",
        subtitle: "HTTPS",
        details: [
          "Always use HTTPS to encrypt data transmitted between the client and server.",
          
        ],
        image: "/images/musicAcademyCheatsheet/day16/9.webp",
      }, {
        title: "",
        subtitle: "Token Expiry",
        details: [
          "JWTs should have an expiration time (e.g., 1 hour) to limit their validity and reduce the risk of misuse.",
          
        ],
      }, {
        title: "",
        subtitle: "Password Policies",
        details: [
          "Enforce strong password requirements (e.g., minimum length, special characters) to enhance security.",
         
        ],
      }, {
        title: "",
        subtitle: "Rate Limiting",
        details: [
          "Prevent brute-force attacks by limiting the number of login attempts from a single IP address.",
         
        ],
      }, {
        title: "Real-World Flow",
        subtitle: "Registration",
        details: [
          "User provides name, email, password, and role.",
          "Server hashes the password and saves the user to the database.",
          "Response: 201 Created with user details (excluding password).",
        ],
      }, {
        title: "",
        subtitle: "Login",
        details: [
          "User provides email and password.",
          "Server verifies credentials and generates a JWT.",
          "Response: 200 OK with the JWT.",
        ],
      }, {
        title: "",
        subtitle: "Protected Route",
        details: [
          "User includes the JWT in the Authorization header.",
          "Server verifies the token and grants access if valid.",
          "Response: 200 OK with the requested data.",
        ],
      }, {
        title: "Diagrams",
        subtitle: "Authentication Flow Diagram",
        details: [
        
        ],
        image: "/images/musicAcademyCheatsheet/day16/10.webp",
      },
    ],
  },
  {
    taskId: "day17",
    content: [
      {
        title: "Authentication vs. Authorization",
        subtitle: "Authentication",
        details: [
          "The process of verifying a user's identity (e.g., through email and password). It answers the question, 'Who are you?'",
        
        ],
        
      },
         {
        title: "",
        subtitle: "Authorization",
        details: [
          " The process of determining what an authenticated user is allowed to do. It answers the question, 'What are you allowed to do?'",
        
        ],
      },
         {
        title: "",
        subtitle: "",
        details: [
          "In this API, authentication is handled during login (via JWT), while authorization is managed by checking the user's role (e.g., admin, user) in protected routes.",
         
        ],
      },
         {
        title: "JSON Web Tokens (JWT)",
        subtitle: "A JWT is a compact, URL-safe token that consists of three parts:",
        details: [
          "A JWT typically looks like this:"
        ],
        image: "/images/musicAcademyCheatsheet/day16/1.webp",

      },
         {
        title: "",
        subtitle: "Header",
        details: [
          "Contains metadata about the token, such as the signing algorithm.",
         
        ],
        image: "/images/musicAcademyCheatsheet/day16/2.webp",

      },
         {
        title: "",
        subtitle: "Payload",
        details: [
          "Contains claims (e.g., user ID, role, expiration time).",
         
        ],
        image: "/images/musicAcademyCheatsheet/day16/3.webp",

      },
      {
        title: "",
        subtitle: "Signature",
        details: [
          "Ensures the token's integrity by combining the header, payload, and a secret key.",
          
        ],
      }, {
        title: "",
        subtitle: "",
        details: [
          "WTs are stateless, meaning the server does not need to store session data. Instead, the token itself contains all the necessary information for authentication.",
        
        ],
      }, {
        title: "Password Hashing",
        subtitle: "Why Hash Passwords?",
        details: [
          "Storing plain-text passwords is a security risk. If the database is compromised, attackers can easily access user credentials.",
          "Hashing converts passwords into a fixed-length string of characters, making it nearly impossible to reverse-engineer the original password.",
        ],
      }, {
        title: "",
        subtitle: "Salt",
        details: [
          "A random string added to the password before hashing. It ensures that even if two users have the same password, their hashed values will be different.",
         
        ],
      }, {
        title: "",
        subtitle: "bcrypt",
        details: [
          "A popular hashing algorithm that automatically handles salting and is computationally intensive, making it resistant to brute-force attacks.",
        
        ],
        image: "/images/musicAcademyCheatsheet/day16/4.webp",

      }, {
        title: "Middleware in Authentication",
        subtitle: "",
        details: [
          "Middleware functions act as gatekeepers for your routes.",
          
        ],
      }, {
        title: "",
        subtitle: "Protect Middleware",
        details: [
          "Verifies the JWT sent by the client.",
          "Decodes the token to extract the user's ID and role.",
          "Attaches the user's information to the req object, making it available to subsequent route handlers.",
          "If the token is invalid or expired, the middleware denies access and returns an error response."
        ],
      }, {
        title: "",
        subtitle: "",
        details: [
          "Middleware ensures that authentication logic is centralized and reusable across multiple routes.",
       
        ],
        image: "/images/musicAcademyCheatsheet/day16/5.webp",
      }, {
        title: "Role-Based Access Control (RBAC)",
        subtitle: "",
        details: [
          "RBAC is a method of restricting system access based on user roles.",
          "In this API, the role field in the user schema determines what actions a user can perform.",
         
        ],
      }, {
        title: "",
        subtitle: "For example:",
        details: [
          "An admin might have access to all routes.",
          "A user might only have access to their own profile or limited resources.",
        ],
      }, {
        title: "",
        subtitle: "",
        details: [
          "Protected routes can use the user's role (from the JWT payload) to enforce these restrictions.",
         
        ],
        image: "/images/musicAcademyCheatsheet/day16/6.webp",

      }, {
        title: "Environment Variables and Security",
        subtitle: "Why Use Environment Variables?",
        details: [
          "They keep sensitive information (e.g., API keys, database credentials) out of the codebase.",
          "They allow configuration to vary between environments (e.g., development, production) without changing the code.",
        ],
      }, {
        title: "",
        subtitle: "Security Best Practices:",
        details: [
          "Never hardcode secrets in your code.",
          "Use a .env file for local development but ensure it is added to .gitignore to avoid exposing secrets in version control.",
          "In production, use secure methods (e.g., cloud provider secrets manager) to manage environment variables.",
        ],
        image: "/images/musicAcademyCheatsheet/day16/7.webp",
      }, {
        title: "Error Handling",
        subtitle: "Proper error handling ensures that the API responds gracefully to issues such as:",
        details: [
          "Invalid user input (e.g., missing fields, incorrect email format).",
          "Database errors (e.g., connection issues, duplicate entries).",
          "Authentication failures (e.g., invalid credentials, expired tokens).",
        ],
      }, {
        title: "",
        subtitle: "",
        details: [
          "Error responses should include a clear message and an appropriate HTTP status code (e.g., 400 for bad requests, 401 for unauthorized access).",
          
        ],
        image: "/images/musicAcademyCheatsheet/day16/8.webp",
      }, {
        title: "Scalability and Modularity",
        subtitle: "Scalability",
        details: [
          "The API should be designed to handle increasing numbers of users and requests. Techniques like caching, load balancing, and database optimization can help.",
          
        ],
      }, {
        title: "",
        subtitle: "Modularity",
        details: [
          "Breaking the application into smaller, reusable components (e.g., routes, middleware, models) makes the codebase easier to maintain and extend.",
          
        ],
      }, {
        title: "Statelessness in REST APIs",
        subtitle: "",
        details: [
          "REST APIs are stateless, meaning each request from the client must contain all the information needed to process it.",
          "JWTs align with this principle because they contain all the necessary authentication data, eliminating the need for server-side session storage.",
        ],
      }, {
        title: "Security Considerations",
        subtitle: "HTTPS",
        details: [
          "Always use HTTPS to encrypt data transmitted between the client and server.",
          
        ],
        image: "/images/musicAcademyCheatsheet/day16/9.webp",
      }, {
        title: "",
        subtitle: "Token Expiry",
        details: [
          "JWTs should have an expiration time (e.g., 1 hour) to limit their validity and reduce the risk of misuse.",
          
        ],
      }, {
        title: "",
        subtitle: "Password Policies",
        details: [
          "Enforce strong password requirements (e.g., minimum length, special characters) to enhance security.",
         
        ],
      }, {
        title: "",
        subtitle: "Rate Limiting",
        details: [
          "Prevent brute-force attacks by limiting the number of login attempts from a single IP address.",
         
        ],
      }, {
        title: "Real-World Flow",
        subtitle: "Registration",
        details: [
          "User provides name, email, password, and role.",
          "Server hashes the password and saves the user to the database.",
          "Response: 201 Created with user details (excluding password).",
        ],
      }, {
        title: "",
        subtitle: "Login",
        details: [
          "User provides email and password.",
          "Server verifies credentials and generates a JWT.",
          "Response: 200 OK with the JWT.",
        ],
      }, {
        title: "",
        subtitle: "Protected Route",
        details: [
          "User includes the JWT in the Authorization header.",
          "Server verifies the token and grants access if valid.",
          "Response: 200 OK with the requested data.",
        ],
      }, {
        title: "Diagrams",
        subtitle: "Authentication Flow Diagram",
        details: [
        
        ],
        image: "/images/musicAcademyCheatsheet/day16/10.webp",
      },
    ],
  },
  {
    taskId: "day19",
    content: [
      {
        title: "User Authentication Basics",
        subtitle: "What is Authentication?",
        details: [
          "Authentication is the process of verifying a user's identity (e.g., through login and password). It ensures that only authorized users can access certain parts of an application.",
        ],
      },
         {
        title: "",
        subtitle: "Why is it Important?",
        details: [
          "Authentication protects sensitive data and ensures that only legitimate users can perform actions like accessing profiles, making purchases, or posting content.",
          
        ],
      },
      {
        title: "Persistent Login with localStorage",
        subtitle: "What is Persistent Login?",
        details: [
          "Persistent login allows users to stay logged in even after refreshing the page or closing the browser. This is achieved by storing a token (e.g., JWT) in the browser's localStorage.",

        ],
      },
         {
        title: "",
        subtitle: "How Does It Work?",
        details: [
          "When a user logs in, the server sends a token, which is stored in localStorage. On page reload, the app checks for this token and automatically logs the user in by fetching their data from the server.",
          
        ],
      },
         {
        title: "Protected Routes",
        subtitle: "What are Protected Routes?",
        details: [
          "Protected routes are pages or components that only authenticated users can access. If a user is not logged in, they are redirected to a login page.",
          
        ],
      },
         {
        title: "",
        subtitle: "How to Implement?",
        details: [
          "Use conditional rendering in React Router.",
          
        ],
      }, {
        title: "Role of useEffect in Authentication",
        subtitle: "What is useEffect?",
        details: [
          "useEffect is a React hook that runs side effects (e.g., fetching data) when the component mounts or when specific dependencies change.",
       
        ],
      },
         {
        title: "",
        subtitle: "How is it Used Here?",
        details: [
          "On app load, useEffect checks for a token in localStorage. If a token exists, it fetches the user's data from the server to log them in automatically.",
          
        ],
      },
         {
        title: "Handling User Registration and Login",
        subtitle: "Registration",
        details: [
          "When a user registers, their details (name, email, password, role) are sent to the server. The server creates a new user and returns a token for authentication.",
          
        ],
      },
         {
        title: "",
        subtitle: "Login",
        details: [
          "When a user logs in, their credentials (email and password) are sent to the server. If valid, the server returns a token, which is stored in localStorage.",
         
        ],
      },
      {
        title: "Logout Functionality",
        subtitle: "What Happens During Logout?",
        details: [
          "Logout clears the user's session by removing the token from localStorage and resetting the user state to null.",
         
        ],
      },
         {
        title: "",
        subtitle: "Why Clear localStorage?",
        details: [
          "Clearing localStorage ensures that the user is fully logged out and cannot access protected routes without re-authenticating.",
         
        ],
      },
         {
        title: "Profile Setup",
        subtitle: "What is Profile Setup?",
        details: [
          "After registration or login, users may need to complete their profile (e.g., add a profile picture, bio, or preferences). This is often done on a dedicated /profile-setup page.",
        ],
      },
         {
        title: "",
        subtitle: "How to Implement?",
        details: [
          "Create a ProfileSetup component that is only accessible to logged-in users. Pass the user data as a prop to pre-fill or customize the form.",
          
        ],
      },
         {
        title: "Error Handling",
        subtitle: "Why is Error Handling Important?",
        details: [
          "Errors (e.g., invalid credentials, server issues) can disrupt the user experience. Proper error handling ensures users receive clear feedback and the app remains stable.",
          
        ],
      },
         {
        title: "",
        subtitle: "How to Handle Errors?",
        details: [
          "Use a state variable (e.g., error) to store error messages. Display these messages conditionally in the UI to inform the user.",
          
        ],
      },
      {
        title: "Security Considerations",
        subtitle: "Token Security",
        details: [
          "Always use HTTPS to encrypt data sent between the client and server. Avoid storing sensitive data (e.g., passwords) in localStorage.",
         
        ],
      },
         {
        title: "",
        subtitle: "Input Validation",
        details: [
          "Validate user inputs (e.g., email format, password strength) on both the client and server to prevent invalid or malicious data.",
         
        ],
      },
      {
        title: "Testing Your Authentication System",
        subtitle: "What to Test?",
        details: [
          "Login and registration functionality.",
          "Persistent login after page refresh.",
          "Access to protected routes for logged-in and non-logged-in users.",
          "Logout functionality.",
        ],
      },  {
        title: "",
        subtitle: "Tools to Use",
        details: [
          "Manual testing in the browser.",
          "Automated testing with tools like Jest and React Testing Library.",
        ],
      },  {
        title: "Key Takeaways",
        subtitle: "",
        details: [
          "Authentication is essential for securing user data and restricting access to certain parts of your app.",
          "Use localStorage to persist login sessions and useEffect to fetch user data on app load.",
          "Protect routes using conditional rendering in React Router.",
          "Handle errors gracefully to improve user experience.",
          "Always prioritize security by validating inputs and using HTTPS.",
        ],
      },  
    ],
  },
  {
    taskId: "day20",
    content: [
   
         {
        title: "The Purpose of the Component",
        subtitle: "",
        details: [
          "The ProfileSetup component is designed to allow users to update their profile information dynamically based on their role (Teacher or Student).",
         
        ],
      },
         {
        title: "",
        subtitle: " It's a form-based component that handles:",
        details: [
          "Common Fields: Information applicable to all users (e.g., name, contact details).",
          "Role-Specific Fields: Custom data for Teachers (e.g., expertise, availability) and Students (e.g., enrolled lessons).",
        ],
      },
         {
        title: "",
        subtitle: "",
        details: [
          "This component is a great example of how to build dynamic, role-based UIs in React, which is a common requirement in real-world applications.",
        
        ],
      },
         {
        title: "Core Concepts Behind the Component",
        subtitle: "State Management in React",
        details: [
          "Why State?: State is used to store and manage the form data dynamically as the user interacts with the inputs.",
          "Single Source of Truth: Instead of managing multiple state variables, we use a single state object (formData) to store all form data. This makes it easier to manage and update nested fields (e.g., teacherDetails).",
          "Nested State: For role-specific data (e.g., teacherDetails.availability), we use nested objects and arrays. This requires careful state updates to avoid overwriting existing data.",
        ],
      },
      {
        title: "",
        subtitle: "Conditional Rendering",
        details: [
          "Role-Based UI: The component displays different fields based on the user's role (Teacher or Student). This is achieved using conditional rendering (if statements or ternary operators).",
          "Dynamic Forms: For fields like availability, the form can grow or shrink based on user input. This is handled by dynamically adding or removing entries from the state.",
        ],
      },     {
        title: "",
        subtitle: "Event Handling",
        details: [
          "onChange Events: Every input field in the form has an onChange handler to update the state as the user types.",
          "Parsing Inputs: For fields like expertise (comma-separated values) and availability (day and time slots), we parse the input strings into arrays or objects for easier storage and processing.",
        ],
      },     {
        title: "",
        subtitle: "Form Submission and API Integration",
        details: [
          "Preventing Default Behavior: When the form is submitted, we use e.preventDefault() to stop the page from reloading.",
          "Sending Data to the Backend: The updated form data is sent to the server using a PUT request. This involves: adding an authentication token to the request headers. Handling the server's response (success or error) and providing feedback to the user.",
          
        ],
      },     {
        title: "",
        subtitle: "Validation and Error Handling",
        details: [
          "Frontend Validation: Before submitting the form, we ensure required fields are filled and inputs are in the correct format (e.g., valid URLs for profile pictures).",
          "Backend Error Handling: If the server returns an error (e.g., invalid data), we display a user-friendly message.",
        ],
      },     {
        title: "Why PropTypes Are Important",
        subtitle: "",
        details: [
          "What Are PropTypes?: PropTypes are a way to validate the data passed to a component via props. They ensure that the component receives the correct data types and structure.",
          
        ],
      },     {
        title: "",
        subtitle: "Why Use Them?",
        details: [
          "Error Prevention: If the user prop doesn't match the expected structure, React will log a warning in the console.",
          "Self-Documenting Code: PropTypes act as documentation, making it clear what data the component expects.",
          "Easier Debugging: Clear error messages help identify issues with props early in development.",
        ],
      },     {
        title: "Real-World Applications",
        subtitle: "",
        details: [
          "Freelancing Platforms: Similar forms are used on platforms like Freelancer.com, where users (e.g., freelancers or clients) have role-specific profiles.",
          "E-Learning Platforms: Teachers and students often have different profile requirements, making role-based forms essential.",
          "Job Portals: Job seekers and employers have different profile fields, requiring dynamic form handling.",
        ],
      },     {
        title: "Best Practices for Building Dynamic Forms",
        subtitle: "Keep State Organized",
        details: [
          "Use a single state object for all form data.",
          "Use nested objects for role-specific fields to keep the state clean and manageable.",
        ],
      },     {
        title: "",
        subtitle: "Use Conditional Rendering Wisely",
        details: [
          "Only render fields that are relevant to the user's role.",
          "Avoid unnecessary complexity by keeping the logic simple and readable.",
        ],
      },     {
        title: "",
        subtitle: "Validate Inputs",
        details: [
          "Use frontend validation to ensure data is correct before sending it to the server.",
          "Provide clear error messages to guide the user.",
        ],
      },     {
        title: "",
        subtitle: "Optimize Performance",
        details: [
          "Avoid unnecessary re-renders by using useState and useEffect effectively.",
          "Memoize expensive calculations or functions if needed.",
        ],
      },     {
        title: "",
        subtitle: "Test Thoroughly",
        details: [
          "Test common fields, role-specific fields, and edge cases (e.g., empty inputs, invalid data).",
          "Use debugging tools to identify and fix issues.",
        ],
      },    
    ],
  },
  {
    taskId: "day21",
    content: [
      {
        title: "What is Authentication and Why Does It Matter?",
        subtitle: "",
        details: [
          "Authentication is the process of verifying a user's identity. It's the gateway that allows users to access protected resources, such as their profile, settings, or personalized content. Without authentication, applications would be open to misuse, data breaches, and unauthorized access.",
        ],
      },  {
        title: "",
        subtitle: "Why is it important?",
        details: [
          "Security: Protects sensitive user data from unauthorized access.",
          "Personalization: Enables users to have a customized experience based on their preferences and actions.",
          "Trust: Builds user confidence in the application, knowing their information is safe.",
          "Compliance: Ensures the application meets legal and regulatory requirements for data protection.",
        ],
      },  {
        title: "Core Concepts of Authentication",
        subtitle: "",
        details: [
          "To understand authentication, let's break it down into its core components:",
         
        ],
      },  {
        title: "",
        subtitle: "User Registration",
        details: [
          "The process of creating a new account.",
          "Involves collecting user information (e.g., name, email, password) and storing it securely in a database.",
        ],
      },  {
        title: "",
        subtitle: "User Login",
        details: [
          "The process of verifying a user's credentials (e.g., email and password).",
          "If successful, the server generates a JWT (JSON Web Token) to represent the user's session.",
        ],
      },  {
        title: "",
        subtitle: "Token-Based Authentication",
        details: [
          "JWTs are used to securely transmit user information between the client and server.",
          "The token is stored on the client side (e.g., in localStorage) and sent with every request to authenticate the user.",
        ],
      },  {
        title: "",
        subtitle: "Protected Routes",
        details: [
          "Routes that are only accessible to authenticated users.",
          "If a user tries to access a protected route without being logged in, they are redirected to the login page.",
        ],
      },  {
        title: "",
        subtitle: "Logout",
        details: [
          "The process of ending a user's session.",
          "Involves removing the token from localStorage and clearing the user's state in the application.",
        ],
      },  {
        title: "How Authentication Works in a React Application",
        subtitle: "",
        details: [
          "In a React application, authentication is typically implemented using the following workflow:",
       
        ],
      },  {
        title: "",
        subtitle: "User Registration",
        details: [
          "The user fills out a registration form.",
          "The data is sent to the backend, where it is validated and stored in the database.",
          "A JWT is generated and sent back to the client.",
        ],
      },  {
        title: "",
        subtitle: "User Login",
        details: [
          "The user enters their credentials (e.g., email and password).",
          "The backend verifies the credentials and generates a JWT.",
          "The token is stored in localStorage and used to authenticate future requests.",
        ],
      },  {
        title: "",
        subtitle: "Fetching User Data",
        details: [
          "On app load, the frontend checks for a stored token.",
          "If a token exists, the frontend sends a request to the backend to fetch the user's data.",
          "The user's data is stored in the application state and used to personalize the UI.",
        ],
      },  {
        title: "",
        subtitle: "Protected Routes",
        details: [
          "Routes like /profile or /dashboard are protected.",
          "If the user is not authenticated, they are redirected to the login page.",
        ],
      },  {
        title: "",
        subtitle: "Logout",
        details: [
          "The token is removed from localStorage.",
          "The user's state is cleared, and they are redirected to the home page or login page.",
        ],
      },  {
        title: "The Role of Error Handling in Authentication",
        subtitle: "",
        details: [
          "Error handling is a critical part of any authentication system. It ensures that the application can gracefully handle issues like:",
         
        ],
      },  {
        title: "",
        subtitle: "",
        details: [
          "Invalid credentials: Displaying a message like “Incorrect email or password.”",
          "Network errors: Showing a message like “Unable to connect to the server.”",
          "Expired tokens: Redirecting the user to the login page.",
        ],
      },  {
        title: "",
        subtitle: "",
        details: [
          "By handling errors effectively, you can provide a better user experience and prevent the application from crashing.",
       
        ],
      },  {
        title: "",
        subtitle: "Why This Matters for Your Project",
        details: [
          "In your project, AuthFlow Pro, you're building a professional-grade authentication system. This system will:",
          
        ],
      },  {
        title: "",
        subtitle: "",
        details: [
          "Secure user data: Protect sensitive information like passwords and personal details.",
          "Enhance user experience: Provide a seamless and personalized experience for logged-in users.",
          "Ensure scalability: Lay the foundation for future features like role-based access control (e.g., admin vs. regular user).",
          "Build trust: Show users that their data is safe and their privacy is respected.",
        ],
      },  {
        title: "The Bigger Picture",
        subtitle: "",
        details: [
          "Authentication is more than just a technical requirement—it's a way to build trust and engagement with your users. By implementing a robust authentication system, you're not only protecting your application but also creating a foundation for features like:",
        
        ],
      },  {
        title: "",
        subtitle: "",
        details: [
          "User profiles: Allow users to manage their information and preferences.",
          "Role-based access: Restrict certain features to specific user roles (e.g., admin, teacher, student).",
          "Analytics: Track user behavior to improve the application.",
        ],
      },  {
        title: "Conclusion",
        subtitle: "",
        details: [
          "Authentication is the backbone of modern web applications. It ensures security, enables personalization, and builds trust with users. By understanding the principles behind authentication and implementing them in your project, you're taking a crucial step toward building professional, scalable, and user-friendly applications. Keep these concepts in mind as you work on AuthFlow Pro, and you'll create a system that users can rely on! ",
         
        ],
      }, 
    ],
  },
  {
    taskId: "day22",
    content: [
      {
        title: "Deployment Tool: Render",
        subtitle: "",
        details: [
          "What it is: Render is a cloud platform for deploying backend services, static sites, and databases ( FREE ).",
          "Why use it: Render simplifies the deployment process by handling infrastructure, scaling, and SSL certificates. It's beginner-friendly and offers a free tier for small projects.",
        ],
      },
         {
        title: "",
        subtitle: "Key Features:",
        details: [
          "Automatic deployments from GitHub.",
          "Built-in support for environment variables.",
          "Custom domains and HTTPS.",
        ],
      },
         {
        title: "Deployment Tool: Netlify",
        subtitle: "",
        details: [
          "What it is: Netlify is a platform for deploying and hosting static websites and single-page applications (SPAs).",
          "Why use it: Netlify is optimized for modern web development workflows, offering continuous deployment, serverless functions, and a global CDN.",
        ],
      },
         {
        title: "",
        subtitle: "Key Features:",
        details: [
          "Automatic deployments from GitHub.",
          "Support for client-side routing with a _redirects file.",
          "Free tier for small projects.",
        ],
      },
         {
        title: "Environment Variables",
        subtitle: "",
        details: [
          "What they are: Environment variables are key-value pairs used to configure your application. They are typically stored in a .env file or directly in the deployment platform.",
          "Why use them: Environment variables keep sensitive data (e.g., API keys, database URLs) out of your codebase and allow you to configure your app for different environments (e.g., development, production).",
          "",
        ],
      },
      {
        title: "",
        subtitle: "Examples:",
        details: [
          "MONGO_URI: The connection string for your MongoDB database.",
          "JWT_SECRET: A secret key for signing JSON Web Tokens.",
          "REACT_APP_API_URL: The URL of your deployed backend.",
        ],
      },   {
        title: "Common Errors and Solutions",
        subtitle: "Error: Failed to load resource: net::ERR_CONNECTION_REFUSED",
        details: [
          "What it means: The frontend cannot connect to the backend.",
        
        ],
      },   {
        title: "",
        subtitle: "Causes:",
        details: [
          "The backend URL is incorrect (e.g., still pointing to localhost).",
          "The backend is not running or accessible.",
        ],
      },   {
        title: "",
        subtitle: "Solution:",
        details: [
          "Ensure the frontend uses the correct REACT_APP_API_URL ( not the hardcoded localhost URL ).",
          "Check the backend logs on Render for errors.",
        ],
      },   {
        title: "",
        subtitle: "Error: 404 Not Found on Page Refresh",
        details: [
          "What it means: Netlify cannot find the requested resource.",
          
        ],
      },   {
        title: "",
        subtitle: "Causes:",
        details: [
          "React Router handles routing on the client side, but Netlify expects server-side routes.",
         
        ],
      },   {
        title: "",
        subtitle: "Solution:",
        details: [
          "Add a _redirects file in the public folder with /* /index.html 200.",
       
        ],
      },   {
        title: "",
        subtitle: "Error: Cannot find package 'express'",
        details: [
          "What it means: Dependencies are not installed.",
         
        ],
      },   {
        title: "",
        subtitle: "Causes:",
        details: [
          "The node_modules folder is missing or dependencies are not listed in package.json.",
          
        ],
      },   {
        title: "",
        subtitle: "Solution:",
        details: [
          "Run npm install in the backend folder.",
          "Ensure all dependencies are listed in package.json.",
        ],
      },   {
        title: "",
        subtitle: "Error: Error connecting to MongoDB",
        details: [
          "What it means: The backend cannot connect to the database.",
         
        ],
      },   {
        title: "",
        subtitle: "Causes:",
        details: [
          "The MONGO_URI is incorrect or MongoDB Atlas is blocking the connection.",
         
        ],
      },   {
        title: "",
        subtitle: "Solution:",
        details: [
          "Verify the MONGO_URI in your .env file.",
          "Whitelist Render's IP address in MongoDB Atlas.",
        ],
      },   {
        title: "Best Practices",
        subtitle: "Frontend",
        details: [
          "Store the backend URL in REACT_APP_API_URL.",
          "Display user-friendly error messages for failed API calls.",
          "Use lazy loading for components.",
          "Minimize unnecessary re-renders with React.memo."
        ],
      },   {
        title: "",
        subtitle: "Backend",
        details: [
          "Check user input (e.g., email, password) before processing.",
          "Implement cors to allow frontend requests.",
          "Use authMiddleware to protect sensitive routes.",
          "Use HTTPS for all API calls.",
          "Store sensitive data (e.g., JWT) securely.",
         
        ],
      },   {
        title: "",
        subtitle: "Deployment",
        details: [
          "Ensure the app works on localhost before deploying.",
          "Check Render and Netlify logs for errors.",
          "Automate testing and deployment with GitHub Actions.",
        ],
      },  
    ],
  },
  {
    taskId: "day23",
    content: [
      {
        title: "Why Do We Need Database Seeding?",
        subtitle: "Database seeding is the process of populating a database with initial or sample data. It serves several key purposes:",
        details: [
        
        ],
      },
         {
        title: "",
        subtitle: "Development Efficiency:",
        details: [
          "Developers need a consistent dataset to build and test features. Without seeding, they'd have to manually enter data every time they set up a new environment, which is time-consuming and error-prone.",
          "Seeding ensures that everyone on the team works with the same data, reducing inconsistencies.",
        ],
      },
         {
        title: "",
        subtitle: "Testing and Debugging:",
        details: [
          "Automated tests require predictable data to verify functionality. Seeding provides a controlled dataset for unit tests, integration tests, and end-to-end tests.",
          "Debugging is easier when you can reproduce issues with the same data every time.",
        ],
      },
         {
        title: "",
        subtitle: "Testing and Debugging:",
        details: [
          "Automated tests require predictable data to verify functionality. Seeding provides a controlled dataset for unit tests, integration tests, and end-to-end tests.",
          "Debugging is easier when you can reproduce issues with the same data every time.",
        ],
      },
         {
        title: "",
        subtitle: "Demonstrations and Onboarding:",
        details: [
          "A pre-populated database allows you to showcase your application with realistic data during demos.",
          "New team members can quickly understand the data structure and relationships by exploring seeded data.",
        ],
      },
         {
        title: "",
        subtitle: "Data Relationships and Integrity:",
        details: [
          "Seeding helps you test and validate relationships between tables or collections (e.g., a User has many BlogPosts).",
          "It ensures that your database schema and constraints (e.g., foreign keys, unique indexes) are working as expected.",
        ],
      },
      {
        title: "Why Clear Existing Data Before Seeding?",
        subtitle: "If you don't clear existing data before seeding, you risk:",
        details: [
        
        ],
      },
      {
        title: "",
        subtitle: "Duplicate Records:",
        details: [
          "The same data might be inserted multiple times, leading to conflicts or unexpected behavior.",
         
        ],
      },  {
        title: "",
        subtitle: "Inconsistent State:",
        details: [
          "Existing data might not match the structure or relationships defined in your seed data, causing errors.",
        
        ],
      },  {
        title: "",
        subtitle: "Why Clearing is Better",
        details: [
       
        ],
      },  {
        title: "",
        subtitle: "Fresh Start:",
        details: [
          "Clearing data ensures that your database starts with a clean slate, free from outdated or irrelevant records.",
          
        ],
      },  {
        title: "",
        subtitle: "Predictable Results:",
        details: [
          "You can be confident that the seeded data is the only data in the database, making it easier to test and debug.",
        
        ],
      },  {
        title: "",
        subtitle: "Trade-Offs",
        details: [
       
        ],
      },  {
        title: "",
        subtitle: "Data Loss:",
        details: [
          "Clearing data deletes all existing records, which might not be desirable in a production environment.",
        
        ],
      },  {
        title: "",
        subtitle: "Solution:",
        details: [
          "Use conditional logic to skip clearing in production or use a separate database for development and testing.",
      
        ],
      },  {
        title: "Why Use Environment Variables for Database Connection?",
        subtitle: "The Problem with Hardcoding",
        details: [
          "Hardcoding sensitive information like database URIs in your codebase is a security risk. If your code is exposed (e.g., in a public repository), attackers can gain access to your database.",
        
        ],
      },  {
        title: "",
        subtitle: "Why Environment Variables are Better",
        details: [
        
        ],
      },  {
        title: "",
        subtitle: "Security:",
        details: [
          "Environment variables keep sensitive information out of your codebase.",
        
        ],
      },  {
        title: "",
        subtitle: "Flexibility:",
        details: [
          "You can easily switch between different databases (e.g., local, staging, production) without modifying your code.",
        
        ],
      },  {
        title: "",
        subtitle: "Best Practice:",
        details: [
          "Using environment variables aligns with the 12-factor app methodology, which recommends storing configuration in the environment.",
         
        ],
      },  {
        title: "",
        subtitle: "Trade-Offs",
        details: [
        
        ],
      },  {
        title: "",
        subtitle: "Complexity:",
        details: [
          "Managing environment variables can be tricky, especially in large teams or distributed systems.",
        
        ],
      },  {
        title: "",
        subtitle: "Solution:",
        details: [
          "Use tools like .env files (with dotenv) or secret management services (e.g., AWS Secrets Manager).",
       
        ],
      },  {
        title: "Why Define Data Models Before Seeding?",
        subtitle: "The Problem with Ad-Hoc Data",
        details: [
        
        ],
      }, {
        title: "",
        subtitle: "Without well-defined models, your seed data might:",
        details: [
          "Lack consistency (e.g., missing required fields).",
          "Fail to enforce relationships (e.g., a LessonSchedule without a teacherId).",
          "Violate database constraints (e.g., unique indexes, foreign keys).",
        ],
      }, {
        title: "",
        subtitle: "Why Data Models are Better",
        details: [
         
        ],
      }, {
        title: "",
        subtitle: "Structure:",
        details: [
          "Models define the shape of your data, ensuring that all required fields are present and valid.",
         
        ],
      }, {
        title: "",
        subtitle: "Relationships:",
        details: [
          "Models make it easy to establish and enforce relationships between collections or tables.",
         
        ],
      }, {
        title: "",
        subtitle: "Validation:",
        details: [
          "Models can include validation rules (e.g., email format, required fields) to ensure data integrity.",
         
        ],
      }, {
        title: "",
        subtitle: "Trade-Offs",
        details: [
          
        ],
      }, {
        title: "",
        subtitle: "Overhead:",
        details: [
          "Defining models requires upfront effort, which might feel unnecessary for small projects.",
         
        ],
      }, {
        title: "",
        subtitle: "Solution:",
        details: [
          "Use lightweight ORMs or schema-less databases for small projects, but always define models for larger applications.",
         
        ],
      }, {
        title: "Why Link Data Between Collections/Tables?",
        subtitle: "The Problem with Isolated Data",
        details: [
        
        ],
      }, {
        title: "",
        subtitle: "If you don't link data, your application won't reflect real-world relationships. For example:",
        details: [
          "A LessonSchedule without a teacher or student is meaningless.",
          "A BlogPost without an author lacks context.",
        ],
      }, {
        title: "",
        subtitle: "Why Linking is Better",
        details: [
       
        ],
      }, {
        title: "",
        subtitle: "Realism:",
        details: [
          "Linked data mirrors real-world scenarios, making your application more realistic and easier to test.",
      
        ],
      }, {
        title: "",
        subtitle: "Functionality:",
        details: [
          "Relationships enable features like fetching all lessons for a specific teacher or all blog posts by a specific author.",
         
        ],
      }, {
        title: "",
        subtitle: "Data Integrity:",
        details: [
          "Linking ensures that related records exist and are valid (e.g., a LessonSchedule cannot reference a non-existent User).",
        
        ],
      }, {
        title: "",
        subtitle: "Trade-Offs",
        details: [
        
        ],
      }, {
        title: "",
        subtitle: "Complexity:",
        details: [
          "Managing relationships adds complexity to your seed data and queries.",
          
        ],
      }, {
        title: "",
        subtitle: "Solution:",
        details: [
          "Use tools like Mongoose's populate() or SQL joins to simplify working with relationships.",
         
        ],
      }, {
        title: "Why Use Logging During Seeding?",
        subtitle: "The Problem with Silent Failures",
        details: [
       
        ],
      }, {
        title: "",
        subtitle: "Without logging, you might not know if:",
        details: [
          "The seeding process completed successfully.",
          "Certain records failed to insert due to validation errors.",
          "Relationships were correctly established.",
        ],
      }, {
        title: "",
        subtitle: "Why Logging is Better",
        details: [
      
        ],
      }, {
        title: "",
        subtitle: "Transparency:",
        details: [
          "Logging provides visibility into the seeding process, helping you identify and fix issues.",
         
        ],
      }, {
        title: "",
        subtitle: "Debugging:",
        details: [
          "Detailed logs make it easier to debug problems (e.g., why a specific record failed to insert).",
          
        ],
      }, {
        title: "",
        subtitle: "Verification:",
        details: [
          "Logs allow you to verify that the correct data was inserted and relationships were established.",
         
        ],
      }, {
        title: "",
        subtitle: "Trade-Offs",
        details: [
       
        ],
      }, {
        title: "",
        subtitle: "Noise:",
        details: [
          "Excessive logging can clutter the console and make it harder to spot important information.",
          
        ],
      }, {
        title: "",
        subtitle: "Solution:",
        details: [
          "Use different log levels (e.g., info, error) and only log essential details.",
         
        ],
      }, {
        title: "Why Avoid Hardcoding Sensitive Data?",
        subtitle: "Hardcoding sensitive data like passwords or API keys in your seed data is a security risk. If your code is exposed, attackers can misuse this information.",
        details: [
          
        ],
      }, {
        title: "",
        subtitle: "Why Environment Variables or Dummy Data are Better",
        details: [
       
        ],
      },{
        title: "",
        subtitle: "Security:",
        details: [
          "Sensitive information is kept out of your codebase.",
          
        ],
      },{
        title: "",
        subtitle: "Flexibility:",
        details: [
          "You can easily switch between different datasets (e.g., development, testing) without modifying your code.",
         
        ],
      },{
        title: "",
        subtitle: "Best Practice:",
        details: [
          "Using dummy data for development and testing aligns with security best practices.",
         
        ],
      },{
        title: "",
        subtitle: "Trade-Offs",
        details: [
        
        ],
      },{
        title: "",
        subtitle: "Convenience:",
        details: [
          "Hardcoding might feel easier in the short term, but it's not worth the security risks.",
          
        ],
      },{
        title: "",
        subtitle: "Solution:",
        details: [
          "Use tools like faker.js to generate realistic but fake data for development and testing.",
         
        ],
      },{
        title: "Why Automate and Document the Seeding Process?",
        subtitle: "Manual seeding is:",
        details: [
          "Time-consuming.",
          "Error-prone.",
          "Inconsistent across environments.",
        ],
      },{
        title: "",
        subtitle: "Why Automation and Documentation are Better",
        details: [
      
        ],
      },{
        title: "",
        subtitle: "Consistency:",
        details: [
          "Automated seeding ensures that everyone on the team works with the same data.",
         
        ],
      },{
        title: "",
        subtitle: "Efficiency:",
        details: [
          "Running a script is faster and more reliable than manually entering data.",
         
        ],
      },{
        title: "",
        subtitle: "Onboarding:",
        details: [
          "Documentation helps new team members understand how to set up and use the seeding component.",
         
        ],
      },{
        title: "",
        subtitle: "Trade-Offs",
        details: [
       
        ],
      },{
        title: "",
        subtitle: "Upfront Effort:",
        details: [
          "Writing scripts and documentation requires time and effort.",
       
        ],
      },{
        title: "",
        subtitle: "Solution:",
        details: [
          "Treat seeding as a core part of your development process, not an afterthought.",
      
        ],
      },{
        title: "Conclusion",
        subtitle: "",
        details: [
          "Building a database seeding component is more than just writing code—it's about understanding the why behind each decision and choosing the best approach for your application. By following these theoretical principles, you can create a seeding system that is secure, efficient, and scalable, while avoiding common pitfalls like hardcoding sensitive data or neglecting relationships.",
          "Remember, the goal of seeding is to make your life easier as a developer, so invest the time to do it right!",
        ],
      }
    ],
  },
  {
    taskId: "day24",
    content: [
      {
        title: "What Are Routes?",
        subtitle: "Definition",
        details: [
          "Routes are endpoints in a web application that handle specific HTTP requests (e.g., GET, POST, PUT, DELETE).",
          "They define how the server responds to client requests for specific URLs.",
        ],
      },
      {
        title: "",
        subtitle: "Purpose of Routes",
        details: [
          "Serve Data: Fetch data from the database (e.g., a list of teachers or blog posts).",
          "Perform Actions: Execute operations like creating, updating, or deleting resources.",
          "Structure the API: Define the API's structure and make it predictable for clients.",
        ],
      },
         {
        title: "",
        subtitle: "Why Are Routes Important?",
        details: [
          "They are the foundation of any web application.",
          "They enable communication between the client (frontend) and server (backend).",
          "They define the API's functionality and structure.",
        ],
      },
         {
        title: "Middleware: The Backbone of Express Applications",
        subtitle: "What Is Middleware?",
        details: [
          "Middleware are functions that execute during the request-response cycle.",
          "They sit between the incoming request and the final route handler.",
        ],
      },
         {
        title: "",
        subtitle: "How Middleware Works",
        details: [
          "Request Comes In: The server receives an HTTP request.",
          "Middleware Executes: Middleware functions process the request (e.g., check authentication, validate data).",
          "Route Handler Executes: The final route handler processes the request and sends a response.",
        ],
      },
         {
        title: "Types of Middleware",
        subtitle: "Authentication Middleware",
        details: [
          "Verifies if the user is logged in (e.g., by checking a JWT token).",
          "Example: protect middleware.",
        ],
      },{
        title: "",
        subtitle: "Authorization Middleware",
        details: [
          "Checks if the user has the required permissions or role (e.g., Admin).",
          "Example: admin middleware.",
        ],
      },
         {
        title: "",
        subtitle: "Validation Middleware",
        details: [
          "Ensures the request data is valid (e.g., checking if required fields are present).",
        
        ],
      },
         {
        title: "",
        subtitle: "Error-Handling Middleware",
        details: [
          "Catches and handles errors gracefully.",
        
        ],
      },
         {
        title: "",
        subtitle: "Why Use Middleware?",
        details: [
          "Modularity: Keeps code organized and reusable.",
          "Security: Ensures requests are authenticated and authorized before processing.",
          "Validation: Prevents invalid or malicious data from being processed.",
          "Error Handling: Catches errors and provides meaningful responses.",
        ],
      },
         {
        title: "Role-Based Access Control (RBAC)",
        subtitle: "What Is RBAC?",
        details: [
          "RBAC is a security model that restricts access to resources based on user roles.",
          "Example roles: Student, Teacher, Admin.",
        ],
      },{
        title: "",
        subtitle: "How RBAC Works",
        details: [
          "Assign Roles: Each user is assigned a role (e.g., Admin, Teacher).",
          "Protect Routes: Use middleware to check the user's role before allowing access to a route.",
          "Example: Only Admin users can create or delete users.",
          "Enforce Permissions: Ensure users can only perform actions relevant to their role.",
        ],
      },
         {
        title: "",
        subtitle: "Why Use RBAC?",
        details: [
          "Security: Prevents unauthorized access to sensitive data or actions.",
          "Scalability: Makes it easy to add new roles or permissions.",
          "Clarity: Clearly defines what each role can and cannot do.",
        ],
      },
         {
        title: "Securing Routes",
        subtitle: "Public Routes",
        details: [
          "Accessible to everyone.",
          "Example: Fetching blog posts or testimonials.",
          "Public Routes provide access to non-sensitive data.",
          "Public Routes allow unauthenticated users to interact with the application.",
        ],
      },
         {
        title: "",
        subtitle: "Protected Routes",
        details: [
          "Require authentication (e.g., a valid JWT token).",
          "Example: Updating a user profile or booking a lesson.",
          "Protected Routes ensure only authenticated users can access sensitive data or actions.",
          "Protected Routes prevent unauthorized access.",
          "",
        ],
      },
         {
        title: "",
        subtitle: "Admin-Only Routes",
        details: [
          "Require both authentication and admin privileges.",
          "Example: Creating or deleting users.",
          "Admin-Only Routes restrict sensitive actions to trusted users.",
          "Admin-Only Routes prevent unauthorized users from performing critical operations.",
        ],
      },{
        title: "",
        subtitle: "Best Practices for Securing Routes",
        details: [
          "Use HTTPS: Encrypt data in transit to prevent eavesdropping.",
          "Validate Input: Ensure request data is valid and sanitized.",
          "Limit Access: Use RBAC to restrict access to sensitive routes.",
          "Handle Errors Gracefully: Return meaningful error messages without exposing sensitive information.",
        ],
      },
         {
        title: "Error Handling",
        subtitle: "What Is Error Handling?",
        details: [
          "Error handling is the process of catching and responding to errors that occur during the execution of your application.",
         
        ],
      },
         {
        title: "",
        subtitle: "Why Is Error Handling Important?",
        details: [
          "Prevents the server from crashing.",
          "Provides meaningful feedback to the client.",
          "Improves the user experience.",
        ],
      },
         {
        title: "How to Handle Errors",
        subtitle: "Use try...catch Blocks",
        details: [
          "Wrap asynchronous code in try...catch to catch errors.",
        
        ],
      },
         {
        title: "",
        subtitle: "Centralized Error Handling",
        details: [
          "Use error-handling middleware to catch and process errors globally.",
         
        ],
      },
      {
        title: "",
        subtitle: "Return Meaningful Responses",
        details: [
          "nclude an error message and status code in the response.",
      
        ],
      },   {
        title: "Validation",
        subtitle: "What Is Validation?",
        details: [
          "Validation is the process of ensuring that the request data is valid and safe to process.",
   
        ],
      },   {
        title: "",
        subtitle: "Why Validate Data?",
        details: [
          "Prevents invalid or malicious data from being processed.",
          "Ensures data integrity and consistency.",
          "Improves security by preventing injection attacks.",
        ],
      },   {
        title: "How to Validate Data",
        subtitle: "Use Schema Validations",
        details: [
          "Define validation rules in your Mongoose schemas.",
          
        ],
      },   {
        title: "",
        subtitle: "Use Middleware",
        details: [
          "Add validation middleware to check request data before processing it.",
          "Example: Use a library like express-validator.",
        ],
      },   {
        title: "",
        subtitle: "Sanitize Input",
        details: [
          "Remove or escape harmful characters from user input.",
      
        ],
      },   {
        title: "One-Time Scripts",
        subtitle: "What Are One-Time Scripts?",
        details: [
          "Scripts that perform a specific task once (e.g., creating an initial admin user).",
          "Example: A createAdmin script to generate the first admin account.",
        ],
      },   {
        title: "",
        subtitle: "Why Use One-Time Scripts?",
        details: [
          "Automates repetitive or sensitive tasks.",
          "Ensures consistency and reduces human error.",
        ],
      },   {
        title: "",
        subtitle: "Best Practices for One-Time Scripts",
        details: [
          "Use Only Once: Run the script once and then disable or delete it.",
          "Never Commit to Git: Avoid exposing sensitive functionality in version control.",
          "Use Environment Variables: Store sensitive information (e.g., admin credentials) in environment variables.",
          "Disable in Production: Remove or disable the script after use in production.",
        ],
      },   {
        title: "Best Practices for Building Secure APIs",
        subtitle: "",
        details: [
          "Use HTTPS: Encrypt data in transit.",
          "Validate and Sanitize Input: Prevent injection attacks.",
          "Use RBAC: Restrict access based on user roles.",
          "Handle Errors Gracefully: Provide meaningful error messages.",
          "Use Environment Variables: Store sensitive information securely.",
          "Disable Debugging in Production: Avoid exposing sensitive information.",
          "Monitor Logs: Keep an eye on requests and errors.",],
      },  
    ],
  },
  {
    taskId: "day25",
    content: [
      {
        title: "From Tokens to Cookies: A Secure Transition for Modern Web Applications",
        subtitle: "",
        details: [
          "In modern web development, authentication is a critical component that ensures users can securely access their accounts and data. Traditionally, many applications have relied on token-based authentication, where a JSON Web Token (JWT) is stored in the browser's localStorage or sessionStorage and sent with each request via the Authorization header. However, as security standards evolve, cookie-based authentication has emerged as a more secure and user-friendly alternative.",
          "In this cheatsheet, we'll explore the key differences between token-based and cookie-based authentication, the steps to transition from one to the other, and why this change is essential for building secure and scalable web applications.",
          
        ],
      },
         {
        title: "Token-Based Authentication: The Old Way",
        subtitle: "Token Generation",
        details: [
          "When a user logs in, the backend generates a JWT and sends it to the frontend.",
          "The token typically contains the user's ID, role, and an expiration time.",
        ],
      },
         {
        title: "",
        subtitle: "Token Storage",
        details: [
          "The frontend stores the token in 'localStorage' or 'sessionStorage'.",
          
        ],
      },
         {
        title: "",
        subtitle: "Token Usage",
        details: [
          "For every authenticated request, the frontend includes the token in the Authorization header (e.g., Authorization: Bearer <token>).",
         
        ],
      },
         {
        title: "",
        subtitle: "Token Verification",
        details: [
          "The backend verifies the token's validity and extracts the user's information.",
       
        ],
      },
      {
        title: "",
        subtitle: "Pros:",
        details: [
          "Simple to implement.",
          "Works well with stateless APIs.",
        ],
      }, {
        title: "",
        subtitle: "Cons:",
        details: [
          "Security Risks: Tokens stored in localStorage are vulnerable to XSS (Cross-Site Scripting) attacks.",
          "Manual Management: The frontend must manually include the token in every request.",
          "Scalability Issues: Managing token expiration and renewal can become complex.",
        ],
      }, {
        title: "Cookie-Based Authentication: The New Way",
        subtitle: "Token Generation",
        details: [
          "When a user logs in, the backend generates a JWT and sets it in an HTTP-only cookie.",
          
        ],
      }, {
        title: "",
        subtitle: "Cookie Storage",
        details: [
          "The cookie is automatically stored in the browser and sent with every request to the backend.",
        
        ],
      }, {
        title: "",
        subtitle: "Cookie Usage",
        details: [
          "The frontend includes credentials: 'include' in fetch requests to ensure cookies are sent.",
       
        ],
      }, {
        title: "",
        subtitle: "Cookie Verification",
        details: [
          "The backend verifies the token from the cookie and extracts the user's information.",
        
        ],
      }, {
        title: "",
        subtitle: "Pros:",
        details: [
          "Enhanced Security: HTTP-only cookies are inaccessible to JavaScript, reducing the risk of XSS attacks.",
          "Automatic Handling: Cookies are automatically sent with every request, simplifying frontend logic.",
          "Built-In Expiry: Cookies can be configured with expiration times, simplifying token management.",
        ],
      }, {
        title: "",
        subtitle: "Cons:",
        details: [
          "Slightly more complex to implement (e.g., configuring CORS and cookie settings).",
          "Requires careful handling of sameSite and secure flags for cross-site and HTTPS compatibility.",
        ],
      }, {
        title: "Why Transition to Cookie-Based Authentication?",
        subtitle: "Improved Security",
        details: [
          "HTTP-only cookies are immune to XSS attacks, as they cannot be accessed via JavaScript.",
          "The secure flag ensures cookies are only sent over HTTPS, protecting against man-in-the-middle attacks.",
          "The sameSite flag prevents cookies from being sent in cross-site requests, mitigating CSRF (Cross-Site Request Forgery) attacks.",
        ],
      }, {
        title: "",
        subtitle: "Simplified Frontend Logic",
        details: [
          "The frontend no longer needs to manually manage tokens or include them in request headers.",
          "Cookies are automatically sent with every request, reducing boilerplate code.",
        ],
      }, {
        title: "",
        subtitle: "Better User Experience",
        details: [
          "Cookies can be configured to persist across sessions, allowing users to stay logged in longer.",
          "Token expiration and renewal are handled seamlessly by the backend.",
        ],
      }, {
        title: "Steps to Transition from Tokens to Cookies",
        subtitle: "Backend Changes:",
        
      }, {
        title: "",
        subtitle: "Set the Token in a Cookie",
        details: [
          "Use res.cookie() to set the token in an HTTP-only cookie with secure settings (httpOnly, secure, sameSite).",
        
        ],
      }, {
        title: "",
        subtitle: "Update Middleware",
        details: [
          "Modify your authentication middleware to check for the token in cookies instead of the Authorization header.",
       
        ],
      }, {
        title: "",
        subtitle: "Handle Logout",
        details: [
          "Implement a /logout endpoint that clears the token cookie.",
        
        ],
      }, {
        title: "",
        subtitle: "Configure CORS",
        details: [
          "Ensure your backend allows credentials (cookies) from the frontend by setting credentials: true in your CORS configuration.",
       
        ],
      }, {
        title: "",
        subtitle: "Frontend Changes",
       
      }, {
        title: "",
        subtitle: "Remove Token Storage",
        details: [
          "Stop storing tokens in localStorage or sessionStorage.",
        
        ],
      }, {
        title: "",
        subtitle: "Include Credentials in Requests",
        details: [
          "Add credentials: 'include' to all fetch requests that require authentication.",
          
        ],
      }, {
        title: "",
        subtitle: "Update Authentication Logic",
        details: [
          "Modify your login and registration flows to rely on cookies instead of tokens.",
         
        ],
      }, {
        title: "",
        subtitle: "Test Thoroughly",
        details: [
          "Verify that cookies are being set, sent, and cleared correctly.",
          "Test edge cases (e.g., expired tokens, invalid cookies) to ensure proper error handling.",
        ],
      }, {
        title: "Best Practices for Cookie-Based Authentication",
        subtitle: "Use HTTP-Only Cookies",
        details: [
          "Prevent client-side JavaScript from accessing the cookie.",
       
        ],
      }, {
        title: "",
        subtitle: "Enable Secure Cookies in Production",
        details: [
          "Ensure cookies are only sent over HTTPS by setting the secure flag.",
       
        ],
      }, {
        title: "",
        subtitle: "Set SameSite Attribute",
        details: [
          "Use sameSite: 'strict' or sameSite: 'lax' to prevent CSRF attacks.",
         
        ],
      }, {
        title: "",
        subtitle: "Monitor Cookie Expiry",
        details: [
          "Set reasonable expiration times for cookies to balance security and user convenience.",
          
        ],
      }, {
        title: "",
        subtitle: "Test Across Browsers",
        details: [
          "Ensure your implementation works consistently across different browsers and devices.",
       
        ],
      }, {
        title: "Conclusion",
        subtitle: "",
        details: [
          "Transitioning from token-based authentication to cookie-based authentication is a significant step toward building more secure and user-friendly web applications. By leveraging HTTP-only cookies, you can protect your users' data from common vulnerabilities like XSS and CSRF while simplifying frontend logic and improving the overall user experience.",
          "Whether you're building a new application or updating an existing one, following the steps and best practices outlined in this post will help you implement cookie-based authentication with confidence.",
        ],
      }, 
    ],
  },
  {
    taskId: "day26",
    content: [
      {
        title: "Building an Admin Dashboard with React: A Theoretical Guide",
        subtitle: "",
        details: [
          "In modern web development, Admin Dashboards are essential tools for managing and controlling the backend of applications. They provide a centralized interface for administrators to perform tasks such as managing users, content, and settings. In this guide, we'll explore the theoretical foundations of building an Admin Dashboard using React, focusing on state management, conditional rendering, and component composition. By the end, you'll have a clear understanding of the principles and best practices involved in creating a scalable and maintainable dashboard.",
      
        ],
      },
         {
        title: "The Role of an Admin Dashboard",
        subtitle: "",
        details: [
          "An Admin Dashboard serves as the control panel for an application, allowing administrators to:",
        
        ],
      },
         {
        title: "",
        subtitle: "",
        details: [
          "Manage Content: Create, update, and delete blog posts, products, or other content.",
          "Monitor Activity: View analytics, user activity, and system performance.",
          "Control Access: Restrict certain features to authorized users (e.g., admins).",
        ],
      },
         {
        title: "",
        subtitle: "",
        details: [
          "In this guide, we'll focus on building a Blog Management System within an Admin Dashboard, where admins can manage blog posts efficiently.",
       
        ],
      },
         {
        title: "Organizing Your Project: Folder Structure",
        subtitle: "",
        details: [
          "Before writing any code, it's crucial to organize your project in a way that promotes scalability and maintainability. Here's why folder structure matters:",
        
        ],
      },
      {
        title: "",
        subtitle: "Why Use a Dedicated admin Folder?",
        details: [
          "Separation of Concerns: By placing admin-related components in a separate folder (e.g., pages/admin), you keep your codebase organized and modular.",
          "Scalability: As your application grows, you can easily add more admin-related features (e.g., user management, analytics) without cluttering the main codebase.",
        ],
      },   {
        title: "",
        subtitle: "Best Practices for Folder Structure",
        details: [
          "Place the AdminDashboard component inside the admin folder.",
          "Create subfolders for related components (e.g., BlogManagement, UserManagement).",
          "Use an index.js file in each folder to simplify imports.",
        ],
      },   {
        title: "State Management with useState",
        subtitle: "",
        details: [
          "State management is at the core of any dynamic React application. In the context of an Admin Dashboard, state is used to track the active tab, user authentication, and other dynamic data.",
         
        ],
      },   {
        title: "",
        subtitle: "Why Use useState?",
        details: [
          "Simplicity: The useState hook is the simplest way to manage state in functional components.",
          "Reactivity: When the state changes, React automatically re-renders the component to reflect the new state.",
        ],
      },   {
        title: "",
        subtitle: "Example: Tracking the Active Tab",
        details: [
          "In the AdminDashboard component, we use useState to track the currently active tab (e.g., 'blogs'). This allows us to conditionally render the appropriate component based on the user's selection.",
      
        ],
      },   {
        title: "Conditional Rendering",
        subtitle: "",
        details: [
          "Conditional rendering is a powerful feature in React that allows you to display different components based on certain conditions. In an Admin Dashboard, this is particularly useful for:",
        
        ],
      },   {
        title: "",
        subtitle: "",
        details: [
          "Displaying the appropriate section (e.g., blog management, user management) based on the active tab.",
          "Restricting access to certain features based on the user's role.",
        ],
      },   {
        title: "",
        subtitle: "How It Works",
        details: [
          "Use a conditional statement (e.g., if or ternary operator) to check the value of a state variable (e.g., activeTab).",
          "Render the corresponding component if the condition is met.",
        ],
      },   {
        title: "",
        subtitle: "Example: Rendering the BlogManagement Component",
        details: [
          "If the activeTab state is 'blogs', the BlogManagement component is rendered. This ensures that only the relevant content is displayed, improving the user experience.",
     
        ],
      },   {
        title: "Component Composition",
        subtitle: "",
        details: [
          "Component composition is the practice of breaking down a complex UI into smaller, reusable components. In the context of an Admin Dashboard, this means:",
         
        ],
      },   {
        title: "",
        subtitle: "",
        details: [
          "Creating separate components for each section (e.g., BlogManagement, UserManagement).",
          "Combining these components in the AdminDashboard component to build the complete interface.",
        ],
      },   {
        title: "",
        subtitle: "Benefits of Component Composition",
        details: [
          "Reusability: Components like BlogManagement can be reused in other parts of the application.",
          "Maintainability: Smaller components are easier to test, debug, and update.",
          "Scalability: Adding new features (e.g., analytics) is as simple as creating a new component and adding it to the dashboard.",
        ],
      },   {
        title: "Tab Navigation",
        subtitle: "",
        details: [
          "Tab navigation is a common UI pattern in dashboards, allowing users to switch between different sections. Here's how it works in the AdminDashboard component:",
      
        ],
      },   {
        title: "",
        subtitle: "Implementing Tab Navigation",
        details: [
          "Use buttons to represent each tab (e.g., 'Blogs').",
          "Update the activeTab state when a button is clicked.",
          "Use inline styles to highlight the active tab (e.g., bold font weight).",
        ],
      },   {
        title: "",
        subtitle: "Why Use Inline Styles?",
        details: [
          "Dynamic Styling: Inline styles allow you to apply styles conditionally based on the state.",
          "Simplicity: For small, state-dependent styles, inline styles are often more convenient than CSS classes.",
        ],
      },   {
        title: "Role-Based Access Control",
        subtitle: "",
        details: [
          "In a real-world application, it's important to restrict access to the Admin Dashboard to authorized users (e.g., admins). This can be achieved using role-based access control (RBAC).",

        ],
      },   {
        title: "",
        subtitle: "How It Works",
        details: [
          "Store the user's role (e.g., 'Admin', 'User') in the state or context.",
          "Use a ProtectedRoute component to check the user's role before rendering admin-specific routes.",
          "Redirect unauthorized users to a login page or home page.",
        ],
      },   {
        title: "Best Practices for Building Admin Dashboards",
        subtitle: "",
        details: [
          "Here are some best practices to keep in mind when building an Admin Dashboard:",
      
        ],
      },   {
        title: "",
        subtitle: "",
        details: [
          "Modular Design: Break down the UI into smaller, reusable components.",
          "State Management: Use state effectively to manage dynamic data and user interactions.",
          "Conditional Rendering: Display content based on conditions (e.g., active tab, user role).",
          "Security: Implement role-based access control to protect sensitive features.",
          "Scalability: Design the dashboard with future features in mind.",
        ],
      },   {
        title: "Conclusion",
        subtitle: "",
        details: [
          "Building an Admin Dashboard with React involves a combination of state management, conditional rendering, and component composition. By organizing your project effectively and following best practices, you can create a scalable and maintainable dashboard that meets the needs of your application. Whether you're managing blog posts, users, or analytics, these principles will help you build a powerful and user-friendly interface.",
          "In the next part of this guide, we'll dive deeper into implementing role-based access control and connecting the dashboard to a backend API. Stay tuned!",
        ],
      },  
    ],
  },
  {
    taskId: "day27",
    content: [
      {
        title: "Side Effects with useEffect",
        subtitle: "What are Side Effects?",
        details: [
          "Side effects are operations that interact with the outside world, such as fetching data from an API, updating the DOM, or setting up subscriptions.",
          "In React, side effects are handled using the useEffect hook.",
        ],
      },
         {
        title: "",
        subtitle: "How Does useEffect Work?",
        details: [
          "The useEffect hook takes two arguments:",
          "A function that contains the side effect logic.",
          "A dependency array that specifies when the effect should run.",
          "In the example below, fetchBlogs runs whenever the refreshBlogs value changes.",

        ],
        image: "/images/cheatsheetImages/task27music/1.webp"
      },
         {
        title: "",
        subtitle: "Common Use Cases",
        details: [
          "Fetching data from an API when the component mounts.",
          "Updating the DOM in response to state or prop changes.",
          "Cleaning up resources (e.g., unsubscribing from events) when the component unmounts.",
        ],
      },
         {
        title: "",
        subtitle: "Key Notes",
        details: [
          "If the dependency array is empty ([]), the effect runs only once (on mount).",
          "If the dependency array includes variables, the effect runs whenever those variables change.",
          "Always clean up side effects (e.g., cancel API requests) to avoid memory leaks.",
        ],
      },
         {
        title: "Fetching Data from an API",
        subtitle: "What is an API?",
        details: [
          "An API (Application Programming Interface) is a set of rules that allows your application to communicate with a server.",
          "APIs are used to fetch data (e.g., blog posts) or send data (e.g., creating a new blog).",
        ],
      },
      {
        title: "",
        subtitle: "How to Fetch Data in React?",
        details: [
          "Use the fetch function or libraries like axios to make HTTP requests.",
          "Example:",
        ],
        image: "/images/cheatsheetImages/task27music/2.webp"
      },    {
        title: "",
        subtitle: "Key Points",
        details: [
          "Always handle errors using try-catch blocks.",
          "Use async/await for cleaner and more readable code.",
          "Include credentials (e.g., cookies) if the API requires authentication.",
        ],
      },    {
        title: "Handling Forms in React",
        subtitle: "What are Controlled Components?",
        details: [
          "Controlled components are form elements (e.g., inputs, textareas) whose values are controlled by React state.",
          "Example:",
        ],
        image: "/images/cheatsheetImages/task27music/3.webp"
      },    {
        title: "",
        subtitle: "How to Handle Form Submission?",
        details: [
          "Use the onSubmit event to handle form submission.",
          "Example:",
        ],
        image: "/images/cheatsheetImages/task27music/4.webp"
      },    {
        title: "",
        subtitle: "Key Points",
        details: [
          "Use FormData for handling file uploads and other form data.",
          "Always reset the form after successful submission.",
          "Validate inputs before submitting the form.",
        ],
      },    {
        title: "Props and Prop Drilling",
        subtitle: "What are Props?",
        details: [
          "Props (short for properties) are used to pass data from a parent component to a child component.",
          "Example:",
        ],
        image: "/images/cheatsheetImages/task27music/5.webp"
      },    {
        title: "",
        subtitle: "What is Prop Drilling?",
        details: [
          "Prop drilling occurs when props are passed through multiple levels of components.",
          "Example: BlogManagement passes refreshBlogs to BlogListTable.",
          "Example: If BlogListTable had a child component, it would pass refreshBlogs further down.",
        ],
      },    {
        title: "",
        subtitle: "Key Points",
        details: [
          "Props are read-only and cannot be modified by the child component.",
          "Prop drilling can make code harder to maintain. In larger applications, consider using state management libraries like Redux or Context API.",
        ],
      },    {
        title: "Event Handling",
        subtitle: "What are Events?",
        details: [
          "Events are actions triggered by the user, such as clicking a button, typing in an input, or submitting a form.",
          "In React, events are handled using event handlers.",
        ],
      },    {
        title: "",
        subtitle: "How to Handle Events?",
        details: [
          "Define event handler functions and attach them to event listeners.",
          "Example:",
        ],
        image: "/images/cheatsheetImages/task27music/6.webp"
      },    {
        title: "",
        subtitle: "Key Points",
        details: [
          "Use arrow functions to pass arguments to event handlers (e.g., onClick={() => handleDelete(blog._id)}).",
          "Always handle errors gracefully and provide feedback to the user (e.g., alerts).",
        ],
      },   
      {
        title: "Why These Concepts Matter",
        subtitle: "",
        details: [
          "useEffect: Essential for managing side effects like data fetching and DOM updates.",
          "API Calls: Critical for interacting with backend services and retrieving dynamic data.",
          "Forms: A common feature in web applications for user input and data submission.",
          "Props: The primary way to share data between components in React.",
          "Events: Enable interactivity and user-driven actions in your application.",
        ],
      },
    ],
  },
  {
    taskId: "day28",
    content: [
      {
        title: "Serving Static Files in Express",
        subtitle: "What It Is:",
        details: [
          "Static files are assets like images, CSS files, JavaScript files, or documents that don't change dynamically. Serving these files efficiently is a fundamental part of building web applications.",
     
        ],
      },
         {
        title: "",
        subtitle: "Why It Matters:",
        details: [
          "Performance: Serving static files separately from dynamic content improves load times.",
          "Scalability: Reduces the load on your server by offloading static file handling to optimized middleware.",
          "Organization: Keeps your project clean and maintainable by separating static assets from application logic.",
        ],
      },
         {
        title: "",
        subtitle: "Key Concepts:",
        details: [
          "express.static: A built-in Express middleware that serves static files from a specified directory. For example, app.use('/uploads', express.static('uploads')) serves files from the 'uploads' folder.",
          "File Paths: Use the 'path' module to handle file paths consistently across different operating systems. For example, path.join(__dirname, 'uploads') ensures the correct path is used regardless of the OS.",
          "Security: Always sanitize file names and restrict access to sensitive files (e.g., configuration files).",
        ],

      },
         {
        title: "",
        subtitle: "Example:",
        details: [
     
        ],
        image: "/images/cheatsheetImages/task28music/1.webp"

      },
         {
        title: "",
        subtitle: "",
        details: [
          "This serves files from the 'uploads' folder when accessed via /uploads/filename.",
          "Pro Tip: Use a CDN (Content Delivery Network) for serving static files in production to further improve performance.",
        ],
      },
      {
        title: "Schema Design in MongoDB",
        subtitle: "What It Is:",
        details: [
          "A schema defines the structure of your data in a MongoDB collection. It acts as a blueprint, specifying what fields each document should have and their data types.",
      
        ],
      }, {
        title: "",
        subtitle: "Why It Matters:",
        details: [
          "Data Consistency: Ensures all documents in a collection follow the same structure.",
          "Validation: Prevents invalid data from being saved to the database.",
          "Readability: Makes it easier to understand and work with your data.",
        ],
      }, {
        title: "",
        subtitle: "Key Concepts:",
        details: [
          "Fields: Define the properties of your data (e.g., title, content, thumbnail).",
          "Data Types: Specify the type of each field (e.g., String, Boolean, Date).",
          "Optional vs. Required Fields: Use required: true for mandatory fields (e.g., title) and leave others optional (e.g., thumbnail).",
          "Timestamps: Use { timestamps: true } to automatically add 'createdAt' and 'updatedAt' fields to your documents.",
        ],
      }, {
        title: "",
        subtitle: "Example:",
        details: [
      
        ],
        image: "/images/cheatsheetImages/task28music/2.webp"

      }, {
        title: "",
        subtitle: "",
        details: [
          "Pro Tip: Use Mongoose's built-in validation (e.g., minlength, maxlength) to enforce additional rules on your data.",
         
        ],
      }, {
        title: "Authentication and Cookies",
        subtitle: "What It Is:",
        details: [
          "Authentication verifies a user's identity, while cookies are used to store session information securely in the user's browser.",
        
        ],
      }, {
        title: "",
        subtitle: "Why It Matters:",
        details: [
          "Security: Protects sensitive user data and prevents unauthorized access.",
          "User Experience: Maintains user sessions, so users don't have to log in repeatedly.",
        ],
      }, {
        title: "",
        subtitle: "Key Concepts:",
        details: [
          "Cookies: Small pieces of data stored in the user's browser. They are sent back to the server with every request.",
          "secure Flag: Ensures cookies are only sent over HTTPS. Use false in development and true in production.",
          "sameSite Policy: Controls how cookies are sent with cross-site requests. Use 'lax' for development and 'none' for production.",
          "Token-Based Authentication: Use JSON Web Tokens (JWT) to securely store user information.",
        ],
      }, {
        title: "",
        subtitle: "Example:",
        details: [
       
        ],
        image: "/images/cheatsheetImages/task28music/3.webp"

      }, {
        title: "",
        subtitle: "",
        details: [
          "Pro Tip: Always hash passwords using libraries like bcrypt before storing them in the database.",
         
        ],
      }, {
        title: "File Uploads with Multer",
        subtitle: "What It Is:",
        details: [
          "Multer is a middleware for handling file uploads in Express. It simplifies the process of receiving and storing files on the server.",
         
        ],
      }, {
        title: "",
        subtitle: "Why It Matters:",
        details: [
          "User Engagement: Allows users to upload files like profile pictures or blog thumbnails.",
          "Security: Ensures files are validated and stored securely.",
        ],
      }, {
        title: "",
        subtitle: "Key Concepts:",
        details: [
          "Storage: Configure where files are saved (e.g., uploads/ folder).",
          "File Filtering: Restrict file types (e.g., only images).",
          "File Size Limits: Prevent large uploads that could overwhelm the server.",
        ],
      }, {
        title: "",
        subtitle: "Example:",
        details: [
        
        ],
        image: "/images/cheatsheetImages/task28music/4.webp"

      }, {
        title: "",
        subtitle: "",
        details: [
          "Pro Tip: Always sanitize file names to avoid security issues (e.g., using timestamps or UUIDs).",
       
        ],
      }, {
        title: "Dynamic Data Fetching in React",
        subtitle: "What It Is:",
        details: [
          "Fetching data dynamically from a backend API instead of using hardcoded data.",
       
        ],
      }, {
        title: "",
        subtitle: "Why It Matters:",
        details: [
          "Real-Time Updates: Makes your application more interactive and up-to-date.",
          "Scalability: Reduces the need to redeploy the frontend when data changes.",
        ],
      }, {
        title: "",
        subtitle: "Key Concepts:",
        details: [
          "useState: Manages state in functional components (e.g., storing fetched data).",
          "useEffect: Handles side effects like fetching data when the component loads.",
          "API Calls: Use 'fetch' or 'axios' to request data from the backend.",
        ],
      }, {
        title: "",
        subtitle: "Example:",
        details: [
       
        ],
        image: "/images/cheatsheetImages/task28music/5.webp"

      }, {
        title: "",
        subtitle: "",
        details: [
          "Pro Tip: Always handle loading and error states to improve the user experience.",
         
        ],
      }, {
        title: "Rendering Dynamic Content",
        subtitle: "What It Is:",
        details: [
          "Displaying data fetched from an API in your React components.",
        
        ],
      }, {
        title: "",
        subtitle: "Why It Matters:",
        details: [
          "Dynamic UI: Makes your application responsive to changes in data.",
          "User Experience: Provides real-time updates without requiring page reloads.",
        ],
      }, {
        title: "",
        subtitle: "Key Concepts:",
        details: [
          "Mapping Data: Use .map() to loop through an array and render components for each item.",
          "Conditional Rendering: Show placeholders or error messages if data is loading or unavailable.",
          "Formatting Data: Use JavaScript methods like toLocaleDateString() to format dates or substring() to truncate text.",
        ],
      }, {
        title: "",
        subtitle: "Example:",
        details: [
      
        ],
        image: "/images/cheatsheetImages/task28music/6.webp"

      }, {
        title: "",
        subtitle: "",
        details: [
          "Pro Tip: Use keys (e.g., blog._id) when rendering lists to help React identify which items have changed.",
       
        ],
      }, {
        title: "Final Thought",
        subtitle: "",
        details: [
          "By mastering these concepts, you're not just building a web application—you're developing a deep understanding of how modern web development works. From handling file uploads to designing schemas and fetching data dynamically, these skills will empower you to tackle more complex projects with confidence.",
        
        ],
      }, 
    ],
  },
  {
    taskId: "day32",
    content: [
      {
        title: "Understanding Core React Concepts Through a Testimonial Management System: A Theoretical Guide",
        subtitle: "",
        details: [
          "React has become one of the most popular libraries for building dynamic and interactive user interfaces. Its component-based architecture, paired with powerful features like hooks, makes it an excellent choice for developing modern web applications. In this theoretical blog post, we'll explore the key concepts of React by breaking down a testimonial management system—a practical example that involves creating, editing, viewing, and deleting testimonials. This system touches on several fundamental React topics, providing a perfect opportunity to learn and apply core skills.",
          "We'll focus on five key learning outcomes: state management with hooks, fetching and manipulating data from a backend API, component composition through props and events, form handling, and error handling with user feedback. By the end of this post, you'll have a solid understanding of these concepts and how they come together to create a functional React application.",
        ],
      },
         {
        title: "1. Managing State in React Using Hooks",
        subtitle: "What is State in React?",
        details: [
          "State refers to data that a component can hold and manage over time. Unlike props, which are passed to a component and are immutable within that component, state is internal and can be updated by the component itself. Changes to state typically trigger a re-render, allowing the UI to reflect the updated data.",
      
        ],
      },
         {
        title: "",
        subtitle: "Why Use Hooks for State Management?",
        details: [
          "Before hooks were introduced in React 16.8, state management was primarily handled using class components and this.setState. Hooks, such as useState and useEffect, allow functional components to manage state and side effects, leading to cleaner and more reusable code.",
       
        ],
      },
         {
        title: "",
        subtitle: "Applying State Management in a Testimonial System",
        details: [
          "Form Inputs: A form for creating or editing testimonials needs to store user input (e.g., the testimonial content, rating, and approval status) in state. As the user types or selects options, the state updates dynamically.",
          "List of Testimonials: The list of testimonials fetched from the backend is stored in state so it can be displayed in a table and updated when a testimonial is created, edited, or deleted.",
          "Refresh Trigger: A boolean state can be used to trigger a refetch of testimonials when changes occur, ensuring the UI stays in sync with the backend.",
        ],
      },
         {
        title: "",
        subtitle: "How Hooks Help",
        details: [
          "useState: Used to initialize and update state for form inputs, testimonial lists, and other dynamic data.",
          "useEffect: Used to perform side effects, such as fetching data from the backend when the component mounts or when a specific state changes (e.g., a refresh trigger).",
        ],
      },
      {
        title: "",
        subtitle: "Why It Matters",
        details: [
          "Understanding state management with hooks allows you to create dynamic and responsive applications. It's the foundation for handling user interactions and ensuring the UI reflects the current application state.",
       
        ],
      },{
        title: "2. Fetching and Manipulating Data from a Backend API",
        subtitle: "Why Integrate with a Backend API?",
        details: [
          "Most real-world applications rely on a backend to store and manage data persistently. In a testimonial system, the backend provides endpoints to fetch users (for assigning testimonials), fetch testimonials, and handle CRUD (Create, Read, Update, Delete) operations.",
      
        ],
      },{
        title: "",
        subtitle: "Fetching Data in React",
        details: [
          "React itself doesn't provide built-in tools for making HTTP requests, but you can use the native fetch API or libraries like axios to communicate with a backend. Typically, you'll fetch data when a component mounts or when certain conditions change (e.g., after creating a new testimonial).",
        
        ],
      },{
        title: "",
        subtitle: "Applying API Integration in a Testimonial System",
        details: [
          "In the testimonial management system, several API interactions are required:",
          "Fetch Users: When the form component loads, it needs to fetch a list of users to populate a dropdown menu for selecting the testimonial's author.",
          "Fetch Testimonials: The table component fetches all testimonials to display them in a list.",
          "Create/Update Testimonials: The form sends a POST request to create a new testimonial or a PUT request to update an existing one.",
          "Delete Testimonials: The table sends a DELETE request to remove a testimonial.",
        ],
      },{
        title: "",
        subtitle: "Using useEffect for API Calls",
        details: [
          "The useEffect hook is ideal for fetching data because it allows you to run side effects (like HTTP requests) when a component mounts or when specific dependencies change. For example:",
          "Fetch users when the form component mounts.",
          "Refetch testimonials when a “refresh” state changes (e.g., after creating or deleting a testimonial).",
        ],
      },{
        title: "",
        subtitle: "Manipulating Data",
        details: [
          "Once data is fetched, it's stored in state and can be manipulated as needed. For example, you might filter testimonials based on their approval status or truncate long content for display purposes.",
        
        ],
      },{
        title: "",
        subtitle: "Why It Matters",
        details: [
          "Learning to fetch and manipulate data from a backend API is essential for building full-stack applications. It bridges the gap between the frontend and backend, enabling dynamic data-driven experiences.",
        
        ],
      },{
        title: "3. Component Composition: Passing Props and Handling Events Between Components",
        subtitle: "What is Component Composition?",
        details: [
          "React's component-based architecture allows you to break down your UI into reusable, independent pieces called components. Component composition refers to combining these components to build a larger application, often by passing data (via props) and handling interactions (via event handlers) between them.",
       
        ],
      },{
        title: "",
        subtitle: "Parent-Child Communication",
        details: [
          "In React, data typically flows from parent components to child components through props. Conversely, child components can communicate with parents by invoking callback functions passed as props.",
         
        ],
      },{
        title: "",
        subtitle: "Applying Component Composition in a Testimonial System",
        details: [
          "The testimonial management system consists of three main components:",
          "Parent Component: Orchestrates the overall flow, managing state for refreshing the testimonial list and tracking the testimonial being edited.",
          "Form Component: Handles creating and editing testimonials, receiving props like the testimonial to edit and a callback to notify the parent when a testimonial is created.",
          "Table Component: Displays the list of testimonials, receiving props like a refresh trigger and a callback to set the testimonial to edit.",
        ],
      },{
        title: "",
        subtitle: "Passing Props",
        details: [
          "The parent passes a refreshTestimonials boolean to the table component to trigger refetching.",
          "The parent passes a testimonialToEdit object to the form component to populate it for editing.",
          "The parent passes callback functions like onTestimonialCreated and onEditTestimonial to the form and table components, respectively.",
        ],
      },{
        title: "",
        subtitle: "Handling Events",
        details: [
          "When a testimonial is created or updated, the form component calls the onTestimonialCreated callback to notify the parent, which toggles the refresh state.",
          "When the “Edit” button is clicked in the table, the table component calls the onEditTestimonial callback to pass the selected testimonial to the parent, which updates the edit state.",
        ],
      },{
        title: "",
        subtitle: "Why It Matters",
        details: [
          "Component composition allows you to create modular, reusable code. Understanding how to pass props and handle events between components ensures smooth communication and coordination within your application.",
       
        ],
      },{
        title: "4. Form Handling: Creating, Updating, and Validating User Input",
        subtitle: "Why is Form Handling Important?",
        details: [
          "Forms are a primary way users interact with web applications. In React, form handling involves managing user input, validating it, and submitting it to perform actions like saving data to a backend.",
       
        ],
      },{
        title: "",
        subtitle: "Controlled Components",
        details: [
          "In React, forms are typically managed using controlled components, where form inputs are tied to state. The value of each input is stored in state, and an onChange handler updates the state as the user types.",
     
        ],
      },{
        title: "",
        subtitle: "Applying Form Handling in a Testimonial System",
        details: [
          "The form component in the testimonial system handles creating and editing testimonials. Here's how it works:",
          "Initialize Form State: Use useState to store the form data (e.g., user, title, content, rating, approved status).",
          "Update State on Change: Write a change handler to update the state whenever the user interacts with an input, select, or checkbox.",
          "Populate Form for Editing: When editing a testimonial, use useEffect to set the form state based on the testimonial being edited.",
          "Submit the Form: Write a submit handler to send the form data to the backend via a POST or PUT request, depending on whether the user is creating or updating a testimonial.",
        ],
      },{
        title: "",
        subtitle: "Validating Input",
        details: [
          "Validation ensures that user input meets certain criteria before submission:",
          "Required fields (e.g., content) should not be empty.",
          "The rating should be within a valid range (e.g., 1 to 5).",
          "Validation can be performed before submitting the form, displaying error messages if necessary.",
        ],
      },{
        title: "",
        subtitle: "Why It Matters",
        details: [
          "Mastering form handling in React allows you to create interactive and user-friendly applications. It teaches you how to manage user input effectively and ensure data integrity before sending it to the backend.",
     
        ],
      },{
        title: "5. Error Handling and Providing User Feedback",
        subtitle: "Why Handle Errors?",
        details: [
          "Errors are inevitable in web applications—network failures, invalid user input, or backend issues can all cause problems. Proper error handling ensures that your application doesn't crash and provides meaningful feedback to users.",
       
        ],
      },{
        title: "",
        subtitle: "Applying Error Handling in a Testimonial System",
        details: [
          "In the testimonial system, errors can occur during API requests (e.g., fetching users, creating testimonials) or form submission. Here's how to handle them:",
          "Wrap API Calls in Try-Catch: Use try-catch blocks to catch errors during fetch requests. Log errors to the console for debugging and display user-friendly messages (e.g., via alerts).",
          "Provide Feedback on Form Submission: If creating or updating a testimonial fails, show an alert with the error message. If successful, show a success message and reset the form.",
          "Handle Edge Cases: If the backend returns an empty list of testimonials, display a message like “No testimonials found.” If a user cannot be fetched, disable the form or show an error.",
        ],
      },{
        title: "",
        subtitle: "Enhancing User Feedback",
        details: [
          "Beyond error messages, you can improve the user experience with:",
          "Loading States: Show a spinner or loading message while fetching data.",
          "Success Messages: Notify the user when a testimonial is created, updated, or deleted successfully.",
          "Validation Feedback: Highlight invalid fields and provide instructions for correction.",
        ],
      },{
        title: "",
        subtitle: "Why It Matters",
        details: [
          "Effective error handling and user feedback make your application more robust and user-friendly. They help users understand what went wrong and how to proceed, improving the overall experience.",
       
        ],
      },{
        title: "Bringing It All Together: Why These Concepts Matter",
        subtitle: "",
        details: [
          "The testimonial management system demonstrates how these core React concepts—state management, API integration, component composition, form handling, and error handling—work together to create a functional application. Each concept builds on the others:",
          "State management enables dynamic updates to the UI.",
          "API integration brings real-world data into the application.",
          "Component composition ensures modularity and reusability.",
          "Form handling facilitates user interaction.",
          "Error handling keeps the application stable and informative.",
        ],
      },{
        title: "",
        subtitle: "",
        details: [
          "By mastering these concepts, you'll be well-equipped to tackle more complex React projects. Whether you're building a simple CRUD app or a large-scale enterprise application, these foundational skills will serve as the building blocks for success.",
     
        ],
      },
    ],
  },
  {
    taskId: "day34",
    content: [
      {
        title: "Theory Guide to Implementing Socket.IO for Real-Time Messaging",
        subtitle: "",
        details: [
          "Socket.IO is a powerful library that enables real-time, bidirectional communication between a backend server and frontend clients. It's commonly used for features like chat applications, live notifications, or collaborative tools. In this guide, we'll break down the process of setting up Socket.IO in a full-stack application (Node.js backend with Express and a React frontend) by explaining the concepts behind each step. Our goal is to help beginners understand why these steps are necessary and how they fit into the bigger picture of building a real-time messaging system.",
    
        ],
      },  {
        title: "Understanding the Backend: Building the Foundation for Real-Time Communication",
        subtitle: "",
        details: [
          "The backend is responsible for managing connections, handling messages, and broadcasting them to the right users in real time. Let's dive into the concepts behind the backend steps.",
        
        ],
      },  {
        title: "Setting Up the Backend Environment",
        subtitle: "",
        details: [
          "Before you can use Socket.IO, you need to prepare your backend environment. This involves installing the necessary tools (dependencies) to enable real-time communication. In Node.js, dependencies are managed using npm (Node Package Manager), which downloads libraries like Socket.IO for you.",
       
        ],
      },  {
        title: "",
        subtitle: "Why Dependencies Matter",
        details: [
          "Socket.IO isn't built into Node.js, so you need to install it as an external library. It provides the functionality to create WebSocket connections, which allow for continuous, two-way communication between the server and clients (unlike traditional HTTP requests, which are one-off).",
      
        ],
      },  {
        title: "",
        subtitle: "What's http?",
        details: [
          "Node.js includes a built-in module called http, which you'll use to create an HTTP server. Socket.IO needs this HTTP server as a foundation to establish WebSocket connections.",
       
        ],
      },  {
        title: "",
        subtitle: "Key Concepts",
        details: [
          "Dependencies: Libraries like Socket.IO extend Node.js functionality.",
          "WebSockets: A protocol for real-time communication, unlike HTTP, which is request-response based.",
          "Node.js Modules: Built-in modules like http don't need installation, but external ones like Socket.IO do.",
        ],
      },  {
        title: "Creating an HTTP Server",
        subtitle: "",
        details: [
          "Socket.IO relies on an HTTP server to establish WebSocket connections. If you're using Express (a popular Node.js framework for handling HTTP requests), you can integrate it with the HTTP server to handle both traditional HTTP routes and WebSocket communication.",
          
        ],
      },  {
        title: "",
        subtitle: "Why an HTTP Server?",
        details: [
          "WebSockets start with an HTTP handshake (a special request to 'upgrade' the connection). The http module in Node.js lets you create this server, and Socket.IO builds on top of it.",
  
        ],
      },  {
        title: "",
        subtitle: "Why Integrate with Express?",
        details: [
          "Express makes it easier to handle HTTP routes (like /api/users) and middleware (like parsing JSON). By combining Express with the HTTP server, you can use both for different purposes in the same app.",
        
        ],
      },  {
        title: "",
        subtitle: "Key Concepts",
        details: [
          "HTTP Server: A foundation for handling HTTP requests and WebSocket upgrades.",
          "Express App: A framework that simplifies routing and middleware in Node.js.",
          "Integration: Combining Express with an HTTP server lets you handle both HTTP and WebSocket communication.",
        ],
      },  {
        title: "Initializing Socket.IO",
        subtitle: "",
        details: [
          "Once you have an HTTP server, you can initialize Socket.IO to enable real-time communication. This involves creating a Socket.IO instance and configuring settings like CORS (Cross-Origin Resource Sharing) to allow your frontend to connect.",
       
        ],
      },  {
        title: "",
        subtitle: "What's Socket.IO Doing?",
        details: [
          "It wraps the HTTP server with additional functionality, like WebSocket support and fallback mechanisms (e.g., polling if WebSockets aren't available). This lets clients connect and exchange messages in real time.",
        
        ],
      },  {
        title: "",
        subtitle: "Why CORS?",
        details: [
          "Browsers enforce security rules that block requests between different origins (e.g., localhost:3000 frontend to localhost:5000 backend). CORS settings tell the server which origins (like your frontend URL) are allowed to connect, what HTTP methods (like GET or POST) are permitted, and whether credentials (like cookies) can be sent.",
         
        ],
      },  {
        title: "",
        subtitle: "Key Concepts",
        details: [
          "Socket.IO Instance: A server-side object that manages all WebSocket connections and events.",
          "CORS: A security mechanism to control cross-origin requests.",
          "Origins and Methods: Specifying allowed URLs and HTTP methods ensures secure communication.",
        ],
      },  {
        title: "Handling Client Connections",
        subtitle: "",
        details: [
          "When a client (like a browser) connects to your backend via Socket.IO, the server needs to handle the connection and prepare for events like joining rooms or sending messages. Socket.IO uses 'event listeners' to respond to actions from clients.",
          
        ],
      },  {
        title: "",
        subtitle: "What's a Connection?",
        details: [
          "When a client connects, Socket.IO creates a unique socket object representing that connection. You can listen for a 'connection' event to know when this happens.",
       
        ],
      },  {
        title: "",
        subtitle: "What Are Rooms?",
        details: [
          "Rooms are a Socket.IO feature that lets you group sockets (clients) together. For example, you might create a room for each user (named after their ID) so you can send messages to specific users later.",
          
        ],
      },  {
        title: "",
        subtitle: "Why Listen for Events?",
        details: [
          "Clients can send custom events (like 'join') to the server. By listening for these events, you can execute code—like joining a room—when the event occurs.",
         
        ],
      },  {
        title: "",
        subtitle: "Key Concepts",
        details: [
          "Socket Object: Represents a single client connection.",
          "Rooms: A way to group clients for targeted messaging.",
          "Event Listeners: Functions that run when specific events (like 'connection' or 'join') occur.",
        ],
      },  
      {
        title: "Handling Messages",
        subtitle: "",
        details: [
          "Real-time messaging involves receiving messages from one client, saving them (e.g., to a database), and broadcasting them to other clients. This step focuses on handling a 'sendMessage' event, processing the message, and emitting it to the right users.",
        
        ],
      },   {
        title: "",
        subtitle: "Why Save Messages?",
        details: [
          "Storing messages in a database (like MongoDB) ensures they're persistent, so users can see their chat history even after disconnecting.",
        
        ],
      },   {
        title: "",
        subtitle: "What's Broadcasting?",
        details: [
          "After saving a message, you can use Socket.IO to send it to specific clients (e.g., the sender and receiver) by targeting their rooms. This is done with an 'emit' action.",
        
        ],
      },   {
        title: "",
        subtitle: "Why Use Try-Catch?",
        details: [
          "Saving to a database or broadcasting can fail (e.g., due to network issues). A try-catch block lets you handle errors gracefully and notify the client if something goes wrong.",
        
        ],
      },   {
        title: "",
        subtitle: "Key Concepts",
        details: [
          "Event Handling: Listening for custom events (like 'sendMessage') to process messages.",
          "Database Operations: Saving and retrieving data (e.g., messages) for persistence.",
          "Broadcasting: Sending messages to specific clients using rooms.",
          "Error Handling: Using try-catch to manage failures and inform the client.",

        
        ],
      },   {
        title: "Handling Disconnections",
        subtitle: "",
        details: [
          "When a client disconnects (e.g., closes their browser), Socket.IO triggers a 'disconnect' event. Handling this event ensures the server cleans up resources or logs the disconnection.",
        
        ],
      },   {
        title: "",
        subtitle: "Why Handle Disconnections?",
        details: [
          "Knowing when a client leaves helps you manage server resources (like memory) and update other users if needed (e.g., showing a user as offline).",
        
        ],
      },   {
        title: "",
        subtitle: "What Happens?",
        details: [
          "Socket.IO automatically closes the WebSocket connection, but you can add custom logic (like logging or updating a user's status) when this happens.",
        
        ],
      },   {
        title: "",
        subtitle: "Key Concepts",
        details: [
          "Resource Management: Cleaning up after a client to keep the server efficient.",
          "Disconnect Event: An event triggered when a client leaves.",
        
        ],
      },   {
        title: "Understanding the Frontend: Connecting and Managing Real-Time Messages",
        subtitle: "",
        details: [
          "The frontend connects to the backend using the Socket.IO client library, sends messages, and updates the UI when new messages arrive. Let's explore the concepts behind the frontend steps.",
        
        ],
      },   {
        title: "Preparing the Frontend Environment",
        subtitle: "",
        details: [
          "To connect your frontend to the backend, you need the Socket.IO client library. This library lets your React app establish a WebSocket connection and communicate with the backend.",
        
        ],
      },   {
        title: "",
        subtitle: "Why a Client Library?",
        details: [
          "The Socket.IO client handles the WebSocket connection and simplifies sending/receiving events. It's the counterpart to the Socket.IO server library.",
        
        ],
      },   {
        title: "",
        subtitle: "What's the Setup Like?",
        details: [
          "Installing the client library with npm adds it to your React project, so you can import and use it in your components.",
        
        ],
      },   {
        title: "",
        subtitle: "Key Concepts",
        details: [
          "Socket.IO Client: A library for connecting the frontend to the backend.",
          "Installation: Using npm to add external libraries to a project.",
        
        ],
      },   {
        title: "Managing State and References",
        subtitle: "",
        details: [
          "To handle real-time messaging, your component needs to track data (like messages) and maintain a reference to the Socket.IO connection. React provides useState for state and useRef for references.",
        
        ],
      },   {
        title: "",
        subtitle: "Why State?",
        details: [
          "State variables (like messages or newMessage) let you store and update data that affects the UI, like the list of messages or the text in an input field.",
        
        ],
      },   {
        title: "",
        subtitle: "Why References?",
        details: [
          "A reference (useRef) is useful for storing the Socket.IO instance because it persists across renders without causing re-renders (unlike state).",
        
        ],
      },   {
        title: "",
        subtitle: "What Data to Track?",
        details: [
          "You'll track messages, the current conversation, errors, and the Socket.IO connection.",
        
        ],
      },   {
        title: "",
        subtitle: "Key Concepts",
        details: [
          "State (useState): For data that changes and affects the UI.",
          "References (useRef): For data that persists but doesn't trigger re-renders.",
          "Dynamic UI: Updating state causes React to re-render the component with new data.",
        
        ],
      },   {
        title: "Synchronizing Data with Side Effects",
        subtitle: "",
        details: [
          "Sometimes, you need to update data (like a reference) when state changes. In React, useEffect lets you run side effects (like updating a reference) in response to changes.",
        
        ],
      },   {
        title: "",
        subtitle: "Why a Side Effect?",
        details: [
          "When the selected conversation changes, you might need to update a reference to it so other parts of your code (like event handlers) can access the latest value.",
        
        ],
      },   {
        title: "",
        subtitle: "How Does useEffect Work?",
        details: [
          "It runs a function whenever specific dependencies (like the selected conversation) change.",
        
        ],
      },   {
        title: "",
        subtitle: "Key Concepts",
        details: [
          "Side Effects (useEffect): Running code in response to state or prop changes.",
          "Dependencies: Values that useEffect watches to decide when to run.",
          "Synchronization: Keeping references or other data in sync with state.",
        
        ],
      },   {
        title: "Establishing a Socket.IO Connection",
        subtitle: "",
        details: [
          "Your frontend needs to connect to the backend when the component mounts (i.e., when it's added to the UI). This involves initializing the Socket.IO client, handling connection events, and joining a room.",
        
        ],
      },   {
        title: "",
        subtitle: "Why Connect on Mount?",
        details: [
          "Using useEffect with an empty dependency array ensures the connection happens once when the component loads.",
        
        ],
      }, {
        title: "",
        subtitle: "What's Involved?",
        details: [
          "You'll initialize the Socket.IO client with the backend URL, set options (like credentials), and listen for events like 'connect' or 'connect_error'.",
        
        ],
      }, {
        title: "",
        subtitle: "Why Join a Room?",
        details: [
          "Emitting a 'join' event with the user's ID tells the backend to add the client to a room, so it can receive messages targeted to that user.",
        
        ],
      }, {
        title: "",
        subtitle: "Key Concepts",
        details: [
          "Socket.IO Client Initialization: Creating a connection to the backend.",
          "Connection Events: Handling success ('connect') or failure ('connect_error').",
          "Rooms: Joining a user-specific room for targeted messaging.",
        
        ],
      }, {
        title: "Receiving Messages",
        subtitle: "",
        details: [
          "When the backend sends a message (via a 'receiveMessage' event), your frontend needs to update the UI. This involves listening for the event and updating state with the new message.",
        
        ],
      }, {
        title: "",
        subtitle: "Why Listen for Events?",
        details: [
          "Socket.IO lets the backend push messages to the frontend in real time, so you don't need to poll the server.",
        
        ],
      }, {
        title: "",
        subtitle: "How to Update the UI?",
        details: [
          "Add the new message to the messages state, but only if it's not already there (to avoid duplicates). If the message belongs to the current conversation, update the conversation's message list too.",
        
        ],
      }, {
        title: "",
        subtitle: "Why Check the Conversation?",
        details: [
          "You only want to update the UI for messages relevant to the currently selected chat.",
        
        ],
      }, {
        title: "",
        subtitle: "Key Concepts",
        details: [
          "Event Listeners: Listening for backend events (like 'receiveMessage') to process incoming data.",
          "State Updates: Adding new messages to state to update the UI.",
          "Conditional Logic: Checking if a message belongs to the current conversation before updating.",
        
        ],
      }, {
        title: "Cleaning Up Connections",
        subtitle: "",
        details: [
          "When the component unmounts (i.e., is removed from the UI), you should disconnect the Socket.IO client to free up resources and avoid memory leaks.",
        
        ],
      }, {
        title: "",
        subtitle: "Why Clean Up?",
        details: [
          "Leaving connections open can waste server resources and cause unexpected behavior (like receiving messages after leaving a chat).",
        
        ],
      }, {
        title: "",
        subtitle: "How Does Cleanup Work?",
        details: [
          "In useEffect, you can return a cleanup function that runs when the component unmounts. This function can disconnect the Socket.IO client.",
        
        ],
      }, {
        title: "",
        subtitle: "Key Concepts",
        details: [
          "Cleanup (useEffect Return): Running code when a component unmounts.",
          "Resource Management: Disconnecting to free up server and client resources.",
          "Memory Leaks: Preventing issues caused by lingering connections.",
        
        ],
      }, {
        title: "Sending Messages",
        subtitle: "",
        details: [
          "To send a message, the frontend emits a 'sendMessage' event to the backend with the message data (like sender, receiver, and content). You also need to manage state for the input field and loading status.",
        
        ],
      }, {
        title: "",
        subtitle: "Why Emit an Event?",
        details: [
          "The 'sendMessage' event tells the backend to process and broadcast the message. This is how the frontend communicates actions to the backend.",
        
        ],
      }, {
        title: "",
        subtitle: "Why Track Loading?",
        details: [
          "A loading state lets you show a spinner or disable a button while the message is being sent, improving the user experience.",
        
        ],
      }, {
        title: "",
        subtitle: "Why Handle Errors?",
        details: [
          "If sending fails (e.g., due to a network issue), you can display an error message to the user.",
        
        ],
      }, {
        title: "",
        subtitle: "Key Concepts",
        details: [
          "Event Emission: Sending data to the backend via Socket.IO.",
          "State Management: Tracking input text and loading status.",
          "Error Handling: Using try-catch to manage failures and inform the user.",
        
        ],
      }, {
        title: "Putting It All Together: How It Works",
        subtitle: "Here's a high-level overview of how the backend and frontend work together:",
       
      }, {
        title: "",
        subtitle: "Backend Setup",
        details: [
          "The backend creates an HTTP server, initializes Socket.IO, and listens for client connections. It handles events like 'join' (to assign clients to rooms) and 'sendMessage' (to save and broadcast messages).",
        
        ],
      },{
        title: "",
        subtitle: "Frontend Setup",
        details: [
          "The frontend installs the Socket.IO client, connects to the backend when the messaging component mounts, and joins a user-specific room.",
        
        ],
      },{
        title: "",
        subtitle: "Real-Time Messaging",
        details: [
          "When a user sends a message, the frontend emits a 'sendMessage' event to the backend.",
          "The backend saves the message to a database and broadcasts it to the sender and receiver using their rooms.",
          "The frontend listens for the 'receiveMessage' event and updates the UI with the new message.",
        
        ],
      },{
        title: "",
        subtitle: "Cleanup",
        details: [
          "When the user leaves the chat or the component unmounts, the frontend disconnects from Socket.IO, and the backend logs the disconnection.",
        
        ],
      },{
        title: "Key Takeaways",
        subtitle: "",
        details: [
          "Real-Time Communication: Socket.IO uses WebSockets to enable instant messaging without constant polling.",
          "Client-Server Interaction: The backend listens for events from clients (like sending a message), processes them, and emits events back to update clients.",
          "State and Side Effects: In the frontend, React hooks like useState and useEffect manage data and handle real-time updates.",
          "Error Handling: Both backend and frontend should handle errors (e.g., failed connections or database issues) to keep the app reliable.",
          "Rooms and Events: Socket.IO's rooms and custom events make it easy to target specific users and handle different actions (like joining or messaging).",
        

        
        ],
      },
        
    ],
  },
  {
    taskId: "day35",
    content: [
      {
        title: "Messaging App Theory Blog Cheatsheet: Understanding 'Day 35'",
        subtitle: "",
        details: [
          "So, you've tackled 'Day 34' and got real-time messaging working with Socket.IO—pretty cool, right? Now, 'Day 35' takes that foundation and builds the rest of the messaging app, adding a slick UI and some key features. This cheatsheet breaks down the big ideas behind 'Day 35', explaining what's going on under the hood so you can see the 'why' behind the code.",
      
        ],
      },
      {
        title: "The Big Picture: What's 'Day 35' Doing?",
        subtitle: "",
        details: [
          "'Day 35' is all about finishing your messaging app. While 'Day 34' handled the live chat magic (sending and receiving messages instantly), 'Day 35' adds the interface you see and interact with—think sidebars, chat windows, and search bars—plus some extra logic to fetch old messages, manage conversations, and handle user navigation. It's like putting a friendly face on the techy stuff from 'Day 34'!",
        
        ],
      },
         {
        title: "State: The App's Memory",
        subtitle: "What's Happening",
        details: [
          "'Day 35' adds new 'state' variables to track things like whether the app is loading, what you're typing in a search, or if a new message form is showing. These build on the state from 'Day 34' (like the message list or the current conversation).",
        
        ],
      },
      {
        title: "",
        subtitle: "Why It Matters",
        details: [
          "State is like the app's brain—it remembers what's going on so the UI can react. For example, when you type in a search bar, the state updates, and the app knows to show matching users. It's how React keeps everything in sync!",
      
        ],
      },{
        title: "References: Pointing to the Chat's Bottom",
        subtitle: "What's Happening",
        details: [
          "There's a new 'reference' added to track the bottom of the chat window, alongside the ones from 'Day 34' for Socket.IO and the selected conversation.",
      
        ],
      },{
        title: "",
        subtitle: "Why It Matters",
        details: [
          "References are like bookmarks in the UI. This one helps the app scroll to the latest message automatically—super handy when chats get long! It's a way to control parts of the screen without messing with state.",
      
        ],
      },{
        title: "Backend URL: Talking to the Server",
        subtitle: "What's Happening",
        details: [
          "'Day 35' sets up a constant for the backend URL (e.g., http://localhost:5001), which wasn't explicitly defined in 'Day 34'.",
      
        ],
      },{
        title: "",
        subtitle: "Why It Matters",
        details: [
          "This is the address your app uses to chat with the server. It's like giving your app a phone number to call for messages or user searches—without it, the frontend and backend can't connect!",
      
        ],
      },{
        title: "Navigation and Prop Types: Safety First",
        subtitle: "What's Happening",
        details: [
          "New imports let the app redirect you to a login page if you're not signed in, and 'prop types' define what the user data should look like.",
      
        ],
      },{
        title: "",
        subtitle: "Why It Matters",
        details: [
          "Navigation keeps the app secure—if you're not logged in, it sends you back to sign in. Prop types are like a rulebook, making sure the user info (like ID or name) is correct so the app doesn't break.",
      
        ],
      },
      {
        "title": "Side Effects: Reacting to Changes",
        "subtitle": "What's Happening",
        "details": [
          "'Day 35' tweaks the Socket.IO side effect from 'Day 34' to check if you're logged in, then adds new ones to fetch messages and scroll the chat."
        ]
      },
      {
        "title": "",
        "subtitle": "Why It Matters",
        "details": [
          "Side effects (with useEffect) are React's way of doing stuff when something changes—like loading messages when you open the app or scrolling when you switch conversations. They tie the app's actions to its state, keeping everything smooth."
        ]
      },
      {
        "title": "Fetching Messages: Loading the Past",
        "subtitle": "What's Happening",
        "details": [
          "The app grabs all your old messages from the server when it starts."
        ]
      },
      {
        "title": "",
        "subtitle": "Why It Matters",
        "details": [
          "Socket.IO in 'Day 34' handles new messages, but what about ones sent before? Fetching fills in the history so you don't miss anything—it's like checking your chat log when you open WhatsApp."
        ]
      },
      {
        "title": "Searching Users: Finding Friends",
        "subtitle": "What's Happening",
        "details": [
          "You can type a name or email to find someone to message, and the app fetches a list from the server."
        ]
      },
      {
        "title": "",
        "subtitle": "Why It Matters",
        "details": [
          "This lets you start new chats easily. It's a mini search engine inside your app—type, see results, click, and boom, you're chatting!"
        ]
      },
      {
        "title": "Conversations: Grouping Messages",
        "subtitle": "What's Happening",
        "details": [
          "The app organizes messages into conversations by user, sorting them by the latest message."
        ]
      },
      {
        "title": "",
        "subtitle": "Why It Matters",
        "details": [
          "Instead of a messy list of messages, you get a tidy sidebar with one entry per person. It's how you see who you're talking to and what was said last—like your phone's chat list."
        ]
      },
      {
        "title": "Marking as Read: Keeping Track",
        "subtitle": "What's Happening",
        "details": [
          "When you click a conversation, unread messages get marked as read on the server."
        ]
      },
      {
        "title": "",
        "subtitle": "Why It Matters",
        "details": [
          "This mimics real apps—once you see a message, it's not “unread” anymore. It's a small touch that makes the app feel polished."
        ]
      },
      {
        "title": "UI Rendering: What You See",
        "subtitle": "What's Happening",
        "details": [
          "'Day 35' builds the whole interface—sidebar with conversations, chat window with messages, input field, and a search form."
        ]
      },
      {
        "title": "",
        "subtitle": "Why It Matters",
        "details": [
          "This is the app's face! Without it, 'Day 34''s code would just be invisible magic. The UI turns data (messages, users) into something you can click and read."
        ]
      },
      {
        "title": "Styling: Making It Pretty",
        "subtitle": "What's Happening",
        "details": [
          "CSS rules shape the layout—side-by-side sidebar and chat, bubbly messages, and neat buttons."
        ]
      },
      {
        "title": "",
        "subtitle": "Why It Matters",
        "details": [
          "Good styling isn't just looks—it makes the app easy to use. Left-aligned messages from others, right-aligned from you—it's intuitive and fun!"
        ]
      },{
        title: "How It Ties to 'Day 34'",
        subtitle: "Socket.IO Connection",
        details: [
          "'Day 34' set up real-time messaging, and 'Day 35' uses that to show new messages instantly in the UI.",
      
        ],
      },{
        title: "",
        subtitle: "State Sharing",
        details: [
          "Both lessons use the same messages and selectedConversation states—'Day 35' just adds more to manage the UI.",
      
        ],
      },{
        title: "",
        subtitle: "Sending Messages",
        details: [
          "The sendMessage function from 'Day 34' gets a shiny input field and button in 'Day 35'.",
      
        ],
      },{
        title: "",
        subtitle: "Backend Link",
        details: [
          "'Day 34''s server powers 'Day 35''s fetching and searching—it's one big team!",
      
        ],
      },{
        title: "Why This Works for Beginners",
        subtitle: "Step-by-Step Logic",
        details: [
          "Each piece (state, effects, UI) builds on the last, like stacking blocks.",
      
        ],
      },{
        title: "",
        subtitle: "Real-World Feel",
        details: [
          "It's not just code—it's a chat app you'd actually use, with search, scrolling, and live updates.",
      
        ],
      },{
        title: "",
        subtitle: "Error Handling",
        details: [
          "Loading and error messages mean the app won't just crash—it tells you what's wrong.",
      
        ],
      },{
        title: "Fun Takeaway",
        subtitle: "",
        details: [
          "Think of 'Day 35' as decorating a house 'Day 34' built. 'Day 34' laid the pipes (Socket.IO) and wired the lights (real-time messaging). 'Day 35' paints the walls (UI), adds furniture (conversations), and puts in a doorbell (search)—now it's a home you can live in! You've gone from techy backend stuff to a full app you can show off. Pretty awesome, huh?",
      
        ],
      },
        
    ],
  },




];

export default cheatsheetData;

/*   


   {
      taskId: "",
      content: [
        {
          title: "",
          subtitle: "",
          details: [
            "",
            "",
            "",
          ],
          image: "/images/cheatsheetImages/task/.webp"
        },
           {
          title: "",
          subtitle: "",
          details: [
            "",
            "",
            "",
          ],
          image: "/images/cheatsheetImages/task/.webp"
        },
           {
          title: "",
          subtitle: "",
          details: [
            "",
            "",
            "",
          ],
          image: "/images/cheatsheetImages/task/.webp"
        },
           {
          title: "",
          subtitle: "",
          details: [
            "",
            "",
            "",
          ],
          image: "/images/cheatsheetImages/task/.webp"
        },
           {
          title: "",
          subtitle: "",
          details: [
            "",
            "",
            "",
          ],
          image: "/images/cheatsheetImages/task/.webp"
        },
           {
          title: "",
          subtitle: "",
          details: [
            "",
            "",
            "",
          ],
          image: "/images/cheatsheetImages/task/.webp"
        },
      ],
    },



  */

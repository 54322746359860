// src/pages/Signup.js
import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import ParticlesBackground from "./ParticlesBackground";
import { StyledContainer } from "./AuthStyles";
import { useSpring, animated } from "@react-spring/web";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, sendEmailVerification, signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "../components/firebase";
import { toast } from "react-toastify";
import axios from "axios";
import AuthForm from "./AuthForm";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (password.length < 6) {
      toast.error("Password must be at least 6 characters long");
      return;
    }
  
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      // Send verification email
      await sendEmailVerification(user);
  
      // Sign out the user immediately
      await auth.signOut();
  
      // Create user in MongoDB
      await axios.post("https://projectschool.dev/.netlify/functions/create-user", {
        firebaseUid: user.uid,
        email: user.email,
      });
  
      toast.success("User signed up successfully! Please verify your email.");
      navigate("/login"); // Redirect to login page
    } catch (error) {
      toast.error("Email already in use. Please log in or choose another.");
      console.error(error);
    }
  };

  const onGoogleSignUp = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;

      await axios.post("https://projectschool.dev/.netlify/functions/create-user", {
        firebaseUid: user.uid,
        email: user.email,
      });

      toast.success("User signed up successfully!");
      navigate("/");
    } catch (error) {
      toast.error("Error: " + error.message);
      console.error(error);
    }
  };

  const formAnimation = useSpring({
    from: { opacity: 0, transform: "translateY(50px)" },
    to: { opacity: 1, transform: "translateY(0px)" },
    config: { tension: 300, friction: 10 },
  });

  return (
    <div style={{ position: "relative" }}>
      <Navbar />
      <ParticlesBackground />
      <StyledContainer>
        <animated.div style={formAnimation}>
          <AuthForm
            onSubmit={onSubmit}
            onGoogleAuth={onGoogleSignUp}
            isLogin={false}
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
          />
        </animated.div>
      </StyledContainer>
    </div>
  );
};

export default Signup;
import React from "react";
import Section1 from "./Section1";
import Navbar from "./Navbar";
import Footer from "./Footer";
import useAuth from "../Login/useAuth";
import ScrollToTopOnNavigation from "./ScrollToTopOnNavigation";

const Welcome = () => {
  const { isLoggedIn } = useAuth();
  return (
    <div style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <ScrollToTopOnNavigation />
      <Navbar />
      <Section1 isLoggedIn={isLoggedIn} />
      <Footer />
    </div>
  );
};

export default Welcome;
import React from "react";
import ReferenceCardList from "./ReferenceCardList";
import ReferenceTitle from "./ReferenceTitle";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ScrollToTopOnNavigation from "../components/ScrollToTopOnNavigation";

const Exercises = () => {
  return (
    <div>
      <ScrollToTopOnNavigation />
      <Navbar />
      <ReferenceTitle />
      <ReferenceCardList />
      <Footer />
    </div>
  );
};

export default Exercises;

const blogCards = [
  {
    id: "How-to-create-Authentication-Guard-Component",
    category: "React",
    title: "How to create Authentication Guard Component",
    extract:
      "Today we'll walk through the process of implementing authentication in a React application using Firebase and React Router. We'll create an AuthGuard component to manage the authentication state anr protect routes that require authentication, ensuring a seamless user experience.",
    date: "04 April 2024",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/3/30/React_Logo_SVG.svg/120px-React_Logo_SVG.svg.webp",
  },
  {
    id: "InsightTrack-Empowering-Your-Digital-Presence",
    category: "Info",
    title: "InsightTrack: Empowering Your Digital Presence",
    extract:
      "Google Analytics is a powerful web analytics service offered by Google that helps website owners and marketers track and analyze their website traffic and user behavior. By providing detailed insights into visitor demographics, acquisition channels, user engagement, and conversion metrics, Google Analytics enables businesses to make data-driven decisions to improve their online presence, optimize marketing strategies, and enhance the overall user experience.",
    date: "5 May 2024",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/7/77/GAnalytics.svg/220px-GAnalytics.svg.webp",
  },
  {
    id: "Mastering-JavaScript-Pagination",
    category: "JavaScript",
    title: "Mastering JavaScript Pagination",
    extract:
      "Discover the ins and outs of JavaScript pagination in this comprehensive guide. Learn the theory behind pagination, explore practical code examples, and uncover where and why pagination shines in web development. From enhancing performance to improving user experience, pagination is a powerful technique you don't want to overlook.",
    date: "8 May 2024",
    image: "/images/articleImg/3/title.webp",
  },
  {
    id: "Going-Live-with-Stripe-in-Your-React-Project",
    category: "Info",
    title: "Going Live with Stripe in Your React Project",
    extract:
      "Transitioning your React project from Stripe's test environment to the live environment is a crucial step to start processing real payments. This process ensures that your application is fully prepared to handle secure transactions in a production setting.",
    date: "23 June 2024",
    image: "/images/articleImg/4/title.webp",
  },
  {
    id: "Guide-to-Implementing-Git-in-Visual-Studio-Code",
    category: "Info",
    title: "Guide to Implementing Git in Visual Studio Code",
    extract:
      "This step-by-step guide provides detailed instructions on installing Git and Visual Studio Code, configuring Git, managing repositories, and performing common Git operations such as staging, committing, and pushing changes to a remote repository. Ideal for beginners, this guide ensures a smooth start to using Git in your development workflow.",
    date: "13 July 2024",
    image: "/images/articleImg/5/title.webp",
  },
  {
    id: "EADDRINUSE-error",
    category: "Info",
    title: "EADDRINUSE Error",
    extract:
      "This guide provides detailed steps to resolve the EADDRINUSE error on Windows, which occurs when the port you're trying to use is already occupied by another process. You'll learn how to identify and kill the process using the port, or change the port to get your application running again.",
    date: "28 January 2025",
    image: "/images/articleImg/6/title.webp",
  },
  {
    id: "How-to-Run-a-React-Frontend-and-NodeJS-Backend-Simultaneously-with-One-Command",
    category: "Info",
    title:
      "How to Run a React Frontend and Node.js Backend Simultaneously with One Command",
    extract:
      "Streamline your full-stack development workflow by running your React frontend and Node.js backend with a single command. Learn how to use tools like `concurrently`, custom scripts, or `npm-run-all` to save time and simplify your process.",
    date: "8 February 2025",

    image: "/images/articleImg/7/cover.webp",
  },
  {
    id: "How-to-Test-Webhooks-Using-Stripe-CLI",
    category: "Info",
    title: "How to Test Webhooks Using Stripe CLI: A Comprehensive Guide",
    extract:
      "Testing webhooks is essential for building reliable applications with Stripe, but it can be tricky without the right tools. This guide provides a step-by-step walkthrough on using the Stripe CLI to test webhooks locally, debug issues, and even handle scenarios like Heroku backends or Windows Defender blocking the CLI. By the end, you'll be equipped to test webhooks confidently and ensure your integration is production-ready.",
    date: "15 February 2025",

    image: "/images/articleImg/8/8cover.webp",
  },
  {
    id: "A-Comprehensive-Guide-to-PropTypes-in-React",
    category: "Info",
    title: "A Comprehensive Guide to PropTypes in React",
    extract:
      "PropTypes in React is a runtime type-checking tool that ensures components receive the correct data types for their props, reducing bugs and improving maintainability. This guide covers everything from installation and basic usage to advanced techniques and a comparison with TypeScript, helping developers understand why and how to use PropTypes effectively.",
    date: "28 February 2025",

    image: "/images/articleImg/9/9cover.webp",
  },
  {
    "id": "The-End-of-Create-React-App-and-What-to-Use-Next",
    "category": "Info",
    "title": "The End of Create React App and What to Use Next",
    "extract": "Create React App is officially deprecated as of 2025, marking the end of an era for React developers. This in-depth guide explores why CRA faded, its limitations, and the best modern alternatives like Vite, Next.js, and Rspack. With detailed comparisons, pros and cons, and practical examples, discover which tool suits your next React project—whether you're building SPAs, static sites, or full-stack apps.",
    "date": "07 March 2025",
  
    image: "/images/articleImg/10/second.webp",
  },
];

/*

{
  id: "",
  category: "Info",
  title: "",
  extract: "",
   date: "28 February 2025",

  image: "/images/articleImg//.webp",
},

*/




















const articles = [
  {
    id: "How-to-create-Authentication-Guard-Component",
    title: "How to create Authentication Guard Component",
    image: "/images/articleImg/AuthGuard1.webp",
    paragraphs: [
      {
        type: "text",
        content: `Setup Firebase Authentication: Ensure you have set up Firebase Authentication properly. This includes setting up a Firebase project, enabling the Authentication service, and configuring sign-in methods (like email/password, Google, etc.). Create Firebase Configuration File: Ensure you have a Firebase configuration file (usually named firebase.html or similar) where you initialize Firebase with your configuration.`,
      },
      {
        type: "text",
        content: `Create an AuthGuard.js file. This component will handle the authentication logic.`,
      },
      {
        type: "text",
        content: `Inside the AuthGuard component:`,
      },
      {
        type: "list",
        items: [
          "Import necessary modules from React and useNavigate from react-router-dom.",
          "Import the 'auth' object from './firebase' file.",
          "Define a functional component AuthGuard that takes 'children' as a prop.",
          "Initialize navigate function using the useNavigate hook from react-router-dom.",
          "Initialize 'isLoggedIn' state variable with false using useState hook.",
        ],
      },
      {
        type: "text",
        content: `Implement the useEffect hook:`,
      },
      {
        type: "list",
        items: [
          "Call onAuthStateChanged method on 'auth' object to listen for authentication state changes.",
          "Update the 'isLoggedIn' state based on whether the user (from onAuthStateChanged) is null or not.",
          "If 'isLoggedIn' is false, redirect the user to the login page using navigate('/login').",
        ],
      },
      {
        type: "text",
        content: `Ensure useEffect has the correct dependencies (auth, navigate) specified.`,
      },
      {
        type: "text",
        content: `In your return statement:`,
      },
      {
        type: "list",
        items: [
          "If isLoggedIn is true, render the children (components wrapped inside AuthGuard).",
          "If isLoggedIn is false, return null to prevent rendering unauthorized content.",
        ],
      },
    ],
  },
  {
    id: "InsightTrack-Empowering-Your-Digital-Presence",
    title: "InsightTrack: Empowering Your Digital Presence",
    image:
      "https://www.themexpert.com/images/blog/how_add_google-analytics/image9.png",
    paragraphs: [
      {
        type: "text",
        content: `Having Google Analytics installed on your website is beneficial because it provides valuable insights into your website's performance and visitor behavior. It allows you to track key metrics such as website traffic, user engagement, conversion rates, and more, helping you make informed decisions to optimize your website, improve user experience, and ultimately achieve your business goals.`,
      },
      {
        type: "list",
        items: [
          "Go to the Google Analytics Website: Visit the Google Analytics website by typing 'Google Analytics' into your browser's search bar or directly navigating to analytics.google.com.",
          "Sign In or Create an Account: If you already have a Google account (Gmail, Google Drive, etc.), you can sign in with those credentials. If not, you'll need to create a Google account by clicking on the 'Create account' link and following the instructions.",
          "Start Setting Up Google Analytics: Once you're signed in, click on the 'Start for free' button. This will begin the process of setting up your Google Analytics account.",
          "Set Up Your Property: After clicking 'Start for free', you'll be prompted to set up your first property. A property represents your website, app, or other digital asset you want to track with Google Analytics. Click on the 'Web' option if you're setting up analytics for a website.",
          "Enter Property Details: Enter the details for your website, such as the website name, URL, industry category, and reporting time zone.",
          "Get Tracking ID: After entering your property details, you'll be presented with a Google Analytics tracking ID. This ID is a unique code that you'll need to add to your website's code so Google can track your site's traffic. You can copy this tracking ID or directly install the Google Analytics tracking code on your website.",
          "Install Tracking Code: To track your website's traffic accurately, you'll need to install the Google Analytics tracking code on every page of your website. You can either manually add the tracking code to your website's HTML or use a website platform like WordPress that offers plugins for easy integration.",
          "Verify Tracking Installation: Once you've installed the tracking code, return to the Google Analytics interface and click on the 'Send test traffic' button. This will send test data to Google Analytics to verify that the tracking code is installed correctly.",
          "Explore Reports: Once you've set up your Google Analytics account and verified that tracking is working, you can start exploring the reports. Google Analytics provides a wealth of data about your website's visitors, including information about where they're coming from, what pages they're visiting, and how they're interacting with your site.",
        ],
      },
    ],
  },
  {
    id: "Mastering-JavaScript-Pagination",
    title: "Mastering JavaScript Pagination",
    image: "/images/articleImg/3/logo.webp",
    paragraphs: [
      {
        type: "text",
        content: `Mastering JavaScript Pagination: Theory, Implementation, and Best Practices In the dynamic landscape of web development, efficient data management and presentation are paramount. One common challenge developers face is efficiently displaying large datasets while maintaining user experience. Enter JavaScript pagination—a technique that not only enhances performance but also improves usability by breaking down voluminous data into digestible chunks. In this comprehensive guide, we'll delve into the theory behind pagination, provide practical code examples, and explore its applications across various scenarios.`,
      },
      {
        type: "list",
        items: [
          "Pagination, in essence, involves dividing content into discrete pages, typically with a limited number of items per page. This approach offers several benefits:",
          "Enhanced Performance: Loading a large dataset in a single go can strain server resources and lead to sluggish user experiences. Pagination mitigates this issue by distributing data across multiple pages, resulting in faster load times.",
          "Improved User Experience: Navigating through pages is intuitive for users, allowing them to consume content at their own pace without feeling overwhelmed.",
          "Optimized Bandwidth Usage: By fetching only the necessary data for each page, pagination reduces bandwidth consumption, making it ideal for users with limited internet connectivity.",
        ],
      },
      {
        type: "text",
        content: `Let's illustrate pagination implementation using HTML, CSS and JavaScript. Consider a scenario where we have an array of items and aim to display them in paginated form.`,
      },
      {
        type: "image",
        src: "/images/articleImg/3/pic1.webp",
        alt: "Pic1",
      },
      {
        type: "image",
        src: "/images/articleImg/3/pic2.webp",

        alt: "Pic2",
      },
      {
        type: "image",
        src: "/images/articleImg/3/pic3.webp",

        alt: "Pic3",
      },
      {
        type: "image",
        src: "/images/articleImg/3/pic4.webp",

        alt: "Pic4",
      },
      {
        type: "text",
        content: `In this example, we start by defining an array of items. The displayItems function takes care of rendering items for the current page, while renderPaginationButtons generates pagination controls. Clicking on a pagination button triggers the display of corresponding items.`,
      },
      {
        type: "text",
        content: `Where to use pagination:`,
      },
      {
        type: "list",
        items: [
          "Pagination finds applications across various domains, including:",
          "E-commerce Websites: Displaying products in paginated grids allows users to navigate through extensive catalogs conveniently.",
          "Blogs and Articles: Long-form content can be divided into pages, facilitating easier reading and navigation.",
          "Data Tables: In applications dealing with tabular data, pagination enhances readability and performance by splitting data into manageable chunks.",
          "Search Results: Search engines often paginate results to present them in a structured and navigable manner.",
        ],
      },
      {
        type: "text",
        content: `In conclusion, JavaScript pagination is a versatile tool for managing and presenting data in web applications. By understanding its theory, implementing it effectively, and leveraging it across various contexts, developers can significantly enhance user experience and optimize performance.`,
      },
    ],
  },
  {
    id: "Going-Live-with-Stripe-in-Your-React-Project",
    title: "Going Live with Stripe in Your React Project",
    image: "/images/articleImg/4/title.webp",
    paragraphs: [
      {
        type: "text",
        content: `To transition your React project from using the Stripe test environment to the live environment, you'll need to make a few changes and take some steps to ensure your application can handle real payments securely.`,
      },
      {
        type: "text",
        content: `Stripe provides separate API keys for test and live environments. You need to replace the test API keys in your React project with the live API keys.`,
      },
      {
        type: "list",
        items: [
          "Log in to your Stripe account.",
          "Go to the Dashboard.",
          "Navigate to Developers > API keys.",
          "Copy your live Publishable key and Secret key.",
          "Replace the test keys with the live keys in your React project. Typically, this means updating your Stripe initialization code or configuration file where the API keys are set.",
        ],
      },
      {
        type: "text",
        content: `If your application uses Stripe webhooks for handling events like payment success or failure, you'll need to ensure that your live webhook endpoints are set up and can handle live events.`,
      },
      {
        type: "list",
        items: [
          "In the Stripe Dashboard, under Developers > Webhooks, add and configure your live webhook endpoints (e.g., https://yourdomain.com/stripe-webhook). Make sure these endpoints point to your live server or API.",
          "If your React project communicates with a backend (Node.js, Django, etc.) that handles Stripe webhooks, update the webhook handling logic to verify live events and update your application accordingly.",
        ],
      },
      {
        type: "text",
        content: `Before going fully live, thoroughly test your application in the live environment. You can do this with real transactions, but Stripe provides a way to test without actually charging a card.`,
      },
      {
        type: "list",
        items: [
          "Use Stripe's test card numbers for various scenarios (e.g., success, failure).",
          "Set the amount to a low value (e.g., $0.50) to avoid significant charges during testing.",
          "Ensure all parts of your checkout process (payment form, payment confirmation, error handling) work correctly with live API keys.",
        ],
      },
      {
        type: "text",
        content: `During testing and after going live, monitor your application for any errors or edge cases.`,
      },
      {
        type: "list",
        items: [
          "Handle API Errors: Stripe may return different error codes in live mode compared to test mode. Ensure your application can handle these gracefully.",
          "Check for Edge Cases: Test scenarios such as declined cards, expired cards, insufficient funds, and other common issues that can occur during payments.",
        ],
      },
      {
        type: "text",
        content: `Ensure your application complies with PCI-DSS (Payment Card Industry Data Security Standard) requirements if you're handling card information directly. Stripe handles much of the compliance burden through their payment forms, but you should still be aware of any relevant regulations.`,
      },

      {
        type: "text",
        content: `Refer to Stripe's documentation for detailed guides and best practices for going live. If you encounter any issues or have questions, Stripe's support team is also available to help.`,
      },
      {
        type: "text",
        content: `After completing these steps, your React project will be ready to securely process real payments with Stripe, ensuring a seamless and reliable experience for your users in a live production environment.`,
      },
    ],
  },
  {
    id: "Guide-to-Implementing-Git-in-Visual-Studio-Code",
    title: "Git with VSCode",
    image: "/images/articleImg/5/gitLogo.webp",
    paragraphs: [
      {
        type: "text",
        content: `Step 1: Install Git`,
      },

      {
        type: "list",
        items: [
          "Go to the Git website. (https://git-scm.com).",
          "Click on 'Download' and choose the version that matches your operating system (Windows, macOS, or Linux).",
          "Follow the instructions to download the installer.",
          "Run the downloaded installer.",
          "Follow the setup instructions. You can use the default settings for most options unless you have specific requirements.",
          "Open your terminal ( in your VSCode ) or command prompt.",
          "Type 'git --version' or ( 'git -v' ) and press Enter.",
          "You should see the installed Git version, confirming that Git is installed successfully.",
        ],
      },
      {
        type: "text",
        content: `Step 2: Install Visual Studio Code`,
      },

      {
        type: "list",
        items: [
          "Go to the VS Code website. (https://code.visualstudio.com).",
          "Click on 'Download' and choose the version that matches your operating system.",
          "Run the downloaded installer.",
          "Follow the setup instructions.",
        ],
      },
      {
        type: "text",
        content: `Step 3: Configure Git in VS Code`,
      },

      {
        type: "list",
        items: [
          "Open Visual Studio Code.",
          "Go to the top menu and click on 'Terminal' > 'New Terminal'.",
          "Set your Git username by typing: git config --global user.name 'Your Name' and press Enter.",
          "Set your Git email by typing: git config --global user.email 'you@example.com' and press Enter.",
        ],
      },
      {
        type: "text",
        content: `Step 4: Initialize a Git Repository`,
      },

      {
        type: "list",
        items: [
          "Open the folder containing your project files in VS Code by clicking on 'File' > 'Open Folder' and selecting your project folder.",
          "Open the terminal in VS Code (Terminal > New Terminal).",
          "Navigate to your project folder if not already there. (type: cd projectFolderName)",
          "Type 'git init' and press Enter to initialize a new Git repository.",
        ],
      },
      {
        type: "text",
        content: `Step 5: Make Changes and Commit`,
      },

      {
        type: "list",
        items: [
          "Create or edit files in your project folder using the VS Code editor.",
          "Open the terminal in VS Code.",
          "Type git commit -m 'Your commit message' and press Enter to commit the changes with a descriptive message.",
        ],
      },
      {
        type: "text",
        content: `Step 6: Push to a Remote Repository`,
      },

      {
        type: "list",
        items: [
          "Go to 'GitHub', 'GitLab', or 'Bitbucket' and create a new repository.",
          "In the terminal, type git remote add origin https://github.com/your-username/your-repository.git (replace the URL with your repository's URL) and press Enter.",
          "Type git push -u origin master and press Enter to push your local changes to the remote repository.",
        ],
      },
      {
        type: "text",
        content: `Step 7: Pull Changes from Remote Repository`,
      },

      {
        type: "list",
        items: [
          "In the terminal, type git pull origin master and press Enter to pull the latest changes from the remote repository to your local repository.",
        ],
      },
      {
        type: "text",
        content: `Step 8: Viewing Git Changes in VS Code`,
      },

      {
        type: "list",
        items: [
          "Click on the Source Control icon in the Activity Bar on the side of the VS Code window (or press Ctrl+Shift+G).",
          "In the Source Control view, you can see the list of changes, staged changes, and commit history.",
        ],
      },
      {
        type: "text",
        content: `Common Git Commands Cheat Sheet`,
      },

      {
        type: "list",
        items: [
          "'git status' - Show the working directory status.",
          "'git add .' - Add all changes to staging.",
          "'git commit -m 'message'' - Commit changes with a message. ( without message it WILL NOT commit ).",
          "'git push' - Push changes to remote repository.",
          "'git pull' - Pull changes from remote repository.",
          "'git checkout branch-name' - Switch to a branch.",
          "'git merge branch-name' - Merge a branch into the current branch.",
        ],
      },
      {
        type: "text",
        content: `Use my example as a guide`,
      },

      {
        type: "list",
        items: ["Make changes in your code."],
      },
      {
        type: "image",
        src: "/images/articleImg/5/blog1.webp",
        alt: "Pic1",
      },
      {
        type: "list",
        items: ["Press Source Control icon."],
      },
      {
        type: "image",
        src: "/images/articleImg/5/blog2.webp",
        alt: "Pic2",
      },
      {
        type: "list",
        items: ["Press '+' to Stage Changes"],
      },
      {
        type: "image",
        src: "/images/articleImg/5/blog3.webp",
        alt: "Pic3",
      },
      {
        type: "list",
        items: [
          "Type message. ( without message it will not commit ).",
          "Then press Commit button.",
        ],
      },
      {
        type: "image",
        src: "/images/articleImg/5/blog4.webp",
        alt: "Pic4",
      },
      {
        type: "list",
        items: ["Press three dots (...) next to main or master."],
      },
      {
        type: "image",
        src: "/images/articleImg/5/blog5.webp",
        alt: "Pic5",
      },
      {
        type: "list",
        items: ["Press Push."],
      },
      {
        type: "image",
        src: "/images/articleImg/5/blog6.webp",
        alt: "Pic6",
      },
      {
        type: "list",
        items: [
          "In your Github account navigate to repository.",
          "There you will find all the changes you made.",
        ],
      },
      {
        type: "image",
        src: "/images/articleImg/5/blog7.webp",

        alt: "Pic7",
      },
    ],
  },
  {
    id: "EADDRINUSE-error",
    title: "EADDRINUSE error",
    image: "/images/articleImg/6/blog1.webp",
    paragraphs: [
      {
        type: "text",
        content:
          "Did you experience the dreaded EADDRINUSE error when running your Node.js app? This happens when the port you're trying to use is already occupied by another process, preventing your application from starting. Instead of restarting your computer or guessing which process is causing the issue, this guide will show you exactly how to identify and free up the port, allowing you to get back to coding without frustration.",
      },
      {
        type: "image",
        src: "/images/articleImg/6/blog4.webp",
        alt: "EADDRINUSE error",
      },
      {
        type: "text",
        content:
          "Port Freezer is a simple yet powerful utility tool designed to help developers resolve the common EADDRINUSE error on Windows. When working with Node.js applications, you may encounter this error when the port you're trying to use is already occupied by another process. This tool walks you through easy-to-follow steps to identify and free up the port, or alternatively, change to a new port—ensuring that your application runs smoothly without interruption.",
      },
      {
        type: "title",
        content: "Step 1: Identify the Port in Use",
      },
      {
        type: "list",
        items: [
          "Open Command Prompt (CMD): Press Win + R, type cmd, and hit Enter. Run as Administrator: Right-click on Command Prompt and select 'Run as administrator'.",
          "Check for the port in use (e.g., 5000) using the netstat command:",
        ],
      },
      {
        type: "code",
        content: `netstat -ano | findstr :5000`,
      },
      {
        type: "text",
        content: "Look for output like:",
      },

      {
        type: "image",
        src: "/images/articleImg/6/blog2.webp",
        alt: "Command promt",
      },
      {
        type: "text",
        content:
          "Note the PID (Process ID) from the last column (e.g., 14500).",
      },
      {
        type: "title",
        content: "Step 2: Kill the Process Using the Port",
      },
      {
        type: "list",
        items: ["Terminate the process with the noted PID:"],
      },
      {
        type: "code",
        content: `taskkill /PID 14500 /F`,
      },
      {
        type: "list",
        items: ["Replace 14500 with the actual PID from Step 1."],
      },
      {
        type: "text",
        content: "You should see:",
      },
      {
        type: "image",
        src: "/images/articleImg/6/blog3.webp",
        alt: "Command promt",
      },
      {
        type: "list",
        items: [
          "Verify that the port is now free by running the netstat command:",
        ],
      },
      {
        type: "code",
        content: `netstat -ano | findstr :5000`,
      },
      {
        type: "list",
        items: ["If no output is returned, the port is free."],
      },
      {
        type: "title",
        content: "Step 3: Change the Port (Optional)",
      },
      {
        type: "text",
        content:
          "If you cannot free the port or prefer to use a different one.",
      },
      {
        type: "list",
        items: [
          "Open your project directory in a text editor (e.g., VSCode).",
          "Modify the port settings ( if using React, it's usually in package.json or .env file).",
          "Add a .env file in the project root (if it doesn't exist).",
          "Inside .env, add: PORT=3001 (or any available port number).",
          "Replace 5001 with the new port you want.",
        ],
      },
      {
        type: "list",
        items: [
          "Modify the port settings ( If using Express or another backend framework ).",
          "Change the port variable in your code:",
        ],
      },
      {
        type: "code",
        content: `const PORT = process.env.PORT || 5001;\napp.listen(PORT, () => console.log(\`Server running on port \${PORT}\`));
        `,
      },
      {
        type: "list",
        items: ["Save the changes and restart the application."],
      },
      {
        type: "title",
        content: "Step 4: Optional: Automate the Process (PowerShell Script)",
      },
      {
        type: "text",
        content:
          "If this happens frequently, create a script to automate the process",
      },
      {
        type: "list",
        items: ["Open a text editor and paste this:"],
      },
      {
        type: "code",
        content: `$port = 5000
      $pid = (Get-NetTCPConnection -LocalPort $port).OwningProcess
      if ($pid) {
          Stop-Process -Id $pid -Force
          Write-Host "Port $port is now free."
      } else {
          Write-Host "Port $port is already free."
      }`,
      },
      {
        type: "list",
        items: [
          "Save it as free-port.ps1.",
          "Run the script by right-clicking and selecting 'Run with PowerShell'.",
        ],
      },
      {
        type: "title",
        content: "Step 4: Final Notes",
      },
      {
        type: "list",
        items: [
          "Always stop the server manually before closing VSCode using Ctrl + C.",
          "If the port is still in use, restart your computer to free it.",
          "Use the Port Freezer tool whenever you encounter the EADDRINUSE error.",
          "Remember to update the port number in your project configuration files.",
        ],
      },
      {
        type: "title",
        content: "Conclusion",
      },
      {
        type: "text",
        content:
          "With Port Freezer, you can easily resolve the EADDRINUSE error and free up ports that are blocking your application. Whether you're debugging or simply managing your development environment, this tool ensures your Node.js projects run seamlessly. By following the simple steps provided, you can avoid common port-related issues and focus more on building your app. Keep this tool handy for a smoother, more efficient development process!",
      },
    ],
  },

  {
    id: "How-to-Run-a-React-Frontend-and-NodeJS-Backend-Simultaneously-with-One-Command",
    title:
      "How to Run a React Frontend and Node.js Backend Simultaneously with One Command",
    image: "/images/articleImg/7/inside.webp",
    paragraphs: [
      {
        type: "text",
        content: `If you're working on a full-stack project with a React frontend and a Node.js backend, you've probably found yourself opening two terminal windows: one to start the backend server and another to start the frontend development server. While this works, it's not the most efficient way to manage your workflow.`,
      },
      {
        type: "text",
        content: `In this guide, I'll show you how to simplify this process by running both your backend and frontend with a single command using npm start. Whether your backend is in a separate folder or your frontend is in the root directory (created with create-react-app), this solution will work seamlessly.`,
      },
      {
        type: "title",
        content: "Why Use a Single Command?",
      },
      {
        type: "list",
        items: [
          "Saves time: No need to switch between terminals or run multiple commands manually.",
          "Improves workflow: Streamlines your development process, especially when working on both the frontend and backend simultaneously.",
          "Easy to share: Makes it simpler for collaborators to get your project up and running.",
        ],
      },
      {
        type: "title",
        content: "Prerequisites",
      },
      {
        type: "text",
        content: `Before we begin, ensure you have:`,
      },
      {
        type: "list",
        items: [
          "A React frontend (created with create-react-app).",
          "A Node.js backend (e.g., an Express server).",
          "Both projects in the same repository (e.g., backend in a backend folder and frontend in the root).",
        ],
      },
      {
        type: "title",
        content: "Solution 1: Using concurrently",
      },
      {
        type: "text",
        content: `The easiest way to run both your backend and frontend simultaneously is by using the concurrently package. It allows you to run multiple commands in parallel.`,
      },
      {
        type: "list",
        items: ["Step 1: Install concurrently"],
      },
      {
        type: "text",
        content: "Open your terminal and run:",
      },
      {
        type: "code",
        content: `
        npm install concurrently --save-dev
        `,
      },
      {
        type: "list",
        items: ["Step 2: Update package.json"],
      },
      {
        type: "text",
        content:
          "In the root of your project, modify the scripts section of your package.json file:",
      },
      {
        type: "code",
        content: `
        "scripts": {
          "start": "concurrently \\"npm run start:backend\\" \\"npm run start:frontend\\"",
          "start:backend": "cd backend && node server.js",
          "start:frontend": "react-scripts start",
        }
        `,
      },
      {
        type: "list",
        items: ["Step 3: Run the project"],
      },
      {
        type: "text",
        content: "Now, simply run:",
      },
      {
        type: "code",
        content: `
        npm start        
        `,
      },
      {
        type: "text",
        content:
          "This will start both your backend and frontend servers in one go!",
      },
      {
        type: "title",
        content: "Solution 2: Using a Custom Node.js Script",
      },
      {
        type: "text",
        content:
          "If you prefer not to use concurrently, you can write a custom Node.js script to achieve the same result.",
      },
      {
        type: "list",
        items: ["Step 1: Create a start.js file"],
      },
      {
        type: "text",
        content:
          "In the root of your project, create a file called start.js and add the following code:",
      },
      {
        type: "code",
        content: `
        const { exec } = require('child_process');

        // Start backend
        exec('cd backend && node server.js', (err, stdout, stderr) => {
          if (err) {
            console.error(\`Backend error: \${stderr}\`);
            return;
          }
          console.log(\`Backend: \${stdout}\`);
        });

        // Start frontend
        exec('npm start', (err, stdout, stderr) => {
          if (err) {
            console.error(\`Frontend error: \${stderr}\`);
            return;
          }
          console.log(\`Frontend: \${stdout}\`);
        });      
        `,
      },
      {
        type: "title",
        content: "Update package.json",
      },
      {
        type: "text",
        content: "Add a script to your package.json to run the custom script:",
      },
      {
        type: "code",
        content: `
        "scripts": {
          "start": "node start.js"
        }
        `,
      },
      {
        type: "list",
        items: ["Step 3: Run the project"],
      },
      {
        type: "text",
        content: "Now, simply run:",
      },
      {
        type: "code",
        content: `
        npm start        
        `,
      },
      {
        type: "text",
        content: "Your backend and frontend will now start simultaneously!",
      },
      {
        type: "title",
        content: "Solution 3: Using npm-run-all",
      },
      {
        type: "text",
        content: `Another great alternative is the npm-run-all package, which allows you to run multiple npm scripts in parallel or sequentially.`,
      },
      {
        type: "list",
        items: ["Step 1: Install npm-run-all"],
      },
      {
        type: "text",
        content: "Run the following command in your terminal:",
      },
      {
        type: "code",
        content: `
        npm install npm-run-all --save-dev
        `,
      },
      {
        type: "list",
        items: ["Step 2: Update package.json"],
      },
      {
        type: "text",
        content: "Modify the scripts section of your package.json file:",
      },
      {
        type: "code",
        content: `
        "scripts": {
          "start": "npm-run-all --parallel start:backend start:frontend",
          "start:backend": "cd backend && node server.js",
          "start:frontend": "react-scripts start",
        }
        `,
      },
      {
        type: "list",
        items: ["Step 3: Run the project"],
      },
      {
        type: "text",
        content: "Now, just run:",
      },
      {
        type: "code",
        content: `
        npm start        
        `,
      },
      {
        type: "text",
        content: "Both your backend and frontend will start together!",
      },
      {
        type: "title",
        content: "Which Solution Should You Use?",
      },
      {
        type: "list",
        items: [
          "concurrently: Best for simplicity and ease of use.",
          "Custom script: Great if you want more control or prefer not to add additional dependencies.",
          "npm-run-all: Ideal if you're already familiar with npm scripts and want a clean, declarative approach.",
        ],
      },
      {
        type: "title",
        content: "Bonus Tips",
      },
      {
        type: "list",
        items: [
          "Environment Variables: If your backend and frontend require different environment variables, make sure to configure them properly (e.g., using .env files).",
          "Error Handling: Ensure your scripts handle errors gracefully, especially if one process crashes.",
          "Production Build: For deployment, you'll likely build your React app (npm run build) and serve it using your backend (e.g., with express.static).",
        ],
      },
      {
        type: "title",
        content: "Conclusion",
      },
      {
        type: "text",
        content:
          "Running your React frontend and Node.js backend with a single command is a game-changer for your development workflow. Whether you choose concurrently, a custom script, or npm-run-all, you'll save time and effort while keeping your terminal clutter-free. Give it a try in your next project, and let me know how it goes! If you have any questions or encounter any issues, please feel free to reach out or post them in the live chat. Happy coding!",
      },
    ],
  },
  {
    id: "How-to-Test-Webhooks-Using-Stripe-CLI",
    title: "How to Test Webhooks Using Stripe CLI",
    image: "/images/articleImg/8/8title.webp",
    paragraphs: [
      {
        type: "text",
        content: `Webhooks are a critical component of modern web applications, especially when integrating with payment systems like Stripe. They enable your application to receive real-time notifications about events such as successful payments, failed charges, or subscription updates. However, testing webhooks can be challenging, particularly when developing locally or in a staging environment. This guide will walk you through the process of testing webhooks using the Stripe CLI in a detailed and structured manner, ensuring you can handle any issues that arise.`,
      },
      {
        type: "title",
        content: "What is Stripe CLI?",
      },
      {
        type: "text",
        content: `The Stripe Command Line Interface (CLI) is a powerful tool that allows developers to interact with Stripe directly from their terminal. It provides a suite of commands to manage Stripe resources, simulate payments, and, most importantly, test webhooks locally.`,
      },
      {
        type: "text",
        content: `With the Stripe CLI, you can:`,
      },
      {
        type: "list",
        items: [
          "Forward Stripe webhook events to your local server.",
          "Trigger test events to simulate real-world scenarios.",
          "Debug and inspect webhook payloads.",
        ],
      },
      {
        type: "title",
        content: "Prerequisites",
      },
      {
        type: "text",
        content: `Before diving into testing webhooks, ensure you have the following set up:`,
      },
      {
        type: "list",
        items: [
          "Stripe Account: You need a Stripe account. If you don't have one, sign up at stripe.com.",
          "Stripe CLI Installed: Download and install the Stripe CLI for your operating system from the official Stripe CLI documentation.",
          "Local Development Server: You should have a local server running that can receive webhook requests (e.g., a Node.js, Python, or Ruby server).",
          "Webhook Endpoint: Your server should have an endpoint (e.g., /webhook) that listens for incoming Stripe webhook events.",
        ],
      },
      {
        type: "title",
        content: "Step 1: Install and Authenticate Stripe CLI",
      },
      {
        type: "text",
        content: `Install the CLI: `,
      },
      {
        type: "list",
        items: [
          "Follow the installation instructions for your operating system from the Stripe CLI docs.",
        ],
      },
      {
        type: "text",
        content: `Authenticate the CLI: `,
      },
      {
        type: "list",
        items: ["Run the following command to log in to your Stripe account:"],
      },
      {
        type: "code",
        content: `
        stripe login
        `,
      },
      {
        type: "text",
        content: `This will open a browser window where you can authenticate the CLI with your Stripe account. Once authenticated, the CLI will display a pairing code and confirm the login.`,
      },
      {
        type: "title",
        content: "Step 2: Forward Webhooks to Your Local Server",
      },
      {
        type: "text",
        content: `The Stripe CLI allows you to forward webhook events from Stripe to your local server. This is especially useful for testing webhooks during development.`,
      },
      {
        type: "text",
        content: `Start the Webhook Forwarding:`,
      },
      {
        type: "list",
        items: ["Run the following command to start forwarding webhooks:"],
      },
      {
        type: "code",
        content: `
        stripe listen --forward-to localhost:3000/webhook
        `,
      },
      {
        type: "list",
        items: [
          "Replace localhost:3000/webhook with the URL of your local webhook endpoint.",
        ],
      },
      {
        type: "text",
        content: `Capture the Webhook Secret:`,
      },
      {
        type: "list",
        items: [
          "When you run the stripe listen command, the CLI will display a webhook signing secret. This secret is used to verify that incoming webhooks are genuinely from Stripe. Make sure to copy and store this secret securely.",
        ],
      },
      {
        type: "list",
        items: ["Example output:"],
      },
      {
        type: "code",
        content: `
        > Ready! Your webhook signing secret is whsec_xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        `,
      },
      {
        type: "text",
        content: `Important Note:`,
      },
      {
        type: "list",
        items: [
          "The webhook signing secret provided by the Stripe CLI is different from the one you use in production. When testing locally, you must use the secret provided by the CLI instead of your original production secret. This ensures that your local environment can correctly verify the webhook events forwarded by the CLI.",
        ],
      },
      {
        type: "text",
        content: `Verify the Webhook Signature:`,
      },
      {
        type: "list",
        items: [
          "In your server code, use the webhook signing secret to verify the authenticity of incoming webhook events. Here's an example in Node.js:",
        ],
      },
      {
        type: "code",
        content: `
      const stripe = require('stripe')('sk_test_xxxxxxxxxxxxxxxxxxxxxxxx');
      const express = require('express');
      const app = express();
      // Use express.raw middleware to parse the raw request body
      app.post('/webhook', express.raw({ type: 'application/json' }), (request, response) => {
        const sig = request.headers['stripe-signature'];
        const webhookSecret = 'whsec_xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'; 
        let event;
        try {
          event = stripe.webhooks.constructEvent(request.body, sig, webhookSecret);
        } catch (err) {
          return response.status(400).send(\`Webhook Error: \${err.message}\`);
        }
        // Handle the event
        switch (event.type) {
          case 'payment_intent.succeeded':
            const paymentIntent = event.data.object;
            console.log('PaymentIntent was successful!');
            break;
          case 'payment_method.attached':
            const paymentMethod = event.data.object;
            console.log('PaymentMethod was attached to a Customer!');
            break;
          // Handle other event types
          default:
            console.log(\`Unhandled event type \${event.type}\`);
        }
        response.json({ received: true });
      });
      app.listen(3000, () => console.log('Running on port 3000'));
        `,
      },
      {
        type: "title",
        content: "Step 3: Trigger Test Webhook Events",
      },
      {
        type: "text",
        content: `Now that your local server is ready to receive webhooks, you can use the Stripe CLI to trigger test events.`,
      },
      {
        type: "text",
        content: `List Available Events:`,
      },
      {
        type: "list",
        items: ["To see a list of all the events you can trigger, run:"],
      },
      {
        type: "code",
        content: `
         stripe trigger --list
         `,
      },
      {
        type: "text",
        content: `Trigger a Test Event:`,
      },
      {
        type: "list",
        items: [
          "Use the stripe trigger command to simulate a specific event. For example, to trigger a payment_intent.succeeded event, run:",
        ],
      },
      {
        type: "code",
        content: `
        stripe trigger payment_intent.succeeded
        `,
      },

      {
        type: "list",
        items: [
          "This will send a test webhook event to your local server. You should see the event being logged in your terminal and handled by your server.",
        ],
      },
      {
        type: "text",
        content: `Inspect the Event Payload:`,
      },
      {
        type: "text",
        content: `The Stripe CLI will display the payload of the triggered event in your terminal. You can use this to debug and ensure your server is processing the event correctly.`,
      },
      {
        type: "title",
        content: "Step 4: Debugging Webhooks",
      },
      {
        type: "text",
        content: `Testing webhooks can sometimes be challenging, especially if things don't work as expected. Here are some tips for debugging:`,
      },
      {
        type: "text",
        content: `Check the CLI Logs:`,
      },
      {
        type: "list",
        items: [
          "The Stripe CLI logs all forwarded webhook events. Look for any errors or issues in the logs.",
        ],
      },
      {
        type: "text",
        content: `Verify the Webhook Signature:`,
      },
      {
        type: "list",
        items: [
          "Ensure that your server is correctly verifying the webhook signature using the signing secret provided by the CLI.",
        ],
      },
      {
        type: "text",
        content: `Use Stripe Dashboard:`,
      },
      {
        type: "list",
        items: [
          "The Stripe Dashboard provides a Webhooks section where you can view the history of webhook events and their responses. This can be helpful for debugging.",
        ],
      },
      {
        type: "text",
        content: `Test Different Events:`,
      },
      {
        type: "list",
        items: [
          "gger different types of events to ensure your server can handle all scenarios.",
        ],
      },
      {
        type: "title",
        content: "Step 5: Automate Testing (Optional)",
      },
      {
        type: "text",
        content: `If you want to automate your webhook testing, you can integrate the Stripe CLI into your CI/CD pipeline or write scripts to trigger events programmatically. For example, you can use a shell script to trigger multiple events in sequence:`,
      },
      {
        type: "code",
        content: `
        #!/bin/bash
        stripe trigger payment_intent.succeeded
        stripe trigger payment_intent.payment_failed
        stripe trigger customer.subscription.created
        `,
      },
      {
        type: "title",
        content: "Step 6: Testing Webhooks with a Backend on Heroku",
      },
      {
        type: "text",
        content: `If your backend is hosted on Heroku, you can still use the Stripe CLI to test webhooks. Here's how:`,
      },
      {
        type: "text",
        content: `Expose Your Local Server:`,
      },
      {
        type: "list",
        items: [
          "Use a tool like ngrok to expose your local server to the internet. This will allow Stripe to send webhook events to your local machine.",
        ],
      },
      {
        type: "code",
        content: `
        ngrok http 3000
        `,
      },
      {
        type: "list",
        items: [
          "This will provide you with a public URL (e.g., https://xxxxxxxx.ngrok.io) that you can use as your webhook endpoint.",
        ],
      },
      {
        type: "text",
        content: `Update Your Webhook Endpoint:`,
      },
      {
        type: "list",
        items: [
          "In the Stripe Dashboard, update your webhook endpoint to use the ngrok URL (e.g., https://xxxxxxxx.ngrok.io/webhook).",
        ],
      },
      {
        type: "text",
        content: `Forward Webhooks:`,
      },
      {
        type: "list",
        items: ["Use the Stripe CLI to forward webhooks to your local server:"],
      },
      {
        type: "code",
        content: `
        stripe listen --forward-to https://xxxxxxxx.ngrok.io/webhook
        `,
      },
      {
        type: "text",
        content: `Trigger Test Events:`,
      },
      {
        type: "list",
        items: [
          "Use the stripe trigger command to send test events to your Heroku backend.",
        ],
      },
      {
        type: "title",
        content:
          "Step 7: Handling Windows Defender or Antivirus Issues (Windows Users)",
      },
      {
        type: "text",
        content: `If you're using Windows and encounter issues with Windows Defender or other antivirus software blocking the Stripe CLI (stripe.exe), you'll need to add the Stripe CLI as an exemption to allow it to run. Here's how:`,
      },
      {
        type: "text",
        content: `Open Windows Security:`,
      },
      {
        type: "list",
        items: [
          "Press Windows + I to open Settings.",
          "Go to Update & Security > Windows Security > Virus & Threat Protection.",
        ],
      },
      {
        type: "text",
        content: `Add an Exclusion:`,
      },
      {
        type: "list",
        items: [
          "Under Virus & Threat Protection, click on Manage Settings.",
          "Scroll down to Exclusions and click Add or Remove Exclusions.",
          "Click Add an Exclusion and choose Folder.",
          "Navigate to the directory where the Stripe CLI is installed (e.g., C:/Program Files/Stripe CLI) and add it as an exclusion.",
        ],
      },
      {
        type: "text",
        content: `Restart the Stripe CLI:`,
      },
      {
        type: "list",
        items: [
          "After adding the exclusion, restart the Stripe CLI and try running your commands again.",
        ],
      },
      {
        type: "title",
        content: "Common Errors and How to Resolve Them",
      },
      {
        type: "text",
        content: `Webhook Signature Verification Failed:`,
      },

      {
        type: "list",
        items: [
          "Cause: The webhook signing secret used in your server code does not match the one provided by the Stripe CLI.",
          "Solution: Ensure you are using the correct webhook signing secret provided by the CLI.",
        ],
      },
      {
        type: "text",
        content: `Webhook Endpoint Not Reachable:`,
      },

      {
        type: "list",
        items: [
          "Cause: Your local server is not running, or the endpoint is incorrect.",
          "Solution: Verify that your server is running and the endpoint URL is correct.",
        ],
      },
      {
        type: "text",
        content: `Event Not Handled:`,
      },

      {
        type: "list",
        items: [
          "Cause: Your server code does not handle the specific event type.",
          "Solution: Add a case in your event handling logic to process the event.",
        ],
      },
      {
        type: "text",
        content: `Payload Parsing Error:`,
      },

      {
        type: "list",
        items: [
          "Cause: The request body is not being parsed correctly.",
          "Solution: Ensure you are using the correct middleware to parse the raw request body.",
        ],
      },
      {
        type: "title",
        content: "Conclusion",
      },
      {
        type: "text",
        content: `Testing webhooks is a critical part of building robust applications with Stripe. The Stripe CLI makes this process seamless by allowing you to forward webhooks to your local server and trigger test events. By following the steps outlined in this guide, you can ensure that your webhook handling logic is reliable and ready for production.`,
      },
      {
        type: "text",
        content: `Remember to:`,
      },

      {
        type: "list",
        items: [
          "Use the stripe listen command to forward webhooks.",
          "Use the webhook signing secret provided by the CLI instead of your original production secret.",
          "Verify webhook signatures using the signing secret.",
          "Trigger test events with stripe trigger.",
          "Debug issues using the CLI logs and Stripe Dashboard.",
        ],
      },
      {
        type: "text",
        content: `With these tools and techniques, you'll be well-equipped to handle Stripe webhooks like a pro. Happy coding!`,
      },
    ],
  },
  {
    id: "A-Comprehensive-Guide-to-PropTypes-in-React",
    title: "A Comprehensive Guide to PropTypes in React",
    image: "/images/articleImg/9/1intro.webp",
    paragraphs: [
      {
        type: "text",
        content: `When building React applications, passing data between components is a common practice. However, ensuring that components receive the correct type and shape of data is crucial for preventing runtime errors and improving code maintainability. This is where PropTypes come into play. In this guide, we'll explore what PropTypes are, why they are useful, how to implement them, common use cases, advanced techniques, best practices, and even compare them to TypeScript for a better understanding.`,
      },
      {
        type: "title",
        content: "What Are PropTypes?",
      },
      {
        type: "text",
        content: `PropTypes is a built-in type-checking mechanism in React that helps validate the data passed as props to a component. By defining PropTypes, developers can enforce certain rules on the props, reducing potential bugs caused by incorrect data types.`,
      },
      {
        type: "text",
        content: `React provides PropTypes through the prop-types package, which must be installed separately. It helps ensure components receive props in the correct format, reducing the chances of unexpected behavior.`,
      },
      {
        type: "title",
        content: "Why Use PropTypes?",
      },
      {
        type: "list",
        items: ["1. Type Checking at Runtime"],
      },
      {
        type: "text",
        content: `Unlike TypeScript, which provides compile-time type checking, PropTypes validate the props at runtime in development mode. If an incorrect prop type is passed, React will display a warning in the console, making debugging easier.`,
      },
      {
        type: "list",
        items: ["2. Better Debugging"],
      },
      {
        type: "text",
        content: `Without PropTypes, tracking down issues related to incorrect props can be difficult. With PropTypes, React gives warnings whenever a component receives invalid data, allowing developers to catch errors early.`,
      },
      {
        type: "list",
        items: ["3. Self-Documenting Components"],
      },
      {
        type: "text",
        content: `PropTypes act as an inline documentation tool, making it clear what kind of data a component expects. This helps other developers (or even your future self) understand component requirements quickly.`,
      },
      {
        type: "list",
        items: ["4. Default Values for Props"],
      },
      {
        type: "text",
        content: `PropTypes allow setting default values for props, ensuring that components behave correctly even if some props are missing.`,
      },
      {
        type: "title",
        content: "Installing PropTypes",
      },
      {
        type: "text",
        content: `If you're using React 15.5 or later, you need to install the prop-types package separately:`,
      },
      {
        type: "code",
        content: `
        npm install prop-types
        `,
      },
      {
        type: "title",
        content: "How to Use PropTypes in React",
      },
      {
        type: "text",
        content: `Let's take a simple example of a UserCard component that displays a user's name, age, and email:`,
      },
      {
        type: "code",
        content: `
        import React from "react";
        import PropTypes from "prop-types";

        const UserCard = ({ name, age, email }) => {
          return (
            <div>
              <h2>{name}</h2>
              <p>Age: {age}</p>
              <p>Email: {email}</p>
            </div>
          );
        };

        UserCard.propTypes = {
          name: PropTypes.string.isRequired,
          age: PropTypes.number,
          email: PropTypes.string
        };

        UserCard.defaultProps = {
          age: 18, // Default age if not provided
          email: "Not Provided"
        };

        export default UserCard;
        `,
      },
      {
        type: "list",
        items: [
          "PropTypes.string.isRequired: Ensures name is a required string.",
          "PropTypes.number: Ensures age is a number (optional).",
          "PropTypes.string: Ensures email is a string (optional).",
          "defaultProps: Provides default values if certain props are missing.",
        ],
      },
      {
        type: "title",
        content: "Common PropTypes Validators",
      },
      {
        type: "text",
        content: `Here are some commonly used PropTypes validators:`,
      },
      {
        type: "list",
        items: [
          "PropTypes.string: Ensures the prop is a string.",
          "PropTypes.number: Ensures the prop is a number.",
          "PropTypes.bool: Ensures the prop is a boolean.",
          "PropTypes.array: Ensures the prop is an array.",
          "PropTypes.object: Ensures the prop is an object.",
          "PropTypes.func: Ensures the prop is a function.",
          "PropTypes.node: Ensures the prop is a valid React node (JSX, string, number, etc.).",
          "PropTypes.element: Ensures the prop is a valid React element.",
          "PropTypes.instanceOf(ClassName): Ensures the prop is an instance of a specific class.",
          "PropTypes.oneOf(['value1', 'value2']): Ensures the prop is one of the specified values.",
          "PropTypes.oneOfType([PropTypes.string, PropTypes.number]): Allows multiple types.",
          "PropTypes.arrayOf(PropTypes.string): Ensures an array of specific types.",
          "PropTypes.shape({ key: PropTypes.string }): Validates object shape.",
          "PropTypes.exact({ key: PropTypes.string }): Ensures object has only specified keys.",
        ],
      },
      {
        type: "title",
        content: "Advanced Usage",
      },
      {
        type: "list",
        items: ["PropTypes for Objects and Arrays"],
      },
      {
        type: "text",
        content: `When a prop is an object with a specific shape, you can use PropTypes.shape:`,
      },
      {
        type: "code",
        content: `
        UserCard.propTypes = {
          user: PropTypes.shape({
            name: PropTypes.string.isRequired,
            age: PropTypes.number,
            email: PropTypes.string
          }).isRequired
        };
        `,
      },
      {
        type: "text",
        content: `For arrays of objects:`,
      },
      {
        type: "code",
        content: `
        PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired
          })
        )
        `,
      },
      {
        type: "title",
        content: "PropTypes vs. TypeScript: Which One Should You Use?",
      },
      {
        type: "image",
        src: "/images/articleImg/9/1.webp",
        alt: "PropTypes vs. TypeScript",
      },
      {
        type: "title",
        content: "When to Use What?",
      },
      {
        type: "text",
        content: `If you already use TypeScript, you don't need PropTypes because TypeScript provides stricter type checking.`,
      },
      {
        type: "text",
        content: `If you're using plain JavaScript, PropTypes is a great lightweight solution for enforcing type safety.`,
      },
      {
        type: "title",
        content: "Best Practices for Using PropTypes",
      },
      {
        type: "list",
        items: [
          "Use isRequired for mandatory props to avoid unexpected errors.",
          "Define defaultProps to handle missing values.",
          "Use oneOf and oneOfType when a prop can take multiple predefined values.",
          "Validate object shapes with shape or exact for better structure enforcement.",
          "Keep PropTypes definitions outside the component to keep code clean and readable.",
        ],
      },
      {
        type: "title",
        content: "Conclusion",
      },
      {
        type: "text",
        content: `PropTypes is a powerful and easy-to-use type validation tool for React components, especially when working with JavaScript. While TypeScript provides stricter compile-time checks, PropTypes remains a great choice for ensuring runtime validation and improving debugging.`,
      },
      {
        type: "text",
        content: `If you're working on a React project and not using TypeScript, adding PropTypes is a simple yet effective way to catch bugs early and document your components better.`,
      },
    ],
  },
  {
    id: "The-End-of-Create-React-App-and-What-to-Use-Next",
    title: "",
    image: "/images/articleImg/10/first.webp",
    paragraphs: [
     {
        type: "title", 
        content: "The End of an Era: Create React App is Dead - What's Next for React Developers?"
      },
      {
        type: "text",
        content: `For nearly a decade, Create React App (CRA) was the go-to tool for bootstrapping React projects. Launched in 2016 by Facebook, it democratized React development with its zero-configuration setup, making it a favorite for beginners and seasoned devs alike. But as of early 2025, the React team has officially marked CRA as deprecated for new projects, signaling a shift toward more modern, performant alternatives. In this detailed guide, we'll unpack why CRA is “dead,” what this means for your workflow, and explore the best replacements—complete with pros, cons, comparisons to CRA, and real-world examples. Whether you're a CRA loyalist or ready for a fresh start, this post has you covered.`,
      },
      {
        type: "title", 
        content: "Why is Create React App “Dead”?"
      },
      {
        type: "text",
        content: `CRA's deprecation isn't a surprise—it's been on life support for years. The React team's official announcement on February 14, 2025, confirmed its retirement, citing evolving needs in the web development landscape. Here's a deeper look at why CRA no longer cuts it:`,
      },
      {
        type: "list",
         items: [
          "Performance Bottlenecks: CRA uses Webpack 4 and Babel under the hood, which lag behind newer tools. Build times balloon as projects grow, with cold starts and Hot Module Replacement (HMR) feeling sluggish compared to modern alternatives.",
          "Outdated Features: CRA lacks native support for server-side rendering (SSR), static site generation (SSG), or file-based routing—features now table stakes in frameworks like Next.js. It's stuck in a single-page app (SPA) mindset.",
          "Maintenance Stagnation: The last major update (v5.0.1) in April 2022 left CRA trailing React 18's advancements, like concurrent rendering. Community forks like craco tried to patch the gaps, but the core tool couldn't keep up.",
          "Eject Pain: Customization meant ejecting, exposing a tangled Webpack config that intimidated newcomers and frustrated pros. Even then, you were stuck with outdated defaults.",
          "Ecosystem Shift: Tools like Vite, esbuild, and SWC have redefined build performance, while frameworks like Remix and Astro push React into new territories—leaving CRA in the dust.",
         ],
       },
       {
        type: "text",
        content: `The React team now recommends moving to frameworks (e.g., Next.js, Remix) or custom setups with modern bundlers (e.g., Vite). While CRA still runs, it's a relic—unsupported for new projects and a liability for scaling teams.`,
      },
      {
        type: "title", 
        content: "What Should You Choose Instead?"
      },
      {
        type: "text",
        content: `The React ecosystem is bursting with options, from lightweight bundlers to full-stack frameworks. Below, we've dissected the top CRA alternatives with technical details, trade-offs, and practical guidance. Each section includes a comparison to CRA, pros and cons, and a sample setup to get you started.`,
      },
      {
        type: "title", 
        content: "Vite + React"
      },

      {
        type: "boldText",
        content: `“The modern, lightweight successor to CRA”`,
      },
       {
        type: "text",
        content: `Vite, created by Evan You of Vue.js fame, is a next-gen build tool that leverages native ES modules and esbuild for unparalleled speed. It's the closest spiritual successor to CRA for SPA lovers.`,
      },
      {
        type: "boldText",
         content: 
          "Pros:",
         
         
       },
       {
        type: "list",
         items: [
          "Lightning-Fast Builds: Esbuild's Go-based engine compiles code up to 100x faster than Webpack. A 1,000-file project might build in seconds, not minutes.",
          "Zero Config: Like CRA, it works out of the box with sensible defaults—run one command and start coding.",
          "Modern Features: Supports TypeScript, JSX, CSS modules, and PostCSS natively, plus a plugin system for extensions (e.g., vite-plugin-svgr for SVGs).",
          "Dev Server: Uses native ES modules for instant HMR, cutting reload times to milliseconds.",
         ],
       },
       {
        type: "boldText",
        content: `Cons:`,
      },
       {
        type: "list",
         items: [
          "SPA Focus: No built-in SSR or SSG—pair it with tools like Vitest or a backend for advanced use cases.",
          "Young Ecosystem: While adoption is soaring (over 2 million weekly downloads on npm), it's less battle-tested than Webpack for edge cases.",
         ],
       },
       {
        type: "boldText",
        content: `Compared to CRA:`,
      },
      {
        type: "text",
        content: `Vite mirrors CRA's simplicity but swaps Webpack for esbuild and native ES modules. It's ideal if you want CRA's workflow with a 2025 upgrade—faster, leaner, and less bloated.`,
      },
      {
        type: "boldText",
        content: `Example Use Case:`,
      },

      {
        type: "text",
        content: `A real-time dashboard SPA with frequent dev iterations.`,
      },
      {
        type: "code",
        content: `
        npm create vite@latest my-vite-app -- --template react-ts
        cd my-vite-app
        npm install
        npm run dev
        `
      },
      {
        type: "text",
        content: `Tip: Add vite-plugin-react for React Fast Refresh, mimicking CRA's live reload.`,
      },
      {
        type: "title", 
        content: "Next.js"
      },
      {
        type: "boldText",
        content: `“The React framework for production”`,
      },
      {
        type: "text",
        content: `Next.js, maintained by Vercel, is a powerhouse that extends React into a full-stack framework. It's the React team's top pick for most use cases.`,
      },
      {
        type: "boldText",
        content: `Pros:`,
      },
      {
        type: "list",
         items: [
          "Rendering Options: Supports SSR, SSG, Incremental Static Regeneration (ISR), and client-side rendering—flexible for any app.",
          "File-Based Routing: Drop a file in pages/ (e.g., about.js), and it's a route—no extra config needed.",
          "API Routes: Build backend endpoints in pages/api/—perfect for prototyping or small apps.",
          "Optimized: Automatic code splitting, image optimization, and prefetching boost performance.",
         ],
       },
       {
        type: "boldText",
        content: `Cons:`,
      },
      {
        type: "list",
         items: [
          "Learning Curve: Concepts like getServerSideProps and getStaticProps take time to master.",
          "Vercel Bias: While deployable anywhere, it's tightly coupled to Vercel's ecosystem, which may lock you in.",
         ],
       },
       {
        type: "boldText",
        content: `Compared to CRA:`,
      },
      {
        type: "text",
        content: `Next.js is CRA with ambition—it keeps React's core but adds server-side muscle and opinions. If CRA's SPA limits held you back, Next.js opens the door to enterprise-grade apps.`,
      },

      {
        type: "boldText",
        content: `Example Use Case:`,
      },

      {
        type: "text",
        content: `An SEO-heavy e-commerce site with dynamic product listings.`,
      },

      {
        type: "code",
        content: `
        npx create-next-app@latest my-next-app --typescript
        cd my-next-app
        npm run dev
        `
      },
      {
        type: "text",
        content: `Tip: Use next.config.js to tweak Webpack or add SWC for even faster builds.`,
      },

      {
        type: "title", 
        content: "Remix"
      },
      {
        type: "boldText",
        content: `“Full-stack React with a focus on UX”`,
      },
      {
        type: "text",
        content: `Remix, acquired by Shopify in 2022, is a modern framework emphasizing nested routing and data-driven development.`,
      },
      {
        type: "boldText",
        content: `Pros:`,
      },
      {
        type: "list",
         items: [
          "Nested Routing: Routes like /dashboard/settings can render nested UI without prop drilling.",
          "Data Loaders: Server-side loader functions sync data with UI, reducing over-fetching.",
          "Progressive Enhancement: Works without client-side JS, falling back gracefully.",
          "Small Runtime: Ships less JS than Next.js, prioritizing performance.",
         ],
       },
       {
        type: "boldText",
        content: `Cons:`,
      },
      {
        type: "list",
         items: [
          "Newer Player: Launched in 2021, it has fewer third-party integrations than Next.js.",
          "Setup Overhead: Requires more upfront planning for data flows and routing.",
        
         ],
       },
       {
        type: "boldText",
        content: `Compared to CRA:`,
      },
      {
        type: "text",
        content: `Remix ditches CRA's SPA-only approach for a full-stack mindset. It's less “plug-and-play” but rewards devs with complex UI needs.`,
      },
      {
        type: "boldText",
        content: `Example Use Case:`,
      },

      {
        type: "text",
        content: `A SaaS app with nested dashboards and real-time updates.`,
      },
      {
        type: "code",
        content: `
        npx create-remix@latest my-remix-app --typescript
        cd my-remix-app
        npm run dev
        `
      },
      {
        type: "text",
        content: `Tip: Pair with Cloudflare Workers for edge deployment.`,
      },

      {
        type: "title", 
        content: "Gatsby"
      },
      {
        type: "boldText",
        content: `“Static sites with dynamic flair”`,
      },
      {
        type: "text",
        content: `Gatsby uses React and GraphQL to generate blazing-fast static sites, perfect for content-driven projects.`,
      },
      {
        type: "boldText",
        content: `Pros:`,
      },
      {
        type: "list",
         items: [
          "Pre-Rendering: Generates HTML at build time for instant page loads and SEO wins.",
          "GraphQL Data Layer: Pulls data from CMSs, APIs, or files into a unified query system.",
          "Plugins Galore: Over 2,500 plugins (e.g., gatsby-plugin-image) simplify integrations.",
          "Hosting Ease: Deploys to Netlify or GitHub Pages with zero server management.",
         ],
       },
       {
        type: "boldText",
        content: `Cons:`,
      },
      {
        type: "list",
         items: [
          "Build Times: Large sites with thousands of pages can take minutes to compile.",
          "Dynamic Limits: Real-time features require workarounds like client-side fetching.",
        
         ],
       },
       {
        type: "boldText",
        content: `Compared to CRA:`,
      },
      {
        type: "text",
        content: `Gatsby trades CRA's SPA flexibility for static-site supremacy. It's heavier but excels where performance and content reign.`,
      },
      {
        type: "boldText",
        content: `Example Use Case:`,
      },

      {
        type: "text",
        content: `A documentation portal with 100+ pages.`,
      },
      {
        type: "code",
        content: `
        npx gatsby new my-gatsby-site https://github.com/gatsbyjs/gatsby-starter-default
        cd my-gatsby-site
        npm run develop
        `
      },
      {
        type: "text",
        content: `Tip: Use gatsby-source-filesystem to pull Markdown content.`,
      },
      {
        type: "title", 
        content: "Astro"
      },
      {
        type: "boldText",
        content: `“Static sites with islands of interactivity”`,
      },
      {
        type: "text",
        content: `Astro is a hybrid tool that blends static generation with optional client-side React (or other frameworks) via its “islands” architecture.`,
      },
      {
        type: "boldText",
        content: `Pros:`,
      },
      {
        type: "list",
         items: [
          "Minimal JS: Ships zero JS by default, adding it only where interactivity is needed.",
          "Multi-Framework: Mix React, Vue, or Svelte in one project.",
          "Fast Builds: Eschews heavy runtimes for a lean, Vite-based pipeline.",
          "Markdown Support: Built-in for blogs or docs.",
         ],
       },
       {
        type: "boldText",
        content: `Cons:`,
      },
      {
        type: "list",
         items: [
          "React Lite: React is an add-on, not the core—less idiomatic than CRA.",
          "Evolving: Launched in 2021, it's still maturing with fewer resources.",
        
         ],
       },
       {
        type: "boldText",
        content: `Compared to CRA:`,
      },
      {
        type: "text",
        content: `Astro is CRA's performance-obsessed sibling—static-first with React as a guest star. It's a departure but a dream for speed freaks.`,
      },
      {
        type: "boldText",
        content: `Example Use Case:`,
      },

      {
        type: "text",
        content: `A portfolio site with a React contact form.`,
      },
      {
        type: "code",
        content: `
        npm create astro@latest my-astro-site -- --template with-typescript
        cd my-astro-site
        npm install @astrojs/react
        npm run dev
        `
      },
      {
        type: "text",
        content: `Tip: Add @astrojs/vercel for seamless deployment.`,
      },
      {
        "type": "title",
        "content": "Parcel"
      },
      {
        "type": "boldText",
        "content": "“Zero-config bundling, redefined”"
      },
      {
        "type": "text",
        "content": "Parcel is a bundler that promises true zero-configuration, competing with Vite for simplicity."
      },
      {
        "type": "boldText",
        "content": "Pros:"
      },
      {
        "type": "list",
        "items": [
          "No Setup: Works with React, CSS, and assets without a config file—just point it at your index.html.",
          "Fast Enough: Uses multicore processing for builds, rivaling Vite in many cases.",
          "Tree Shaking: Built-in code optimization slims down bundles."
        ]
      },
      {
        "type": "boldText",
        "content": "Cons:"
      },
      {
        "type": "list",
        "items": [
          "Feature-Light: Lacks advanced optimizations of Webpack or esbuild.",
          "Niche Use: Less popular than Vite, with a smaller plugin ecosystem."
        ]
      },
      {
        "type": "boldText",
        "content": "Compared to CRA:"
      },
      {
        "type": "text",
        "content": "Parcel is CRA minus the cruft—simpler and faster but not as extensible. It's a quick-start champ."
      },
      {
        "type": "boldText",
        "content": "Example Use Case:"
      },
      {
        "type": "text",
        "content": "A weekend hackathon project."
      },
      {
        "type": "code",
        "content": `
      mkdir my-parcel-app
      cd my-parcel-app
      npm init -y
      npm install react react-dom parcel
      echo '<div id="root"></div>' > index.html
      npx parcel index.html
      `
      },
      {
        "type": "text",
        "content": "Tip: Add a .babelrc for custom transforms."
      },
      {
        "type": "title",
        "content": "Nx"
      },
      {
        "type": "boldText",
        "content": "“Monorepos and enterprise-grade tooling”"
      },
      {
        "type": "text",
        "content": "Nx is a build system for monorepos but doubles as a standalone React setup with advanced features."
      },
      {
        "type": "boldText",
        "content": "Pros:"
      },
      {
        "type": "list",
        "items": [
          "Monorepo Magic: Manage multiple apps and libraries in one repo with dependency graphing.",
          "Caching: Speeds up builds and tests by reusing prior results.",
          "Tooling: Integrates Vite, Webpack, or esbuild—your choice.",
          "CI/CD Ready: Built-in support for large teams and pipelines."
        ]
      },
      {
        "type": "boldText",
        "content": "Cons:"
      },
      {
        "type": "list",
        "items": [
          "Complexity: Overwhelming for solo devs or small apps.",
          "Setup Time: Requires planning for workspace structure."
        ]
      },
      {
        "type": "boldText",
        "content": "Compared to CRA:"
      },
      {
        "type": "text",
        "content": "Nx is CRA for the big leagues—structured and scalable but not beginner-friendly."
      },
      {
        "type": "boldText",
        "content": "Example Use Case:"
      },
      {
        "type": "text",
        "content": "A suite of micro-frontends for a fintech platform."
      },
      {
        "type": "code",
        "content": `
      npx create-nx-workspace@latest my-nx-app --preset=react --bundler=vite
      cd my-nx-app
      npm start
      `
      },
      {
        "type": "text",
        "content": "Tip: Use nx affected to run tasks only on changed projects."
      },

      {
        "type": "title",
        "content": "Rspack"
      },
      {
        "type": "boldText",
        "content": "“Rust-powered Webpack successor”"
      },
      {
        "type": "text",
        "content": "Rspack is a high-performance bundler written in Rust, designed as a drop-in replacement for Webpack with a focus on speed."
      },
      {
        "type": "boldText",
        "content": "Pros:"
      },
      {
        "type": "list",
        "items": [
          "Insane Speed: Rust compilation outpaces Webpack by orders of magnitude—think sub-second builds.",
          "Webpack Compatibility: Supports most Webpack loaders and plugins, easing migration from CRA.",
          "Modern Defaults: TypeScript, JSX, and CSS modules work natively.",
          "Active Development: Backed by ByteDance, it's evolving rapidly."
        ]
      },
      {
        "type": "boldText",
        "content": "Cons:"
      },
      {
        "type": "list",
        "items": [
          "Early Days: Launched in 2023, it's less mature than Vite or Next.js, with sparse documentation.",
          "SPA-Centric: No SSR/SSG out of the box—pair it with a server for advanced needs."
        ]
      },
      {
        "type": "boldText",
        "content": "Compared to CRA:"
      },
      {
        "type": "text",
        "content": "Rspack is CRA with a Rust engine—familiar Webpack vibes but turbocharged. It's a sleeper hit for SPA diehards."
      },
      {
        "type": "boldText",
        "content": "Example Use Case:"
      },
      {
        "type": "text",
        "content": "A legacy CRA app needing a performance boost."
      },
      {
        "type": "code",
        "content": `
      npm create rspack@latest my-rspack-app --template react-ts
      cd my-rspack-app
      npm run dev
      `
      },
      {
        "type": "text",
        "content": "Tip: Reuse your webpack.config.js with minimal tweaks."
      },
      {
        type: "image",
        src: "/images/articleImg/10/1.webp",
        alt: "React Icon",
      },

  {
        type: "title", 
        content: "Which Should You Pick?"
      },
      {
        type: "list",
         items: [
          "CRA Fans Seeking Simplicity: Vite, Parcel, or Rspack keep the zero-config SPA spirit with modern speed.",
          "Full-Stack Ambitions: Next.js or Remix deliver SSR, routing, and data handling for dynamic apps.",
          "Static Site Gurus: Gatsby or Astro optimize for content and performance.",
          "Enterprise Scale: Nx structures large teams and monorepos with precision.",
         ],
       },
       {
        type: "title", 
        content: "Migrating from CRA: Tips and Tricks"
      },
      {
        type: "list",
         items: [
          "Audit Your Setup: Check for custom Webpack configs or ejected setups—tools like Rspack or Nx ease the transition.",
          "Test Performance: Benchmark build times with Vite or Rspack against CRA to quantify gains.",
          "Start Small: Prototype with Parcel or Vite before committing to Next.js or Remix for complex apps.",
          "Leverage Plugins: Most alternatives support CRA staples like react-scripts polyfills via plugins.",
         ],
       },
       {
        type: "title", 
        content: "Final Thoughts"
      },
      {
        type: "text",
        content: `Create React App's retirement closes a chapter, but the React ecosystem is thriving with innovation. From Vite's blistering speed to Next.js's full-stack prowess, there's a tool for every developer—whether you're building a quick SPA, a content empire, or an enterprise monorepo. The shift away from CRA is a chance to rethink your workflow, boost performance, and embrace the future of React.`,
      },
      {
        type: "text",
        content: `What's your next move? Drop a comment with your favorite alternative or project plans—I'd love to hear how you're adapting!`,
      },










    
    ]
  },
];
export { articles, blogCards };
/*

 {
    id: "",
    title: "",
    image: "/images/articleImg/AuthGuard1.webp",
    paragraphs: [

     {
        type: "title", 
        content: "title"
      },

       {
        type: "boldText",
        content: `text`,
      },

      {
        type: "text",
        content: `text`,
      },

      {
       type: "list",
        items: [
         "",
         "",
         "",
        ],
      },

        {
        type: "image",
        src: "/images/articleImg//1.webp",
         alt: "React Icon",
      },

      {
        type: "code",
        content: `
        
        `
      },
    ]
  },

  */
